@import "src/styles/variables.scss";

.cross-sell-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
}

.cross-sell-price,
.cross-sell {
  font-size: 0.8rem;
  font-weight: 500;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
  margin: 0 10px;
}
