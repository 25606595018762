@import "styles/variables.scss";

.free {
  background-color: transparent;
}

.premium {
  background-color: $gold;
}

.essential {
  background-color: $silver;
}

.basic {
  background-color: $bronze;
}

.logo-fill {
  fill: white;
}

.logo {
  filter: drop-shadow(1px 1px 1px $brandPrimary);
}

.logo-small {
  width: 1.5em;
  height: 1.5em;
}

.label {
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.33);
  padding: 4px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 1px $brandPrimary;
}

.label-rank {
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 0.5em;
  text-align: center;
  vertical-align: middle;
}
