@import "/src/styles/variables.scss";

.shipping-service {
  cursor: pointer;
  user-select: none !important;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $darkGray;
  border-radius: 3px;
}

.is-selected {
  border: 2px solid $brandAccent;
  background-color: $shadeAccentTransparency;
  // font-weight: bold;
  padding: calc(0.5rem - 1px) calc(1rem - 1px);
}

.shipping-service-logo {
  font-size: 0.7rem;
  line-height: 0.9rem;
}
