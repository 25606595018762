@import "styles/variables.scss";

.workshop-id {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: white;
  background-color: $workshopId;
  border-radius: 5px;
  //   padding: 0 6px;
  width: fit-content;
  height: fit-content;
  min-height: 36px;
  font-size: 36px;
  font-weight: 500;
  padding: 10px;
  text-wrap: nowrap;
  position: relative;
}

.list-size {
  padding: 0.5rem;
  line-height: 0.8rem;
  font-size: 0.8rem;
  border-radius: 4px;
  min-height: unset;
  min-width: 95px;

  .icon {
    font-size: 0.8rem;
  }
}

.xxx-small {
  padding: 4px 10px;
  font-size: 14px;
  border-radius: 4px;
  min-width: 95px;

  .icon {
    font-size: 14px;
  }
}

.xx-small {
  min-width: 138px;
  font-size: 1em;
}

.x-small {
  min-width: 100px;
  font-size: 1rem;
}

.small {
  font-size: 20px;
}

.medium {
  font-size: 36px;
}

.large {
  font-size: 1.5em;
  min-width: 190px;
}

.pro-not-definitive {
  background-color: $pro;
}

.cross-sell {
  background-color: $crossSell !important;
}

.pro {
  background-color: $pro !important;
}

.galoche {
  background-color: $workshopId !important;
}

.pro-logo {
  height: 0px;
  width: 0px;
  overflow: visible;
  font-size: 10px;
  position: absolute;
  top: 0;
  font-weight: bold;
  right: 24px;
}

.small-pro {
  top: -5px;
}

.xxx-small-pro {
  top: -7px;
  right: 19px;
  font-size: 8px;
}

.xx-small-pro {
  top: -5px;
  // right: 10px;
}

.x-small-pro {
  top: -6px;
  right: 20px;
  font-size: 8px;
}

.medium-pro {
  top: -5px;
}

.large-pro {
  top: -5px;
}
