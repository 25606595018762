@import "styles/variables.scss";

.information-grid-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  label {
    margin: 0 0.5rem 0 0 !important;
  }
}

.error-bubble-container {
  overflow: visible;
  margin: 0 5px;
  height: 0;
}

.error-bubble {
  display: flex;
  align-items: center;
  position: relative;
  top: -5px;
  margin-right: 1px;
  background-color: $error;
  color: white;
  border-radius: 3px;
  padding: 0.25rem;
  width: fit-content;
  font-size: 0.75rem;
  line-height: 0.8rem;
  z-index: 2;
  box-shadow: $readCardShadow;
}

.icon {
  margin-right: 0.3rem;
  height: 17px !important;
  width: 17px !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .information-grid-input {
    grid-template-columns: 1fr;
  }
  .error-bubble {
    font-size: 0.7rem;
  }
}
