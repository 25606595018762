@import "variables.scss";

.button-l {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 400;
    text-align: center;

    cursor: pointer;

    text-decoration: none;
    border-radius: 4px;
    color: $brandSecondary;

    border: 0px solid $brandPrimary;

    background-color: $brandPrimary;

    &:hover {
        background-color: $hoverPrimary;
        border-color: $hoverPrimary;
        color: $brandPrimary;
    }

    &:disabled {
        background-color: $lightGray;
        border-color: $lightGray;
    }

    font-size: 18px;
    line-height: 24px;
    padding: 24px 32px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        padding: 20px 24px;
    }
}

.button-m {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 400;
    text-align: center;

    cursor: pointer;

    text-decoration: none;
    border-radius: 4px;
    color: $brandSecondary;

    border: 0px solid $brandPrimary;


    background-color: $brandPrimary;

    &:hover {
        background-color: $hoverPrimary;
        border-color: $hoverPrimary;
        color: $brandPrimary;
    }

    &:disabled {
        background-color: $lightGray;
        border-color: $lightGray;
    }

    font-size: 16px;
    line-height: 24px;
    padding: 20px 24px;
}

.button-s {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 400;
    text-align: center;

    cursor: pointer;

    text-decoration: none;
    border-radius: 4px;
    color: $brandSecondary;

    border: 0px solid $brandPrimary;

    background-color: $brandPrimary;

    &:hover {
        background-color: $hoverPrimary;
        border-color: $hoverPrimary;
        color: $brandPrimary;
    }

    &:disabled {
        background-color: $lightGray;
        border-color: $lightGray;
    }

    font-size: 14px;
    line-height: 16px;
    padding: 16px 24px;
}

.button-tiny {
    font-family: "Albert Sans";
    font-style: normal;
    font-weight: 400;
    text-align: center;

    cursor: pointer;

    text-decoration: none;
    border-radius: 4px;
    color: $brandPrimary;

    border: none;
    background-color: transparent;

    &:hover {
        background-color: $hoverPrimary;
        color: #ffffff;
    }

    &:disabled {
        background-color: $lightGray;
        border-color: $lightGray;
    }

    font-size: 14px;
    line-height: 16px;
    padding: 4px;
}

.button-s.selected {
    background-color: $hoverPrimary;
    border-color: $hoverPrimary;
    color: #ffffff;
}


*[class^="button-"].accent {
    background-color: $brandAccent;
    border: 0px solid $brandAccent;
    color: $brandPrimary;

    &:hover {
        color: $brandAccent;
        border-color: $brandAccent;
        background-color: $brandPrimary;
    }

    &:disabled {
        border-color: $lightGray;
    }
}

*[class^="button-"].secondary {
    background-color: $brandSecondary;
    border: 0px solid $brandSecondary;
    color: $brandPrimary;

    &:hover {
        color: $brandSecondary;
        background-color: $brandPrimary;
    }

    &:disabled {
        background-color: $lightGray;
    }
}

.outlined {
    background-color: transparent;
    border: 1px solid $brandPrimary;
    color: $brandPrimary;

    &:hover {
        border-color: $hoverPrimary;
        background-color: $hoverPrimary;
    }

    &:disabled {
        border-color: $lightGray;
    }
}