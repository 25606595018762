@import "styles/variables.scss";

.dashboard-widget-header {
  display: flex;
  justify-content: space-between;
  font-size: clamp(1rem, 2.5vw, 2rem);
  padding: 1.5rem 2rem;
  width: 100%;
}

@media screen and (max-width: $tabletBreakpoint) {
  .dashboard-widget-header {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .dashboard-widget-header {
    padding: 0.5rem;
  }

  .footer {
    padding: 0.5rem;
    margin: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
}
