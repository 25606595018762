@import "styles/variables.scss";

.item {
  display: table-row;
  font-size: 16px;
  height: 70px !important;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.hover:hover {
  background-color: $brandSecondary;
}

.active:active {
  background-color: $brandSecondary;
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.item-coord {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.displayname {
  font-weight: 500;
}

.item-product {
  // display: flex;
}

.coordinates {
  width: 300px !important;
}

.status-box,
.item-date {
  width: 140px !important;
}
.tools {
  width: 90px !important;
}

.item-date {
  cursor: pointer;
}

.product-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-cell {
  cursor: pointer;
  width: 600px !important;
  min-width: 200px !important;
}
