@import "/src/styles/variables.scss";

.in-store-drop-off-bottom,
.in-store-drop-off-bottom-collapsed {
  margin-top: auto;
  padding: 0.5rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid $darkGray;
  background-color: $lightGrayTransparent40;
}

.in-store-drop-off-bottom-empty {
  margin-top: auto;
}

.in-store-drop-off-bottom-collapsed {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.in-store-drop-off-bottom-collapsed-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.in-store-drop-off-bottom {
  position: relative;
}

.collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.expand-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: $mobileBreakpoint) {
  .in-store-drop-off,
  .in-store-drop-off-bottom {
    grid-template-columns: 1fr;
  }

  .in-store-drop-off-bottom-collapsed-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
