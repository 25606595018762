@import "styles/variables.scss";

.list-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
  min-height: 68px;
}

.title {
  font-size: clamp(1.5rem, 3vw, 2rem) !important;
}

@media screen and (max-width: $laptopBreakpoint) {
  .list-page-header {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .list-page-header {
    padding: 0.5rem;
  }
}
