@import "styles/variables.scss";

.add-new-cart-pro-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  // max-height: 800px;
  overflow: auto;
  width: 100%;
  padding: 20px;
}

.add-new-cart-pro-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-new-cart-pro-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.comment {
  width: 100%;
  min-height: 100px;
  padding: 5px;
}
.separator {
  margin: 20px 0px !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .add-new-cart-pro-header {
    flex-direction: column;
  }
}


// @import "../../../styles/variables.scss";

// .button-container {
//   align-items: center;
//   display: flex;
//   justify-content: flex-end;
// }

// .contact-information {
//   margin-bottom: 20px;
//   width: 100%;
// }

// .contact-information-form {
//   display: flex;
//   justify-content: space-between;
// }

// .delete-product {
//   align-items: center;
//   color: $error;
//   display: flex;
//   justify-content: center;
//   height: 36px;
//   width: 36px;
// }

// .edit-button {
//   align-items: center;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   margin-left: 10px;
// }

// .header {
//   display: flex;
//   justify-content: space-between;
// }

// .header-right {
//   align-items: center;
//   display: flex;
// }

// .operation-item {
//   -webkit-box-shadow: $primaryShadow;
//   background-color: $brandSecondary;
//   box-shadow: $primaryShadow;
//   border-radius: 4px;
//   cursor: pointer;
//   display: flex;
//   font-size: 16px;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   overflow-wrap: anywhere;
//   padding: 10px;
//   text-align: center;
//   width: 100%;
// }

// .operation-section {
//   display: flex;
//   align-items: center;
//   gap: 10px;
// }

// .pro-shipping-modal {
//   -webkit-box-shadow: $primaryShadow;
//   background-color: $shadeSecondary;
//   border-radius: 4px;
//   box-shadow: $primaryShadow;
//   display: flex;
//   height: 100%;
//   flex-direction: column;
//   justify-content: space-between;
//   max-height: 800px;
//   max-width: 1000px;
//   overflow: auto;
//   width: 90vw;
// }

// .pro-shipping-modal-container {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: 20px;
//   width: 100%;
// }

// .pro-shipping-modal-container-top {
//   display: flex;
//   height: 100%;
//   flex-direction: column;
// }

// .selected {
//   background-color: $brandAccent;
// }

// // .select-product-slider-wrapper {
// //   overflow-y: visible;
// //   height: 125px;
// // }

// .title {
//   font-size: 36px;
//   margin-bottom: 20px;
// }

// .unset-height {
//   height: unset !important;
// }

// .workshop-id-input {
//   order: unset;
//   min-width: unset;
//   width: 8em;
// }

// @media screen and (max-width: 1100px) {
//   .operation-section {
//     display: flex;
//     flex-direction: column;
//   }
// }
