.icon {
  margin-right: 0.5rem;
}

.logo {
  height: 20px !important;
  object-fit: scale-down;
}

.shipping-service {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.chronopost-link {
  display: flex;
  align-items: center;

  &:active {
    filter: grayscale(100%);
  }
}

.unavailable {
  filter: grayscale(100%);
}
