@import "styles/variables.scss";

.discount-logo {
  height: 2em;
}

.operation-canceled {
  color: $lightGray;
  text-decoration: line-through;
}

.widget-title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.workshopId {
  margin-left: 1rem;
  width: 98px;
  font-size: 14px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 10px;
  background-color: $info;
  color: $brandSecondary;
  border-radius: 4px;

  .tag-icon {
    height: 18px !important;
    width: 18px !important;
  }
}

.logo {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
}

.product-info {
  display: flex;
  align-items: center;
}

.product-info-text {
  margin-bottom: 0 !important;
  line-height: 1.2rem;
}
