@import "styles/variables.scss";

.separator {
  margin: 0px 0px 20px 0px !important;
}

.left {
  user-select: none;
  cursor: pointer;
  display: flex;
  flex: 1;
  height: fit-content;
}

.right {
  flex: 2;
  display: flex;
}

.section-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: fit-content;
}

.section-form.collapsed {
  .right {
    display: none;
  }
}

.section-form-template {
  width: 100%;

  margin-bottom: 20px;
}

.no-margin {
  margin: 0;
}

.icon {
  height: 15px !important;
  width: 15px !important;
}

.collapsible {
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $tabletBreakpoint) {
  .right {
    max-width: unset;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .section-form {
    flex-direction: column;
  }

  .left {
    margin-bottom: 1rem;
  }
}
