@import "/src/styles/variables.scss";

.product-state-list {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
}

.product-state {
  border: 1px solid $darkGray;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1em;
  font-size: 0.875rem;
  line-height: 0.9rem;
  text-align: center;
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
}

.state-selected {
  opacity: 1;
  border: 2px solid $brandAccent;
  padding: calc(1em - 1px);
}
