@import "styles/variables.scss";

.modal-product-list {
  width: 300px;
  padding: 0.5rem;
  height: 100%;
  overflow-x: auto;
  border-right: 1px solid $lightGray;
}

.modal-product-miniature {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $darkGray;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  max-width: 216px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .modal-product-list {
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 70px !important;
    border-right: 0;
    border-bottom: 1px double $lightGray;
    overflow-x: unset;
    overflow-y: auto;
    padding: 0rem 0.5rem;
  }

  .modal-product-miniature {
    width: 160px;
    height: fit-content;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
}
