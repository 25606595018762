@import "styles/variables.scss";

.earliest-product-dueDate {
  font-style: italic;
  font-size: 16px;
}

.loading-spinner {
  width: 25px;
  height: 25px;
}

.operation-type-card {
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border: 4px;
  font-size: 30px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:active {
    filter: brightness(90%);
  }
}

.operation-type-card-left {
  display: flex;
  flex-direction: column;
}

.operation-type-card-right {
  display: flex;
  align-items: center;
}

.product-list {
  margin-bottom: 10px;
  width: 100%;
}

.title {
  display: flex;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20px;
}
