.sub-total {
  opacity: 0.8;
  margin: 0.4rem 0 !important;
  font-size: 0.9rem !important;
}
.product-list {
  list-style: none;
}

.quote-summary-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  height: fit-content;
}

.quote-summary-modal-container {
  width: 100%;
}

.overview-link-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.overview-link {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.icon {
  margin-left: 0.2rem;
  height: 18px !important;
  width: 18px !important;
}
