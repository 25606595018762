@import "styles/variables.scss";

.discount-logo {
  height: 2em;
}

.operation-discount-option {
  font-size: 12px;
  margin-left: 1em;
  padding: 0;
  border: none;
}

.operation-discount-select {
  flex: 1;
  border: 1px solid $brandPrimary;
  padding: 0.25em;
  margin-left: 1em;
  min-width: 24px;
  width: auto;
  height: auto;
  order: 0;
}

.operation-list-item {
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.operation-canceled {
  color: $lightGray;
  text-decoration: line-through;
}

.operation-line {
  display: flex;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.2rem;
  flex-direction: row;
}


.operation-description {
  // font-weight: bold;
}

.operation-dots {
  border: none;
  border-bottom: 1px dotted black;
  flex: 1;
}

.operation-price {
  white-space: nowrap;
  font-weight: bold;
}

.operation-discount-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5em;
  padding-left: 5em;
  margin-top: 0.5rem;
}
