@import "styles/variables.scss";

.layout {
  display: flex;
  flex-direction: column;
}

.outlet {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 100px;
}

.body {
  width: fit-content;
}

select {
  cursor: pointer;
}

@media screen and (min-width: 1400px) {
  .layout {
    flex-direction: row;
  }
  .outlet {
    // margin-left: 310px;
    margin-top: 50px;
    width: 100%;
    // margin-bottom: 50px;
  }
}
