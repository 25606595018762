@import "/src/styles/variables.scss";

.action-button {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.overview-link {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-right: 1rem;

  .icon {
    margin-left: 0.2rem;
  }
}

.icon {
  height: 18px !important;
  width: 18px !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .action-button {
    &:not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 0.5rem;
    }
  }
}
