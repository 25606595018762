@import "styles/variables.scss";

.image-carrousel-container {
  width: 100%;
  border-top: 1px solid $brandAccent;
  background-color: $brandPrimary;
  overflow: auto;
  align-items: center;
  height: 101px;

  transition: opacity 0.2s ease-out;
  transition: opacity 1.2s ease-in;
}

.hide {
  opacity: 0;
  // display: none;
}

.image-carrousel {
  width: fit-content;
  display: flex;
  padding: 10px;
}

.image-item {
  height: 80px !important;
  width: 80px !important;
  object-fit: scale-down;
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  transition: transform 0.5s ease-in-out;
}

.selected {
  transform: scale(90%);
  border: 4px solid $brandAccent;
  border-radius: 5px;
}
