@import "styles/variables.scss";
.gp-calendar-item {
  flex: 1;
  flex-direction: column;
  background-color: $info;
  color: black;
  border: 1px solid #fff;
  overflow: hidden;
  border-radius: 4px;
  padding: 0.25em;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
}

.gp-calendar-item-title {
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1em;
}

.gp-calendar-item-subtitle {
  text-overflow: ellipsis;
  font-size: 0.8em;
}
