@import "styles/variables.scss";

.reset-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
}

.rbc {
  position: absolute;
  top: 22px;
  right: 36px;
  height: 0px;
  width: 0px;

  &:hover {
    color: $error;
  }
}

.hidden {
  display: none;
}
