.validate-drop-off-shipping {
  height: fit-content;
}

.add-shipping-address-title {
  margin-bottom: 0.5rem;
}

.add-shipping-address-message {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
