@import "styles/variables.scss";

.dev-tools {
  display: flex;
  justify-content: center;
  padding: 50px;
}

.dev-tools-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1000px;
  width: 100%;
}

.tool-button {
  -webkit-box-shadow: $primaryShadow;
  align-items: center;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 90px;
  justify-content: center;
  text-align: center;
}
