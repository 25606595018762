.front-label,
.rear-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.front-label-container {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
}

.is-preview {
  display: none;
}

.title {
  font-size: 0.875rem;
}

.rear-label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
