.tracker-link {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}

.launch {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-left: 1rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  height: 15px !important;
  width: 15px !important;
}

.state {
  // font-size: 0.875rem;
  margin-left: 1rem;
}

.trackId {
  margin-left: 1rem;
  // font-size: clamp(0.5rem, 2.5vw, 1rem);
}
