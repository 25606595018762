@import "styles/variables.scss";

.contact-section {
  border: none !important;
  padding: 0px 0px 1rem 0 !important;
  width: 100%;
}

.loading-section {
  padding: 1rem;
  width: 100%;
  border: 1px solid $lightGray;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayname-container {
  display: flex;
  align-items: center;
}

.displayname {
  font-size: clamp(32px, 5vw, 40px) !important;
  line-height: clamp(38px, 5vw, 46px) !important;
}

.track-id {
  font-size: 0.9rem;
  font-size: clamp(0.7rem, 5vw, 0.9rem) !important;
}

.to-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.icon {
  height: 25px !important;
  width: 25px !important;
}

.coordinates {
  display: flex;
  width: 100%;
}

.email,
.phone {
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-size: clamp(0.8rem, 5vw, 1rem) !important;
}

.email {
  margin-right: 2rem;
}

.coordinates-icon {
  height: 15px !important;
  width: 15px !important;
  margin-right: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .coordinates {
    flex-direction: column;
  }
}
