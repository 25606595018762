@import "styles/variables.scss";

.overview-action {
  display: flex;
  margin-bottom: 1rem;
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.overview-action-container {
  display: flex;
  width: fit-content;
}

.icon {
  height: 15px !important;
  width: fit-content !important;
  padding-left: 0.3rem;
}

.action-button {
  display: flex;
  align-items: center;
  width: fit-content !important;
  text-wrap: nowrap;
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.select-state {
  margin-right: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .select-state {
    margin-right: 0.5rem;
  }
}
