@import "styles/variables.scss";

.selected {
  background-color: $brandAccent !important;
  color: white !important;
}

.printer-connect-header-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.printer-connect-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.connection-type {
  display: flex;
}

.connection-type-container {
  display: flex;
}

.connection-type-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid black;
  background-color: white;
  border-right: 0px;

  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0;
    border-left: 0px !important;
  }

  &:hover,
  &:active {
    filter: brightness(90%);
  }
}

.icon {
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px;
}
