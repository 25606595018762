.button {
  margin-right: 1rem;
}
.new-order-modal {
  padding: 1rem;
}

.new-order-modal-bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
