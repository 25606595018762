@import "/src/styles/variables.scss";

.total-price,
.selected-product-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  width: 100%;
}

.selected-product-price {
  font-size: 0.8rem;
  color: $darkGray;
}

.total-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  margin-bottom: 1rem;
}
