@import "/src/styles/variables.scss";

.discount-item {
  user-select: none;
  cursor: pointer;
  padding: 1rem 0.5rem 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
    // border-bottom: 1px solid $brandPrimary;
  }

  &:active {
    background-color: $lightGray;
  }
}

.discount-modal {
  padding: 1rem;
}

.discount-list {
  margin-top: 1rem;
}
