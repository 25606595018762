@import "styles/variables.scss";

.quote {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 1rem;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-form {
  width: 100%;
}

.title-container {
  display: flex;
}

.input-line {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.separator {
  margin: 20px 0px 20px 0px !important;
}

.top-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.send-icon {
  margin-left: 10px;
}

.currency-select {
  // width: 300px;
}

.remove-discount {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.discount-select {
  width: 100%;
}

.comment {
  width: 100%;
  min-height: 100px;
  padding: 5px;
}

.validate-button {
  background-color: $success;
  color: white;
}

.input-container {
  // width: 35% !important;
}

.simple-product-form-container,
.cross-sell-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.error-message {
  color: $error;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.add-new-button {
  cursor: pointer;
  background-color: $brandPrimary;
  color: $brandAccent;
  border: none;
  width: fit-content;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;

  padding: 10px;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

.price-value {
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.cross-selling-products-container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.cross-selling-products-list {
  margin-top: 30px;
}

.cross-selling-products-item {
  display: flex;
  width: 500px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .description {
    flex: 4;
    display: flex;
  }

  .cart-quantity,
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .delete-button {
    width: 50px;
    cursor: pointer;
    border: none;
    background-color: unset;

    &:hover,
    &:active {
      color: $error;
    }
  }
}

@media screen and (max-width: 1400px) {
  .quote {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .quote {
    padding-inline: 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .title {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .input-line {
    flex-direction: column;
  }
}
