@import "styles/variables.scss";

.color-container {
  min-width: unset !important;
  margin-left: unset;
  margin-top: 5px;
}

.cross-sell-card {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-height: 150px;
  width: 100%;
  aspect-ratio: 0.6;
  border-radius: 4px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  background-color: white;
  border: 2px solid $error;
  display: flex;
  flex-direction: column;
}

.cross-sell-card-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  font-size: 14px;
}

.cross-sell-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
}

.cross-sell-card-container {
  position: relative;
  display: flex;
}

.action {
  font-size: 14px;
  line-height: 14px;
  font-style: italic;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.image-miniature {
  width: 90px;
  height: 90px;
  object-fit: scale-down;
  border: 1px solid $lightGray;
  border-radius: 5px;
}

.item-selected {
  border: 2px solid $success;
  border-radius: 5px;
  padding: 10px;
}

.status-icon {
  height: 0;
  width: 0;
  overflow: visible;
  position: absolute;
  top: 5px;
  right: 25px;
}

.ready-icon {
  color: $success;
}

.not-ready-icon {
  color: $error;
}
