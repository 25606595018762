@import "styles/variables.scss";
.send-icon {
  margin-left: 10px;
}

.cancel-button {
  background-color: $error;
  margin-top: 0.5rem;
}

.non-feasible-button {
  background-color: $darkGray;
  margin-top: 0.5rem;
}
