@import "styles/variables.scss";

.other-article {
  padding-left: 1rem;
}

@media screen and (max-width: 650px) {
  .other-article {
    padding-left: 0;
  }
}
