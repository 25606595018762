.inputcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding-top: 6px;

  select {
    padding: 13.5px;
  }
}
