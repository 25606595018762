.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
}

.date {
  margin-right: 0.5rem;
}

.price-input {
  -moz-appearance: textfield !important;
  -webkit-appearance: none;
  margin: 0;
}

.referral-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
