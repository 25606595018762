@import "styles/variables.scss";

.license-keys-modal {
  background-color: white;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  padding: 20px 20px;
  border-radius: 5px;

  -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  height: 100%;
  width: 100%;
  min-width: 600px;

  overflow: auto;
}

.license-keys-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}

.copy-button {
  cursor: pointer;
  &:hover {
    color: $brandAccent;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  a {
    margin-right: 1em;
  }
  align-items: center;
}
