@import "styles/variables.scss";

.top-bar {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  background-color: $brandSecondary;
  border-bottom: 1px solid $lightGray;
  padding: 0 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left,
.right,
.profile,
.profile-top {
  display: flex;
  align-items: center;
}

.right {
  gap: 30px;
}

.profile {
  gap: 10px;
  // cursor: pointer;
}

.delete-button {
  background-color: $error !important;
  color: white;
}

.logout {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  padding: 5px 20px;
}

.profile-link {
  &:hover {
    background-color: $brandAccent;
    color: $brandSecondary;
  }
}

.profile-top {
  gap: 10px;
  cursor: pointer;
  width: fit-content;
  height: 30px;
}

.profile-picture {
  height: 100%;
}

.logout {
  cursor: pointer;
  &:hover {
    background-color: $error;
    color: white;
  }
}

.close-menu {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 15px;
  bottom: 10px;

  margin-right: 10px;
}

.logout-icon {
  position: relative;
  right: 3px;
}

.version {
  // font-size: 14px;
  width: fit-content;
}

.sub-menu {
  position: relative;
  width: 0;
  height: 0;
  top: 25px;
  left: calc(-200px - 8px);
  .container {
    -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
    // padding: 10px 20px;
    min-width: 200px;
    background-color: $brandSecondary;
  }
}

.menu {
  display: none;
}

@media screen and (max-width: $mobileBreakpoint) {
  .top-bar {
    padding: 0 1rem;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .version,
  .profile {
    display: none;
  }
}
