/*
For bold:
font-weight: 700
*/

@import "variables.scss";

@font-face {
  font-family: "Bitter";
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
  // font-named-instance: 'Bold';
  src: url("../static/fonts/Bitter/Bitter-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Albert Sans";
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
  // font-named-instance: 'Bold';
  src: url("../static/fonts/Albert_Sans/AlbertSans-VariableFont_wght.ttf")
    format("truetype");
}

body {
  font-family: "Albert Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $brandBlack;
}

a {
  &:hover {
    color: $brandAccent;
  }
}

.body2 {
  font-size: 16px;
  line-height: 24px;
}

.body3 {
  font-size: 12px;
  line-height: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-10 {
  font-size: 14px;
  line-height: 18px;
}

h1 {
  // font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
}

h2 {
  // font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  margin: 1em 0;
  font-size: 24px;
  line-height: 32px;
}

h3 {
  // font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
}

h4 {
  // font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin: 1em 0;
}

h5 {
  // font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

a {
  text-decoration: none;
  color: $brandBlack;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.text-center {
  text-align: center;
}

.error {
  color: $error !important;
}

.validText {
  color: $success !important;
}
