@import "styles/variables.scss";

.concurrent-tasks {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 10px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .concurrent-tasks {
    flex-direction: column;
    align-items: flex-end;
  }
}
