@import "styles/variables.scss";

.add-cross-sell-modal {
  background-color: $shadeSecondary;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;

  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.add-cross-sell-modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.add-cross-sell-modal-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
