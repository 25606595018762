.section-title {
  width: 100%;
}

.customer-trackers-list {
  height: 100%;
  overflow: scroll;
}

.trackers-list {
  height: 100%;
  width: 100%;
  flex: 1;
}

.trackers-table {
  width: 100%;
}

.loading-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
