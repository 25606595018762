@import "styles/variables.scss";

.series {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0.5em;
}
.series-content {
  width: 100%;
}

.gp-calendar-container {
  width: 100%;
  max-width: 100%;
}

.gp-calendar-body {
  display: flex;
  flex-direction: row;
}

.gp-calendar-body-days {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}

/////////////////////////////// SeriesCalendarItem ///////////////////////////////
.gp-calendar-series-item {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: black;
  border: 1px solid #fff;
  overflow: hidden;
  border-radius: 4px;
  padding: 0.25em;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
}

.gp-calendar-series-item-add {
  // opacity: 0.75;
  justify-content: center;
  align-items: center;
  background-color: $brandSecondary;
  &:not(:last-child) {
    border-right-color: $lightGray;
    border-radius: 0;
  }
}

.gp-calendar-series-item-empty {
  opacity: 0.3;
  cursor: default;

  &:hover {
    filter: none;
  }
}

.gp-calendar-series-item-emty-icon {
  color: white;
  opacity: 0.75;
}

.gp-calendar-series-item-title {
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 0.8em;
}

.gp-calendar-item-subtitle {
  text-overflow: ellipsis;
  font-size: 0.8em;
}

.gp-calendar-series-item-add-series-length-container {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.gp-calendar-series-item-add-series-max-length {
  font-size: 0.75em;
  align-self: flex-end;
  vertical-align: bottom;
  font-style: italic;
}

.gp-calendar-series-item-add-series-length {
  font-size: 1.25em;
  font-weight: bold;

// color: #faaf90;
// color: #b3c7f7;
// color: #8babf1;
// color: #b9e192;
// color: #b3c7f7;
// color: #f194b8;
}

.gp-calendar-series-item-add-series-length-success {
  color: $success;
}

.gp-calendar-series-item-add-series-length-error {
  color: $error;
}
// .gp-calendar-series-item-state-container {
//   // position: absolute;
//   // top: 0px;
//   // left: 0px;
//   // // background-color: #ffffff55;
//   // width: 100%;
//   height: 100%;
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
//   border-right: 4px solid $success;
// }
.item-state {
  height: 100%;
  width: 8px;
  position:absolute;
  top: 0;
  right: 0;
  border-left: 1px solid $lightGray;
}
.item-state-1_todo {
  background-color: $warning;
  // border-right: 8px solid $warning;
}
.item-state-2_in_progress {
  background-color: $warning;
}
.item-state-3_done {
  background-color: $success;
}
.item-state-4_controlled {
  background-color: $success;
}

.add-product-modal-content-product-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-product-modal-content-add-button {
  width: 42px !important;
  height: 100%;
}

.add-product-modal-content-button-icon {
  height: 25px;
  width: 25px;
}

// .gp-calendar-series-item-done-icon {
//   width: 100%;
//   height: 100%;
//   color: $success;
//   // color: #75d78eb3;
//   // stroke: #ffffff48;
//   // stroke-width: 1px;
// }

// .stamp {
//   position: relative;
//   display: inline-block;
//   color: $success;
//   // padding: 15px;
//   background-color:transparent;
//   // box-shadow:inset 0px 0px 0px 6px $success;
//   font-weight: bolder;
//   width: 100%;
//   height: 100%;
// }

// .stamp:after {
//   content: '';
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url("http://i.imgur.com/5O74VI6.jpg");
//   mix-blend-mode: lighten;
// }


@media screen and (max-width: $mobileBreakpoint) {
  .series {
    padding: 0.5em 0;
  }
}

// /////////////////////////////// GPCalendarHeader ///////////////////////////////
// .gp-calendar-header {
//   display: flex;
//   flex-direction: row;
//   padding: 1em 0;
// }

// .gp-calendar-date-navigation {
//   display: flex;
//   flex-direction: row;
// }

// .gp-calendar-date-arrow {
//   font-size: 1.5em;
//   width: 74px;
//   text-align: center;
//   cursor: pointer;
// }

// .gp-calendar-header-buttons-container {
//   display: flex;
//   flex-direction: row;
//   margin-left: auto;
//   margin-right: 1em;
// }

// .gp-calendar-header-button {
//   height: 1.5em;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   margin-left: 1em;
// }

// .gp-calendar-header-button-icon {
//   max-height: 100%;
// }

// .gp-calendar-date-picker {
//   display: inline-block;
//   position: relative;
//   min-width: 280px;
//   text-align: center;
//   // width: 18px;
//   // height: 18px;
// }

// .gp-calendar-date-picker-input {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0;
//   cursor: pointer;
//   box-sizing: border-box;
// }

// .gp-calendar-date-picker-input::-webkit-calendar-picker-indicator {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   cursor: pointer;
// }

// /////////////////////////////// GPCalendarRowsAxis ///////////////////////////////
// .gp-calendar-rows-axis-container {
//   //   background-color: beige;
//   border-left: 1px solid rgba(224, 224, 224, 1);
// }
// .gp-calendar-rows-axis-item {
//   height: 58px;
//   flex: 1;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   padding: 0em 1em;
// }

// .gp-calendar-day-column-header {
//   height: 96px;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   cursor: pointer;
// }

// /////////////////////////////// GPCalendarDayColumn ///////////////////////////////

// .gp-calendar-day-column-container {
//   //   background-color: azure;
//   flex: 1;
//   border-left: 1px solid rgba(224, 224, 224, 1);
//   max-width: 100%;
// }

// .gp-calendar-day-row {
//   height: 58px;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   display: flex;
//   flex-direction: row;
//   flex: 1;
// }

// .skeleton {
//   animation: skeleton-loading 1s linear infinite alternate;
//   color: transparent;
// }

// @keyframes skeleton-loading {
//   0% {
//     background-color: #f5f5f5; /* FROM Color 1 */
//   }
//   100% {
//     background-color: #e2e2e2; /* TO Color 2 */
//   }
// }

// .gp-calendar-day-row-weekly {
//   max-width: 20%;
// }

// .gp-calendar-day-column-header {
//   height: 96px;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   text-align: center;
// }

// .gp-calendar-day-column-header-today {
//   color: blue;
// }

// .gp-calendar-day-column-header-day {
//   font-size: 1em;
//   margin-bottom: 0.25em;
// }

// .gp-calendar-day-column-header-date {
//   font-size: 2em;
// }

// /////////////////////////////// GPCalendarItem ///////////////////////////////

// .gp-calendar-item {
//   flex: 1;
//   flex-direction: column;
//   background-color: $info;
//   color: black;
//   border: 1px solid #fff;
//   overflow: hidden;
//   border-radius: 4px;
//   padding: 0.25em;
//   cursor: pointer;
//   &:hover {
//     filter: brightness(90%);
//   }
// }

// .gp-calendar-item-title {
//   text-overflow: ellipsis;
// }

// .gp-calendar-item-subtitle {
//   text-overflow: ellipsis;
//   font-size: 0.8em;
// }
