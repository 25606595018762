@import "styles/variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  height: 40px;
}

.tabs-section {
  height: 100%;
  display: flex;
}

.tab-item {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding: 10px;
  line-height: 16px;
  font-size: 16px;
  opacity: 0.6;

  &:hover {
    color: $brandAccent;
  }
}

.tabs-text {
  padding-left: 0.25rem;
}

.tab-item:after {
  margin-top: 5px;
  display: block;
  content: "";
  border-bottom: 2px solid $brandAccent;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.tab-item:hover:after {
  transform: scaleX(1);
}

.tab-selected {
  color: $brandAccent;
  font-weight: 500;
  opacity: 1;
}

.tab-selected:after {
  transform: scaleX(1);
}

.header-right {
  display: flex;
}

.icon {
  height: 20px !important;
}

.more-tools {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $mobileBreakpoint) {
  .tabs-text {
    display: none;
  }
}
