@import "styles/variables.scss";

.button-chronopost {
  display: flex;
  align-items: center;
  gap: 20px;
}

.external-shipping-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 210px;
}

.external-id {
  padding: 10px;
  // width: fit-content;
  display: flex;
  width: 210px;
  justify-content: center;
  border: 1px solid $brandPrimary;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 10px;
  // justify-content: center;
}

.download-button {
  background-color: $brandPrimary;
  order: 2;
  &:hover {
    background-color: $brandAccent;
  }
}

.download-button,
.cancel-button {
  cursor: pointer;
  color: $brandAccent;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  &:hover {
    color: $brandPrimary;
  }
}

.cancel-button {
  order: 3;
  // background-color: $brandPrimary;
  color: $brandPrimary;

  &:hover {
    // background-color: $brandAccent;
    color: $error;
  }
}

.access-button {
  cursor: pointer;
  background-color: $brandPrimary;
  color: $brandAccent;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}
