@import "styles/variables.scss";

.shipping-service-button {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  border: 1px solid $lightGray;
  font-weight: 900;
}

.hover:hover {
  background-color: $shadeAccent;
  border-color: $brandAccent;

  color: $brandPrimary;
}

.active:active {
  background-color: $shadeAccent;
  border-color: $brandAccent;

  color: $brandPrimary;
}

.toggled-button {
  background-color: $shadeAccent;
  border-color: $brandAccent;

  color: $brandPrimary;
}
