@import "styles/variables.scss";

.cross-selling {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .headers {
    display: flex;
    // gap: 2px;
    font-size: 16px;
    .header-element {
      flex: 1;
      // width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      justify-content: center;
      // min-width: 175px;
      padding: 10px;
      font-weight: bold;
      background-color: $brandPrimary;
      color: $brandSecondary;

      &:first-child {
        border-top-left-radius: 5px;
        flex: 4;
      }

      &:nth-child(2) {
        flex: 2;
        // min-width: 500px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        // min-width: 500px;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    gap: 40px;
    min-height: 50px;
    margin-bottom: 20px;

    .title {
      font-size: 36px;
      cursor: default;
    }

    .add-button {
      background-color: $brandAccent;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $brandPrimary;
        color: $brandAccent;
      }
    }
  }
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters-container-left {
  display: flex;
  align-items: center;
}

.category-filter-container {
  // display: flex;
  margin-right: 50px;
}

.category-list {
  display: flex;
  align-items: center;
  // height: 48px;
}

.reset-filters-container {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.reset-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.category-item {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.color-picker-container {
  width: 300px;
}

.selected {
  background-color: $brandPrimary;
  color: $brandAccent;
}

.error-message {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-size: 20px;
  color: $error;
}

@media screen and (max-width: 1400px) {
  .cross-selling {
    margin-top: 20px;
  }
}
