@import "styles/variables.scss";

.modal-product-miniature {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $darkGray;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  max-width: 216px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.selected {
  border: 2px solid $brandAccent;
  background-color: $shadeAccent !important;
  color: black !important;
  padding: calc(0.5rem - 1px) !important;
}

.modal-product-miniature-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-left: 0.5rem;
}

.modal-product-miniature-right-top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
}

.brand {
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90px;
}

.product-operation-group-miniature {
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}

.product-group-logo {
  height: 20px !important;
  margin-right: 0.5rem;
  object-fit: scale-down;
}

.image-miniature {
  height: 72px !important;
  width: 72px !important;
  object-fit: scale-down;
}

.image-miniature-container {
  height: 72px !important;
  width: 72px !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .image-miniature-container,
  .brand {
    display: none;
  }
  .modal-product-miniature-right {
    flex-direction: row;
  }

  .modal-product-miniature {
    width: 160px;
    height: fit-content;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
}
