@import "styles/variables.scss";

.button-container {
  display: flex;
  margin-top: 50px;
  justify-content: right;
}

.validate {
  background-color: $success;
  color: $brandSecondary;
}

.disabled,
.validate {
  margin-left: 20px;
}

.cancel {
  background-color: $error;
  color: $brandSecondary;
}

.cancel,
.validate,
.disabled {
  &:hover {
    color: white !important;
  }
}

.cancel,
.validate {
  display: flex;
  justify-content: space-around;
}

.disabled {
  display: flex;
  justify-content: space-around;
  background-color: lightgray;
}
