@import "styles/variables.scss";

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact-form {
  width: 100%;
}

.shipping-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 1rem;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.shipping-form-container {
  width: 100%;
}

.simple-product-form-container {
  margin-top: 20px;
  margin-bottom: 40px;
}

.validate-shipping-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.section-form-title {
  font-size: 14px;
}

.separator {
  margin: 20px 0px 20px 0px !important;
}

.title {
  font-size: 30px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  display: flex;
}

.top-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (max-width: 1400px) {
  .shipping-form {
    margin-top: 20px;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .shipping-form {
    padding: 0px 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .title {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    gap: 20px;
  }
}
