@import "styles/variables.scss";

.incoming-shipping-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.shipping-service-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.incoming-shipping-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 100%;
  gap: 20px;
  //   justify-content: center;
}

.shipping-service-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(white, $shadePrimary);
  font-weight: 900;

  &:hover {
    background-image: linear-gradient($shadeAccent, $brandAccent);
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

.toggled-button {
  background-image: linear-gradient($shadeAccent, $brandAccent);
  background-color: $brandAccent;
  color: $brandPrimary;
}

.title {
  font-size: clamp(1rem, 2.5vw, 2rem);
}

@media screen and (max-width: $laptopBreakpoint) {
  .incoming-shipping-container {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .incoming-shipping-container {
    padding: 0.5rem;
  }
}
