@import "styles/variables.scss";

.list-header {
  width: 100%;
  border: 0px !important;
}

.list-header-row {
  display: table-row;
  width: 100%;
}

.list-header-cell {
  border: 0px !important;
  display: table-cell;
  text-align: left;
  padding: 0 1rem;
  font-weight: 600 !important;
  font-size: 0.875rem;

  background-color: $shadeSecondary;
  height: 48px;
  line-height: 20px;
}

.center-cell {
  text-align: center !important;
}

.product-cell {
  // min-width: 300px !important;
}

.sort-cell {
  cursor: pointer;
  align-items: center;
  user-select: none;
  display: flex;
}
