@import "styles/variables.scss";

.cross-sell-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 20px;
}

.category-filter-container {
  margin-right: 50px;
}

.category-list {
  display: flex;
  align-items: center;
  width: 100%;
}

.category-item {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.color-picker-container {
  width: 100%;
  max-width: 270px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .cross-sell-filters {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: $mobileBreakpoint) {
  .category-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .category-item {
    margin: 0px 0px 10px 0px;
  }
}
