@import "variables.scss";

label {
  font-family: "Albert Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $brandBlack;
  margin-bottom: 6px;
}

input {
  min-width: 217px;
  width: 100%;
  height: 36px;

  padding: 0 1em;

  background: #ffffff;
  /* Shades/Light Gray */

  outline: unset !important;
  border: 1px solid black;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 16px;

  &::placeholder {
    font-style: italic;
    font-size: 14px;
    opacity: 0.5;
  }

  &:active {
    outline: 1px solid $brandAccent !important;
    background-color: $shadeAccent;
  }

  &:hover {
    border-color: $brandAccent;
  }

  &:focus {
    box-shadow: $inputShadow;
  }

  &:disabled {
    border-color: $lightGray;
    background-color: $brandSecondary;
  }

  .warning {
    border-color: $warning;
  }

  .success {
    border-color: $success;
  }

  .error {
    border-color: $error;
  }

  .info {
    border-color: $info;
  }

  // @media screen and (max-width: 768px) {
  //   width: auto;
  // }
}

input[type="checkbox"] {
  min-width: unset !important;
  height: 20px;
  width: 20px;
}

select {
  min-width: 217px;
  width: 100%;
  height: 48px;

  padding-left: 1em;

  background: #ffffff;
  /* Shades/Light Gray */

  border: 1px solid $lightGray;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 16px;

  &:hover {
    border-color: $hoverPrimary;
  }

  &:focus {
    box-shadow: $inputShadow;
  }

  &:disabled {
    border-color: $lightGray;
    background-color: $brandSecondary;
  }

  .warning {
    border-color: $warning;
  }

  .success {
    border-color: $success;
  }

  .error {
    border-color: $error;
  }

  .info {
    border-color: $info;
  }

  // @media screen and (max-width: 768px) {
  //   width: auto;
  // }
}

// .one-line-form {
//     display: flex;
//     flex-direction: row;
//     width: 100%;
// }

// @media screen and (max-width: 768px) {
//     .one-line-form {
//         display: flex;
//         flex-direction: column;
//         width: 100%;
//     }
// }
