.product-list {
  list-style: none;
}

.sub-total {
  opacity: 0.8;
  margin: 0rem 0 !important;
  font-size: 0.9rem !important;
}

.total {
  border-bottom: 1px solid black;
}

.total-refashion-section {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
}

.total-refashion {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.discount-logo {
  height: 1.5rem;
  margin-left: 0.3rem;
}

.refashion-price {
  margin-inline: 0.3rem;
}

.no-margin-bottom {
  margin-bottom: 0;
}
