@import "variables.scss";
@import "fonts.scss";
@import "buttons.scss";
@import "mobile.scss";
@import "forms.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
}

#root {
  max-width: 100%;
  flex: 1;
}

p {
  // margin-bottom: 1em;
}

ul {
  list-style: inside;
}

hr {
  // background-color: red;
  border-color: $lightGray;
}

//google place autocomplete dropdown
.pac-container {
  z-index: 2001;
}

.pac-item {
  padding: 5px;
}

summary {
  position: relative;
  // border: 2.5px inset grey;
  padding: 6px;
  margin: 1rem 0;
  cursor: pointer;
  // transform: scaleX(-1);
}

summary b {
  display: inline-block;
  position: absolute;
  right: 6px;
  // transform: scaleX(-1);
}

.details-content {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin: 2.5vh auto;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $brandAccent;
  -webkit-border-radius: 1ex;
}

.bg-white {
  background-color: $background;
}

.bg-primary {
  background-color: $shadePrimary;
}

.bg-secondary {
  background-color: $shadeSecondary;
}

.bg-gray {
  background-color: $brandSecondary !important;
}

.bg-accent {
  background-color: $brandAccent;
}

.color-primary {
  color: $brandPrimary;
}

.color-white {
  color: $background;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.reverse-row {
  display: flex;
  flex-direction: row-reverse;
}

.mw-1088 {
  max-width: 1088px;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.h-100vh {
  height: 100vh;
}

.min-h-100vh {
  min-height: 100vh;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.margin-1 {
  margin: 1em;
}

.margin-2 {
  margin: 2em;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 2em;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.padding-1 {
  padding: 1em;
}

.padding-2 {
  padding: 2em;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 1em;
}

.pb-2 {
  padding-bottom: 2em;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1em;
}

.pl-2 {
  padding-left: 2em;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 1em;
}

.pr-2 {
  padding-right: 2em;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-justify {
  text-align: justify;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

*[class^="col-"] {
  display: flex;
  flex-direction: column;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.666666666666667%;
}

.col-10 {
  width: 83.333333333333333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.666666666666667%;
}

.col-7 {
  width: 58.333333333333333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.666666666666667%;
}

.col-4 {
  width: 33.333333333333333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.666666666666667%;
}

.col-1 {
  width: 8.333333333333333%;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
}

// .display-xs {
//   @media screen and (min-width: 768px) {
//     display: none !important;
//   }
// }

// .display-md {
//   @media screen and (max-width: 768px) {
//     display: none !important;
//   }
// }

// @media screen and (max-width: 768px) {
//   *[class^="col-"] {
//     width: 100% !important;
//   }

//   ul {
//     list-style: outside;
//   }

//   .mobile-order-2 {
//     order: 2;
//   }
// }

// .padding-2-web {
//   padding: 10px;
// }

// @media screen and (min-width: 768px) {
//   .pl-2-web {
//     padding-left: 2rem;
//   }

//   .pr-2-web {
//     padding-right: 2rem;
//   }

//   .padding-2-web {
//     padding: 2rem;
//   }
// }

.br-4-top {
  border-radius: 4px 4px 0px 0px;
}

.br-4-bottom {
  border-radius: 0px 0px 4px 4px;
}

.br-4 {
  border-radius: 4px;
}

.link {
  &:hover {
    color: $hoverPrimary !important;
  }
}

.selectable {
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border-color: $hoverPrimary;
  }
}

.selectable.selected {
  border-color: $hoverPrimary;
}

.thumb {
  max-width: 200px;
  max-height: 200px;
  border-radius: 4px;
  padding: 4px;
  overflow: hidden;
}

.border-primary {
  border: 1px solid $brandPrimary;
}

.border-secondary {
  border: 1px solid $brandSecondary;
}

.h-100 {
  height: 100%;
}

.h-40 {
  height: 120%;
}

.swiper-pagination-bullet-active {
  background: $brandSecondary !important;
}
