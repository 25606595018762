@import "styles/variables.scss";

@keyframes loading {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loading-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: $brandAccent;
}

.white {
  background-color: $brandSecondary;
}

.gold {
  background-color: $brandAccent;
}

.load {
  display: flex;
  gap: 8px;
}

.load .line:nth-last-child(1) {
  animation: loading 0.7s 0.1s linear infinite;
}
.load .line:nth-last-child(2) {
  animation: loading 0.7s 0.2s linear infinite;
}
.load .line:nth-last-child(3) {
  animation: loading 0.7s 0.3s linear infinite;
}

.loader4 {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: $brandAccent;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: $brandAccent;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}
@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader4 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
