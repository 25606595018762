@import "/src/styles/variables.scss";

.add-image {
  width: 100px;
  aspect-ratio: 1;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $brandAccent;
  border-radius: 3px;
  cursor: pointer;
}

.add-image-form {
  display: flex;
  // flex-direction: column;
}

.icon {
  // margin-right: 0.5rem;
  color: $brandAccent;
}

.file-input {
  display: none;
}

.image-section {
  width: 100%;
}
