@import "styles/variables.scss";

.responsive-menu-container {
  position: fixed;
  display: flex;
  top: 54px;
  right: 0;
  z-index: 2001;
  height: calc(100% - 54px);
  width: 100%;
}

.background {
  height: 100%;
  flex: 1;
  background-color: $transparent;
}

.responsive-menu {
  height: 100%;
  width: 100%;
  max-width: 400px;
  background-color: $brandSecondary;
  border-left: 1px solid $lightGray;
  overflow: auto;
  padding: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .responsive-menu {
    padding: 0.5rem;
  }
}
