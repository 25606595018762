@import "styles/variables.scss";

.modal-product-miniature-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.modal-product-miniature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  padding: 10px;
  border-radius: 4px;
}

.modal-product-miniature-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
}

.operation-list-container {
  height: 0px;
  width: 100%;
  overflow: visible;
  position: relative;
  z-index: 2;
}

.operation-list-wrapper {
  background-color: $brandPrimary;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 5px;
  position: relative;
  top: 5px;
  width: 100%;
}

.product-operation-group-miniature {
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}

.strikethrough {
  text-decoration: line-through;
  opacity: 0.5;
}

.image-miniature {
  height: 100px;
  width: 100px;
  object-fit: scale-down;
}
