@import "styles/variables.scss";

.product-card-grid {
  display: grid;
  grid-template-columns: auto 130px 1fr 160px auto;
  grid-gap: 8px;
  grid-auto-rows: minmax(1em, auto);
}

.grid-item-picture {
  grid-column: 1;
  grid-row: 1/5;
}

.grid-item-brand {
  grid-column: 2;
  grid-row: 1;
}

.grid-item-workshop-id {
  grid-column: 4;
  grid-row: 1/3;
}

.grid-item-acronyms {
  grid-column: 3;
  grid-row: 1;
}

.grid-item-operations {
  grid-column: 2/4;
  grid-row: 2/4;
  font-size: 1rem;
}

.grid-item-comments {
  grid-column: 2/4;
  grid-row: 4;
}

.grid-item-due-date {
  grid-column: 4;
  grid-row: 4/5;
}

.grid-item-state {
  grid-column: 4;
  grid-row: 3/5;
}

.grid-item-actions {
  grid-column: 5;
  grid-row: 1/5;
}

.picture {
  max-width: 100px !important;
  max-height: 100px !important;
}

.state {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.brand {
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
}

.due-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
}

.operations-acronym-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.operations-acronym {
  border: 2px solid black;
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 10px;
  line-height: 14px;
  min-width: 47px;
  display: flex;
  justify-content: center;
}

.product-card {
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border: 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  // &:active {
  //   filter: brightness(90%);
  // }
}

.product-card-is-active {
  // filter: brightness(105%);
  // border-left: 4px solid $brandAccent;
}

.workshop-id {
  min-width: 120px;
  margin-left: auto;
}

.hide {
  display: none !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .product-card-grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 6px;
    grid-auto-rows: auto;
  }

  .grid-item-picture {
    display: none;
  }

  .grid-item-brand {
    grid-column: 1;
    grid-row: 2;
  }

  .grid-item-workshop-id {
    grid-column: 1;
    grid-row: 1;
  }

  .grid-item-acronyms {
    grid-column: 2;
    grid-row: 2;
  }

  .grid-item-operations {
    display: none;
  }

  .grid-item-comments {
    display: none;
  }

  .grid-item-due-date {
    grid-column: 2;
    grid-row: 1;
  }

  .grid-item-state {
    grid-column: 2;
    grid-row: 1;
  }
  .workshop-id {
    min-width: 100px;
    font-size: 0.875rem !important;
    line-height: 1rem;
  }

  .operations-acronym-list {
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-right: 0;
  }
}
