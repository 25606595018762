@import "styles/variables.scss";

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.item-coord {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.displayname {
  font-weight: 500;
}

.coordinates {
  width: 350px !important;
  // max-width: 450px !important;
  display: flex;
  align-items: center;
}

.icon {
  height: 20px !important;
  width: 20px !important;
  margin-left: 1rem;
}

.item-product {
  // display: flex;
}

.status-box,
.item-date {
  width: 140px !important;
}
.tools {
  width: 90px !important;
}

.item-date {
  cursor: pointer;
}

.visibility-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px !important;
  width: 17px !important;
}

.visibility-off {
  opacity: 0.5;
}

.hover:hover,
.active:active {
  cursor: pointer;
  opacity: 0.8;
}

.product-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-cell {
  cursor: pointer;
  width: 100% !important;
  min-width: 200px !important;
}

.product-group-logo {
  width: 20px !important;
  height: 20px !important;
  margin-right: 0.5rem;
}

.product-group-cell {
  display: flex;
  align-items: center;
}
