@import "styles/variables.scss";

.option-list-item-icon {
  width: 20px !important;
  margin-right: 10px;
}

.option-list-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  padding: 10px;

  &:hover {
    background-color: $lightGray;
  }
}

.disabled {
  cursor: default;
  // background-color: gray;
  color: $darkGray;
  opacity: 0.8;
  pointer-events: none;

  &:hover {
    background-color: unset;
    color: $darkGray;
  }
}
