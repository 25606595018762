.checkbox-item {
  align-items: center;
  cursor: pointer;
  display: flex;

  input {
    cursor: pointer;
  }
}

.label {
  margin: 0px 0px 0px 5px !important;
  cursor: pointer !important;
  user-select: none;
  order: 2 !important;
}
