@import "styles/variables.scss";

.item {
  display: flex;
  gap: 2px;
  font-size: 16px;
  align-items: center;
  max-width: 1500px;
  width: 85vw;

  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}

.item-left {
  cursor: pointer;
  display: flex;
  flex: 4;
}

.item-element {
  text-align: center;
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coordinates,
.discount {
  display: flex;
  flex-direction: column;
}

.coordinates {
  align-items: flex-start;
}

.none-text {
  font-style: italic;
  font-weight: 100;
  font-size: small;
}

.tools {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.mylink {
  display: flex;
  align-items: center;
  &:hover {
    color: $hoverAccent;
  }
}

.more-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 13px;
  // padding: 10px;
  margin-left: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $brandAccent;
  }
}

.icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:nth-child(2) {
    &:hover {
      color: $error;
    }
  }
}

.delete-icon {
  &:hover {
    color: $error;
  }
}

.substract-quantity {
  cursor: pointer;
  height: 24px;
  width: 24px;

  &:hover {
    color: $brandAccent;
  }
}

.true-icon {
  color: $success;
}

.false-icon {
  color: $error;
}

.state-container {
  min-width: 100px;
  background-color: $success;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
}

@media screen and (max-width: 1700px) {
  .item {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .item {
    // max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    width: 85vw;
  }
}

@media screen and (max-width: 1235px) {
  .item {
    flex-direction: column;
    align-items: flex-start;

    .tools {
      width: 100%;
      padding-top: 20px;
      .icon {
        height: 30px;
        width: 30px;
      }
    }
  }
}
