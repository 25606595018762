@import "styles/variables.scss";

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
  margin: 0 10px;
}

.price {
  // font-size: 1rem;
  font-weight: 500;
}

.product-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.7rem 0;
  font-size: 1rem;
  // border-bottom: 1px solid $brandPrimary;
}

.title {
  font-weight: 500;
}
