@import "styles/variables.scss";

.series {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin: 1rem 0;
  width: 100%;
}

.nav-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 1rem 1rem;
    justify-content: center;
}

.nav-item {
    margin: .5rem .5rem;
    padding: .5rem;
    box-shadow: $primaryShadow;
    -webkit-box-shadow: $primaryShadow;
    background-color: $shadeSecondary;
}

.series-content {
    width: 100%;
}