@import "styles/variables.scss";

.timeline-item {
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: flex-start;
  // min-height: 50px;
}

.timeline-item-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 84px;
  width: 50px !important;
  padding-right: 0.7rem;
  height: 100%;
}

.timeline-item-right {
  display: flex;
  padding-bottom: 1rem;
}

.timeline-item-right-top {
  padding-right: 0.7rem;
}

.radio-icon {
  height: 15px;
  width: 15px;
}

.radio-icon,
.icon,
.separator-line,
.line {
  color: $brandAccent;
  border-color: $brandAccent !important;
}

.line {
  flex: 1;
  width: 0;
  height: 100%;
  border: 1px solid $brandPrimary;
}

.message {
  font-size: 0.925rem;
  width: 100%;
  white-space: pre-wrap;
}

.timeline-date {
  font-size: 0.7rem;
  opacity: 0.7;
  height: 15px;

  display: flex;
  align-items: center;
}

.information {
  min-height: 24px;
  font-size: 0.875rem;
  opacity: 0.7;
}

.separator-line {
  width: 0;
  height: 20px;
  border: 1px solid $brandPrimary;
}

.separator-line-container {
  width: 50px;
  padding-right: 0.7rem;
  display: flex;
  justify-content: center;
}

.delete {
  margin-left: auto;
  cursor: pointer;
}

.delete-icon {
  height: 20px !important;
  width: 20px !important;
}
