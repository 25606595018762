.radio-item {
  align-items: center;
  cursor: pointer;
  display: flex;

  .input {
    cursor: pointer !important;
    height: 20px !important;
    min-width: 30px !important;
    width: unset !important;
  }
}

.label {
  margin: 0px 5px 0px 0px !important;
  cursor: pointer !important;
  user-select: none;
}
