@import "styles/variables.scss";

.color {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 160px;
  margin-left: 20px;
  font-size: 12px;
  line-height: 18px;
}

.color-box {
  height: 18px;
  width: 18px;
}
