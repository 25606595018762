@import "styles/variables.scss";

.cross-sell-miniature {
  cursor: pointer;
  background-color: $brandPrimary;
  color: white;
  box-shadow: $primaryShadow;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected {
  border: 2px solid $brandAccent;
  background-color: $shadeAccent !important;
  color: black !important;
  padding: calc(0.5rem - 1px) !important;
}

.cross-sell-miniature-left {
  flex: 2;
  height: 100%;
}

.cross-sell-number {
  font-size: 30px;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 0.875rem;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}

.icon {
  display: none !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .cross-sell-number,
  .title {
    display: none;
  }
  .icon {
    display: block !important;
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
  }
  .cross-sell-miniature-left {
    display: flex;
    align-items: center;
  }
}
