.validate-drop-off-modal {
  padding: 1rem;
  padding-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button-container {
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: auto;
  width: 100%;
  padding: 1rem 0;
}
