@import "styles/variables.scss";

.left {
  flex: 1;
}

.right {
  flex: 2;
}

.input-line {
  display: flex;
  gap: 5px;
}

.workshop-pro-form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.delete-button,
.validate-button {
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
  text-decoration: underline;

  &:hover {
    color: $brandAccent;
  }
}

.product-form-button {
  margin-right: 20px;
}

.product-form-validate-button {
  background-color: $success !important;
  color: white;

  &:hover {
    color: black !important;
  }
}

.delete-button {
  margin-right: 20px;
  &:active {
    color: $error;
  }
}

.operation-section-container {
  margin-top: 10px;
}

.select-container {
  width: 100%;
}

.workshop-pro-info-form {
  background-color: $shadeSecondary;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
}

@media screen and (max-width: $mobileBreakpoint) {
  .workshop-pro-info-form,
  .input-line {
    flex-direction: column;
  }
}
