@import "styles/variables.scss";

.gp-calendar-rows-axis-container {
  border-left: 1px solid rgba(224, 224, 224, 1);
  display: block;
  width: 64px;
}
.gp-calendar-rows-axis-item {
  height: 58px;
  flex: 1;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 0em .25em;
  font-size: .75em;
  text-align: right;
  color: gray;
  overflow: hidden;
  text-overflow:ellipsis;
}

.gp-calendar-rows-axis-day-column-header {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: center;
  height: 86px;
}

.hide {
  display: none;
}
@media screen and (max-width: $tabletBreakpoint) {
  .gp-calendar-rows-axis-day-column-header {
    height: 58px;
  }
}

