@import "styles/variables.scss";

.action {
  justify-content: center;
  overflow: visible;
  flex: 1;
}

.date-input {
  display: none;
}

.edit-icon {
  margin-left: 5px;
  font-size: 16px !important;
}

.next-shipping-date.hover:hover,
.next-shipping-date.active:active {
  color: $brandAccent;
}

.next-shipping-date,
.shipping-service {
  display: flex;
  flex-direction: column;
}

.next-shipping-date {
  cursor: pointer;
  width: fit-content;
  min-width: 130px;
  margin-right: 50px;
  // flex: 1;
}

.next-shipping-date-info {
  display: flex;
  align-items: center;
}

.shipping-service-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 300px;
}

.shop-info,
.action,
.shop-next-shipping {
  align-items: center;
  display: flex;
  padding: 10px;
}

.shop-info {
  display: flex;
  // flex-direction: column;

  align-items: center;
}

.shop-info {
  flex: 2;
}

.shop-next-shipping {
  display: flex;
  // justify-content: space-between;
  flex: 4;
}

.shipping-service {
  margin-right: 20px;
}

.tag-icon {
  font-size: 14px;
  margin-left: 5px;
}

.workshop-prefix-id {
  margin-right: 10px;
}

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.shop-item {
  display: flex;
  align-items: center;
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.coordinates {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.item-coord {
  width: 300px;
}

.displayname {
  font-weight: 500;
}

.item-product {
  // display: flex;
}

.status-box,
.item-date {
  width: 140px !important;
}
.tools {
  width: 90px !important;
}

.item-date {
  cursor: pointer;
}

@media screen and (max-width: $laptopBreakpoint) {
  .shop-next-shipping {
    font-size: 14px;
  }
}
