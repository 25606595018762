.shipping-summary {
  display: flex;
  align-items: center;
}

.time-slot {
  margin-left: 1rem;
  font-size: 0.9rem;
}

.repare-days {
  margin-left: 1rem;
  font-size: 0.9rem;
}

.shipping-address {
  font-size: 0.9rem;
  margin-left: 1rem;
}
