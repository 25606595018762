@import "styles/variables.scss";

.is-selected {
  border: 2px solid $brandAccent !important;
  background-color: $shadeAccentTransparency !important;
  font-weight: bold;
  padding: calc(1rem - 2px) !important;
}

.product-group {
  cursor: pointer;
  user-select: none !important;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid $darkGray;

  border-radius: 3px;

  p {
    font-size: 0.875rem;
    margin-bottom: 0 !important;
  }
}

.product-group-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.logo {
  width: 25px;
  margin-right: 0.5rem;
}

.section-title {
  margin-top: 0 !important;
}
