@import "styles/variables.scss";

.cross-sell-list {
  min-height: 200px;
  overflow: auto;
  max-height: 400px;
}

.cross-sell-products-grid {
  // background-color: red;
  height: fit-content;
  // flex: 2;
  margin-right: 20px;
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  overflow: auto;
}

.prepare-cross-sell-products {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  height: 100%;
}

.logistic-action-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
