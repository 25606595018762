@import "styles/variables.scss";

.disabled {
  pointer-events: none;
}

.hidden {
  display: none;
}

.input-container {
  position: relative;
  width: 100%;
}

.input {
  height: 36px;
  min-width: 260px;
  outline: unset !important;
  border: 1px solid $brandPrimary !important;
  border-radius: 3px !important;

  &:hover {
    border-color: $brandAccent;

    .expand-icon-container {
      color: $hoverAccent;
    }
  }

  &:active {
    outline: 1px solid $brandAccent !important;
    background-color: $shadeAccent;
  }

  &::placeholder {
    font-style: italic;
    font-size: 14px;
  }
}

.reset-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
}

.rbc {
  position: absolute;
  top: 28px;
  right: 36px;
  height: 0px;
  width: 0px;

  &:hover {
    color: $error;
  }
}
