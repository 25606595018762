@import "/src/styles/variables.scss";

.product-check-in-form-section {
  width: 100%;
  margin-left: 1rem;
}

.label-section {
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: $readCardShadow;
  background-color: $lightGrayTransparent60;
  border: 1px solid $darkGray;
  border-radius: 3px;
}

.label-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.title {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.front-label {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-end;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
