@import "styles/variables.scss";

.picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  // width: 100%;
}

.main-picture,
.other-picture,
.more-picture {
  // max-width: 650px;
  aspect-ratio: 1;
  object-fit: scale-down;
  border: 1px solid $lightGray;
  width: 100%;
}

.more-picture {
  max-width: 69px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  color: $darkGray;
}

.other-picture {
  max-width: 69px;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.other-picture-list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.5rem;
}
