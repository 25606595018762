@import "/src/styles/variables.scss";

.action-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: $shadeSecondary;
  border-top: 2px solid $brandAccent;
}

.action-container-left,
.action-container-right {
  display: flex;
}

@media screen and (max-width: $mobileBreakpoint) {
  .action-container,
  .action-container-right {
    flex-direction: column;
  }

  .action-container-left {
    padding-bottom: 0.5rem;
  }
}
