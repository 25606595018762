@import "styles/variables.scss";

.gp-calendar-header {
  display: flex;
  flex-direction: row;
}

.gp-calendar-date-navigation {
  display: flex;
  flex-direction: row;
  padding: 0.25em 0;
  justify-content: space-between;
  width: 420px;
}

.gp-calendar-date-arrow {
  font-size: 1.5em;
  width: 74px;
  text-align: center;
  cursor: pointer;
}

.gp-calendar-header-buttons-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding: 0.5em;
  margin-right: 1em;
}

.gp-calendar-header-button {
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 1em;
}

.gp-calendar-header-button-icon {
  max-height: 100%;
}

.gp-calendar-date-picker {
  display: inline-block;
  position: relative;
  text-align: center;
}

.gp-calendar-date-picker-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  min-width: auto
}

.gp-calendar-date-picker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.gp-calendar-header-buttons-separator {
  height: 100%;
  border-left: 1px solid rgba(224, 224, 224, 1);
  margin-left: 1em;
}

@media screen and (max-width: $mobileBreakpoint) {
  .gp-calendar-date-navigation {
    min-width: auto;
    width: 100%;
    justify-content: space-between;
  }

  .gp-calendar-header {
    flex-direction: column-reverse;
  }
}

