@import "styles/variables.scss";

.comment {
  font-style: italic;
  width: 100%;
}

.estimate-section {
  // margin-top: 0.5rem;
  font-size: 1rem;
}

.image-list-container {
  width: 150px;
  margin-right: 1rem;
}

.loading-section {
  padding: 1rem;
  width: 100%;
  border: 1px solid $lightGray;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  display: flex;
  margin-bottom: 1rem;
}

.more-details-button {
  all: unset;
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
}

.more-details-button.hover:hover,
.more-details-button.active:active {
  color: $brandAccent;
}

.image-miniature {
  height: 70px !important;
  width: 70px !important;
  margin-right: 1rem;
  border: 1px solid $lightGray;
}

.estimate-section-header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.estimate-section-header {
  align-items: flex-start;
}

.estimate-section {
  width: 100%;
}

@media screen and (max-width: $mobileBreakpoint) {
  .body {
    flex-direction: column-reverse;
  }
  .image-list-container {
    margin-right: 0;
    margin-top: 1rem;
  }
}
