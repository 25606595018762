@import "styles/variables.scss";

.modal-detailed-product {
  display: flex;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.modal-detailed-product-right {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 650px) {
  .modal-detailed-product {
    flex-direction: column;
  }
  .modal-detailed-product-right {
    flex: unset;
  }
}
