@import "styles/variables.scss";

.color-picker {
  //   height: 50px;
  overflow: visible;
  width: fit-content;
}

.color-picker-input {
  align-items: center;
  cursor: pointer;
  display: flex;
  //   height: 36px;
  //   width: 100%;
}

.color-picker-icon {
  background-color: white;
  border: 1px solid $brandPrimary;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;

  .icon {
    height: 25px;
    width: 25px;
  }

  &:active {
    border-color: $brandAccent;
    color: $brandAccent;
  }
}

.button-color-preview {
  display: flex;
}

.color-box {
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  transition: transform 0.3s ease-in-out;

  &:active {
    transform: scale(110%);
  }
}

.color-box.hover {
  &:hover {
    transform: scale(110%);
  }
}

.color-name-container {
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.color-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.color-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.color-preview-name {
  color: white;
  background-color: rgb(0, 0, 0, 0.3);
  padding: 10px;
  line-height: 13px;
  border-radius: 2px;
}

.color-picker-drop-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.color-picker-drop-input {
  height: 36px;
  //   width: 200px;
  padding-left: 36px;
  min-width: none !important;
  outline: unset !important;
}

.search-icon-container {
  align-items: center;
  display: flex;
  position: relative;
  left: 10px;
  width: 0px;
  overflow: visible;
}

.search-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;
}

.color-preview {
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: center;
  height: 110px;
}

.color-list {
  display: grid;
  width: fit-content;

  grid-template-columns: 45px 45px 45px 45px;
  grid-template-rows: 45px 45px 45px 45px;
  gap: 8px;
  //   gap: 10px;
  padding: 10px;
  height: fit-content;
}

.color-list-container {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: auto;
  padding: 10px;
}

.color-picker-drop {
  -webkit-box-shadow: $primaryShadow;
  background-color: white;
  box-shadow: $primaryShadow;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
  top: 5px;
  width: fit-content;
  z-index: 50;
}

.mobile-tablet-hovered {
  border: 2px solid $brandAccent;
  border-radius: 4px;
  transform: scale(120%);
}

.placeholder {
  font-size: 14px;
  font-style: italic;
  opacity: 0.8;
  user-select: none;
}

.reset-color {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;

  &:active {
    color: $error;
  }
}
