@import "styles/variables.scss";

.image-preview-miniature {
  filter: unset;
  height: 140px !important;
  object-fit: scale-down;
  padding: 3px;
  width: 140px !important;

  &:not(:last-child) {
    margin-right: 5px;
  }
  &:hover {
    border: 3px solid $brandAccent;
    padding: 0px;
  }
}

.delete-button {
  background-color: $error;
  color: white;
}
