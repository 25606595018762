@import "styles/variables.scss";

.customer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 20px;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
}

.form {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.icon {
  margin-left: 1rem;
}

.contact-form,
.address-form {
  width: 100%;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

@media screen and (max-width: $laptopBreakpoint) {
  .customer {
    padding: 0 1rem;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .customer {
    padding: 0 0.5rem;
  }
}
