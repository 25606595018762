@import "/src/styles/variables.scss";

.address {
  font-size: 0.875rem;
}

.brand {
  font-weight: 500;
  margin-left: 0.5rem;
}

.incoming-shipping-card {
  display: flex;
  width: 100%;
  background-color: $brandSecondary;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid $darkGray;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.incoming-shipping-card-left {
  flex: 1;
  margin-right: 2rem;
}
.incoming-shipping-card-right {
  flex: 2;
}

.operation-item {
  font-size: 1rem;
}

.products-list {
  list-style: none;
}

.product-item-header {
  display: flex;
}

.shipping-period {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.period {
  margin-right: 1rem;
  font-size: clamp(0.7rem, 2.5vw, 1rem);
}

.name {
  font-size: clamp(0.875rem, 2.5vw, 1rem);
}

@media screen and (max-width: $mobileBreakpoint) {
  .incoming-shipping-card {
    flex-direction: column;
  }

  .incoming-shipping-card-left {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
