@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 1rem;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

//className={style["contact-form"]}

.contact-form {
  width: 100%;
}

.separator {
  margin: 20px 0px 20px 0px !important;
}

.comment-input {
  width: 100%;
  padding: 5px;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  display: flex;
}

.simple-product-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.validate-button {
  background-color: $success;
  color: white;
}

.input-line {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.input-container {
  width: 35% !important;
}

.submit-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.error-message {
  color: $error;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.add-new-button {
  cursor: pointer;
  background-color: $brandPrimary;
  color: $brandAccent;
  border: none;
  width: fit-content;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;

  padding: 10px;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    margin-top: 20px;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .container {
    padding: 0px 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .title {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
