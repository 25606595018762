@import "/src/styles/variables.scss";

.error-message {
  font-size: 0.875rem;
  line-height: 0.9rem;
  font-style: italic;
}

.section-title {
  width: 100%;
}

.valid {
  color: $success;
}

.workshop-id-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.workshop-exists {
  display: flex;
  align-items: center;
}

.loading-spinner {
  width: 20px !important;
  height: 20px !important;
  border-width: 3px !important;
}
