@import "styles/variables.scss";

.item-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.item-product-left {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.product-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.icon {
  height: 22px !important;
  width: 22px !important;
  object-fit: scale-down;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.product-group-list {
  display: flex;
}

.brand {
  line-height: 16px !important;
}

.title,
.cross-sell-number {
  margin-right: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .title {
    display: none;
  }
}
