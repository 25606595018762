.logistic-confirm-modal {
  padding: 1rem;
}

.button-section {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.title {
  margin-bottom: 1rem;
}
