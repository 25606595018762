@import "styles/variables.scss";

.confirm-modal-comp {
  align-items: center;
  background-color: $shadeSecondary;
  border-radius: 4px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  display: flex;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 30px;
  width: fit-content;
  min-width: 500px;
}

.button-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.message {
  text-align: center;
  font-size: 14px;
}

.title {
  text-align: center;
  font-weight: 500;
}

.element {
  margin: 1rem 0px;
  width: 100%;
}

.mail-body {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;

  &::placeholder {
    font-style: italic;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .confirm-modal-comp {
    margin: 10px;
  }
}

@media screen and (max-width: 500px) {
  .confirm-modal-comp {
    min-width: unset;
    padding: 30px 10px;
  }
}
