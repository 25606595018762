@import "styles/variables.scss";

.download-container {
  display: flex;
  flex-direction: column;
}

.generate-label-button {
  // width: 260px;
}

.print-mode-list-container {
  height: 0px;
  overflow: visible;
  min-width: 164px;
}

.print-mode-list {
  background-color: $brandSecondary;
  // border: 2px solid $brandAccent;
  border-radius: 4px;
  box-shadow: $primaryShadow;

  font-size: 14px;
  position: relative;
  z-index: 100;
  top: 3px;
}

.mode-item {
  cursor: pointer;
  padding: 10px;
}

.mode-item.hover:hover,
.mode-item.active:active {
  background-color: $shadeAccent;
}
