@import "styles/variables.scss";

.body-section {
  height: 100%;
  width: 100%;
  display: flex;
}

.product-list {
  width: 250px;
  overflow: auto;
  padding: 10px;
  border-right: 1px double $lightGray;
}

.product-miniature {
  width: 100%;
  padding: 10px;
}

.product-miniature-container {
  padding: 10px;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: $shadeAccent;
    border-color: $brandAccent;
  }
}

.product-detailed-info {
  width: 100%;
  height: 100%;
}

.modal-product-list {
  width: 300px;
  padding: 0.5rem;
  height: 100%;
  overflow-x: auto;
  border-right: 1px solid $lightGray;
}

@media screen and (max-width: $mobileBreakpoint) {
  .modal-product-list {
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    min-height: 70px !important;
    border-right: 0;
    border-bottom: 1px double $lightGray;
    // margin-top: 0.5rem;
    overflow-x: unset;
    overflow-y: auto;
    padding: 0rem 0.5rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .body-section {
    flex-direction: column;
  }
}
