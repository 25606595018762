@import "styles/variables.scss";

.item {
  display: flex;
  gap: 2px;
  font-size: 16px;
  align-items: center;
  max-width: 1500px;
  width: 85vw;

  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}

.item-element {
  overflow: auto;
  text-align: center;
  align-items: center;
  justify-items: center;
  flex: 1;
  padding: 10px;

  &:nth-child(1) {
    display: flex;
    text-align: left;
    flex: 4;
  }

  &:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    flex: 2;
  }
}

.item-element-miniature {
  height: 70px;
  width: 70px;
  object-fit: cover;
  margin-right: 20px;
}

.delete-button {
  background-color: $error;
  color: white;
}

.tools {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: visible;
}

.mylink {
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: $hoverAccent;
  }
}

.icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  &:nth-child(3) {
    &:hover {
      color: $error;
    }
  }
}

.description {
  font-size: 18px;
}

.product-group-item {
  text-align: center;
  font-size: 13px;
  line-height: 12px;
  font-weight: bold;
  width: fit-content;
  padding: 10px;
  border-radius: 3px;
  border: 2px solid black;

  // background-color: $lightGray;
  margin-bottom: 5px;
  margin-top: 5px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1700px) {
  .item {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .item {
    margin-left: auto;
    margin-right: auto;
    // width: 90vw;
  }
}

@media screen and (max-width: 1235px) {
  .item {
    flex-direction: column;
    align-items: flex-start;

    .tools {
      width: 100%;
      padding-top: 20px;
      .icon {
        height: 30px;
        width: 30px;
      }
    }
  }
}
