@import "styles/variables.scss";

.price-cell {
  opacity: 0.5;
}

.selected {
  background-color: $shadeAccent;
  opacity: 1;
}

.item {
  display: table-row;
  font-size: 0.9rem;
  height: 50px !important;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.checkbox {
  height: 15px !important;
  width: 15px !important;
}
