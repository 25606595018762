@import "styles/variables.scss";

.operation-canceled {
  color: $lightGray;
  text-decoration: line-through;
}

.operation-description {
  // white-space: nowrap;
  // text-overflow: ellipsis;
  font-weight: bold;
}

.discount-logo {
  height: 2em;
}

.operation-discount-option {
  font-size: 12px;
  margin-left: 1em;
  padding: 0;
  border: none;
}

.operation-discount-select {
  flex: 1;
  border: 1px solid $brandPrimary;
  padding: 0.25em;
  margin-left: 1em;
  min-width: 24px;
  width: auto;
  height: auto;
  order: 0;
}

.operation-discount-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5em;
  padding-left: 5em;
}

.operation-dots {
  border: none;
  border-bottom: 1px dotted black;
  flex: 1;
}

.operation-line {
  display: flex;
  flex-direction: row;
}

.operation-list-item {
  list-style: none;
}

.operation-price {
  white-space: nowrap;
  font-weight: bold;
}

.product-informations {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.product-informations-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  // max-height: 530px;
  // overflow: auto;
  gap: 30px;
  // border: 1px solid red;
}

.product-informations-number {
  font-size: 14px;
  padding-bottom: 10px;
  margin-right: 1rem;
  font-style: italic;
  opacity: 0.5;
}

.product-informations-total {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.widget-title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.workshopId {
  width: 98px;
  font-size: 14px;
  height: fit-content;
  // font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 5px;
  background-color: $info;
  color: $brandSecondary;
  border-radius: 4px;

  .tag-icon {
    height: 18px !important;
    width: 18px !important;
  }
}

.product-informations-bottom {
  margin-top: 1rem;
}

.product-informations-bottom-top {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
