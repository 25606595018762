@import "styles/variables.scss";

.input-line {
  display: flex;
  gap: 5px;
}

.select-container {
  width: 100%;
}

.operation-form {
  flex: 2;
}

.operation-form-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.delete-button,
.validate-button {
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
  text-decoration: underline;
}

.validate-button {
  margin-right: 20px;
}

.delete-button {
  &:active {
    color: $error;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-section-top,
  .input-line {
    flex-direction: column;
  }
}
