@import "styles/variables.scss";

.auto-complete-operation-input {
  width: 100%;
}

.drop-down {
  max-height: 250px;
  position: relative;
  top: 2px;
  z-index: 10;
  border-radius: 4px;
  background-color: black;
  color: white;
  overflow: auto;
}

.drop-down-container {
  height: 0px;
  overflow: visible;
}

.drop-down-item {
  cursor: pointer;
  padding: 10px;
  list-style-type: none;

  &:hover {
    background-color: $brandAccent;
  }
}
