@import "styles/variables.scss";

.print-product-label-modal {
  background-color: $shadeSecondary;
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.label-preview-container {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-top: 1rem;
  width: fit-content;
}

.body {
  display: flex;
  padding-bottom: 1rem;
  background-color: $shadeSecondary;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
}

.modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .label-preview-container {
    grid-template-columns: 1fr;
  }
}
