@import "styles/variables.scss";

.search-by-key-component {
  display: flex;
  align-items: center;
}

.select-key-container {
  height: 36px;

  overflow: visible;
}

.input-value-container {
  height: 36px;
  width: 240px;
  overflow: visible;
  display: flex;
  margin-left: 10px;
}

.select-key,
.input-value {
  cursor: pointer;
  width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;

  border: 1px solid $brandPrimary;
  border-radius: 3px;

  &:hover {
    border-color: $brandAccent;

    .expand-icon-container {
      color: $hoverAccent;
    }
  }

  &:active {
    background-color: $shadeAccent;
  }
}

.input-value {
  cursor: text;
  &::placeholder {
    font-size: 14px;
    font-style: italic;
  }
}

.placeholder {
  font-size: 14px;
  line-height: 14px;
  font-style: italic;
  opacity: 0.7;
}

.expand-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon-container {
  position: relative;
  order: 2;
  right: 15px;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    cursor: pointer;
    color: $brandAccent;
  }
}

.options-list {
  padding-top: 5px;
}

.options-list-container {
  position: relative;
  z-index: 10;
  width: 240px;
  color: white;
  background-color: $nightBlue;
  border-radius: 3px;
  top: 2px;
  max-height: 250px;
  overflow: auto;
}

.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.option-item {
  cursor: pointer;
  line-height: 14px;
  font-size: 14px;
  padding: 10px;

  &:hover {
    background-color: $brandAccent;
  }
}

.large-item {
  padding: 20px;
  font-size: 16px;
}
