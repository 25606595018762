@import "styles/variables.scss";

.search-by-key-component {
  display: flex;
  align-items: center;
}

.input-value-container {
  height: 36px;
  width: 240px;
  overflow: visible;
  display: flex;
}

.input-value {
  cursor: pointer;
  width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 5px 10px;

  border: 1px solid $brandPrimary;
  border-radius: 3px;

  &:hover {
    border-color: $brandAccent;
  }

  &:active {
    background-color: $shadeAccent;
  }
}

.input-value {
  cursor: text;
  &::placeholder {
    font-size: 12px;
    font-style: italic;
    line-height: 12px;
  }

  &:focus {
    border-color: $brandAccent;
    outline: 1px solid $brandAccent;
  }
}

.close-icon-container {
  position: relative;
  order: 2;
  right: 15px;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    cursor: pointer;
    color: $brandAccent;
  }
}
