.canceled {
  text-decoration: line-through;
  opacity: 0.5;
}

.operation-list {
  list-style: none;
}

.product-description {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.operation-logo {
  height: 20px;
  margin-right: 0.5rem;
}

.brand {
  font-size: 1rem;
  font-weight: 500;
}
