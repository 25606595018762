@import "styles/variables.scss";

.modal {
  padding: 1rem;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.delete-button,
.validate-button,
.send-button {
  color: $brandSecondary;
  border-color: $brandSecondary;
}

.delete-button {
  background-color: $error;
}

.validate-button {
  background-color: $success;
}

.send-button {
  background-color: $info;
}

.confirm-button {
  background-color: $lightGray;
}
