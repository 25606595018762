@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  // max-height: 100%;
  overflow-y: scroll;
}

.products-container {
  height: 100%;
  overflow-y: scroll;
}

// .products-container {
//   display: flex;
//   flex-direction: column;
//   width: 400px;
//   overflow-y: auto;
// }

// .images-container {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   background-color: blue;
//   flex: 1;
// }

// .product-title {
//   display: flex;
//   padding: 8px 12px;
//   flex-direction: row;
//   justify-content: space-between;
// }

.workshop-id-miniature {
  width: 80px !important;
  height: 30px !important;
  font-size: 14px !important;
}

.product-container {
  padding: 8px;
}

.product-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.product-brand {
  font-weight: bold;
  margin-left: 6px;
  font-size: 24px;
}

.operation-container {
  // margin-left: 16px;
}

.operation-description {
}
.operation-description-refashion {
  font-weight: bold;
}

.operation-description-not-refashion {
  font-style: italic;
}
.product-miniature-container {
  padding: 10px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: $shadeAccent;
    border-color: $shadeAccent;
  }
}

.selected-product {
  border: 2px solid $shadeAccent;
  background-color: $shadeAccent;
  padding: 8px;
}

.product-detailed-info {
  // width: 100%;
  flex: 1;
  height: 100%;
  overflow: hidden;
  //   background-color: blue;
}

.product-operation-group-miniature,
.cross-sell-operation-group-miniature {
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}

.workshop-id-miniature {
  width: 80px !important;
  height: 30px !important;
  font-size: 14px !important;
}

.modal-detailed-product {
  display: flex;
  padding: 20px;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 2;
}
.product-info,
.product-info-line-item {
  display: flex;
  flex-direction: column;
  font-size: 34px;

  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
  }
}

.product-info-line-item {
  flex: 1;
  height: 50px;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
}

.product-info-line {
  display: flex;
  width: 100%;
}

.product-operation-group {
  font-size: 24px;
  font-weight: bold;
}

.color,
.empty-color {
  height: 20px;
  width: 20px;
}

.product-color {
  display: flex;
  align-items: center;

  gap: 10px;
  font-size: 20px;
}

.empty-color {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  position: relative;
}
.main-picture {
  margin-left: 6px;
  margin-right: 6px;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  border-radius: 6px;
  overflow: hidden;
  img {
    width: 160px;
    object-fit: scale-down;
    border-radius: 6px;
    border: 1px solid gray;
  }
}

.main-picture > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.picture-selected {
  border: 4px solid $brandAccent !important;
}

.picture-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1;
}

.check-mark-container {
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: $shadeAccentTransparency;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-mark {
  color: $brandAccent;
  width: 50% !important;
  height: 50% !important;
}

.hidden {
  display: none;
}
