@import "styles/variables.scss";

.change-quantity-card {
  position: absolute;
  // max-width: 250px;
  max-height: 150px;
  width: 100%;
  aspect-ratio: 0.6;

  .background {
    background-color: $lightGray;
    // max-width: 250px;
    max-height: 150px;
    width: 100%;
    aspect-ratio: 0.6;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.change-quantity-section {
  display: flex;
  gap: 10px;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  line-height: 24px;
}

.quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brandAccent;
  border-radius: 50px;
  cursor: pointer;

  width: 50px;
  height: 50px;

  &:active {
    background-color: $brandPrimary;
    .icon {
      color: $brandAccent;
    }
  }
  .icon {
    width: 40px;
    height: 40px;
  }
}

.quantity-value {
  min-width: 70px;
  text-align: center;
  font-size: 30px;
}

.delete-icon-container {
  position: relative;
  right: -190px;
  top: -140px;
  display: flex;
  width: 0px;
  height: 0px;
  overflow: visible;
  z-index: 20;

  cursor: pointer;

  &:active {
    .icon {
      color: $error;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

.delete-icon {
  display: flex;
  align-content: flex-end;
}
