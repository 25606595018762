@import "styles/variables.scss";

.expandable-list {
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}

.menu-item-link {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;

  &:hover {
    color: inherit;
  }
}

.menu-item-icon-container-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item-icon-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate {
  transform: rotate(90deg);
}

.menu-item-icon {
  height: 16px !important;
  width: 16px !important;
  // margin-right: ;
}

.menu-item-title {
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 0.5rem;
}
