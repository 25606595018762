@import "/src/styles/variables.scss";

.contact-modal {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contact-form {
  margin-bottom: 1rem;
}

.autocomplete-container {
  position: absolute;
  z-index: 2;
  height: 0;
  overflow: visible;
  top: 1.5rem;
  right: 0rem;
}

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer !important;
  padding: 1rem;
}

.autocomplete-label {
  margin: 0 0.25rem 0 0;
  cursor: pointer !important;
  user-select: none;
}
