@import "styles/variables.scss";

.contact-info-card-wrapper,
.small-contact-info-card-wrapper {
  position: fixed;
  z-index: 2000;
  top: 70px;
  right: 10px;
  display: flex;
  text-align: right;
  background-color: $brandAccent;
  border-radius: 4px;
  opacity: 0.9;
  padding: 20px;
}

.contact-info-container {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 10px;
}

.overview-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  font-size: 13px;
  &:hover {
    color: white !important;
  }
}

.small-contact-info-card-wrapper {
  cursor: pointer;
  padding: 10px;
}

.close-button {
  position: relative;
  top: -15px;
  right: -15px;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .contact-info-card-wrapper,
  .small-contact-info-card-wrapper {
    top: 10px;
  }
}

@media screen and (max-width: 960px) {
  .contact-info-card-wrapper,
  .small-contact-info-card-wrapper {
    top: 70px;
  }
}
