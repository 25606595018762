@import "styles/variables.scss";

.invoice-pro-modal {
  background-color: $shadeSecondary;
  display: flex;
  border-radius: 5px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  width: 100%;
  min-width: 1000px;
  overflow: auto;
}

.invoice-pro-modal-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
}
