@import "styles/variables.scss";

.checklist-item {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid $brandPrimary;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 4px;
  flex: 1;
  text-align: center;
}
.checked {
  background-color: $brandPrimary;
  color: $brandAccent;
}
