@import "styles/variables.scss";

.add-new-picture-button {
  background-color: unset;
  border: 3px dashed $brandAccent;

  cursor: pointer;
  height: 140px;
  margin-right: 5px;
  transition: filter 0.5s ease-in-out;
  width: 140px;

  // &:hover {
  //   filter: brightness(90%);
  //   .camera-icon {
  //     transform: scale(140%);
  //   }
  // }
}

.camera-icon {
  color: $brandAccent;
  font-size: 30px;
  transition: transform 0.5s ease-in-out;
}

.file-input {
  display: none;
}

.image-form {
  width: 100%;
}

.image-form-container,
.file-list-container,
.preview-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100px;
  width: 100%;
  overflow: auto;
}

.preview-list {
  display: flex;
  max-height: 280px;
  padding-bottom: 10px;
  overflow: auto;
}

.upload-image-button-container {
  display: flex;
}

.title {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.add-container {
  border-radius: 4px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-input {
  display: none;
}

.uploaded-image {
  display: flex;
}
