@import "styles/variables.scss";

.cross-selling-products-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.delete-button {
  align-items: center;
  cursor: pointer;
  display: flex;

  &:hover {
    color: $error;
  }
}

.description {
  margin-right: 20px;
  min-width: 150px;
}

.price {
  min-width: 3em;
}

.cart-quantity {
  margin-left: 5px;
  font-size: 14px;
}

.color-component {
  min-width: unset !important;
}

.dots {
  flex: 1;
  height: 0px;
  border-bottom: 1px dashed black;
  margin: 0px 10px;
}

.ready-icon {
  color: $success;
}

.state {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.not-ready-icon {
  color: $error;
}
