@import "styles/variables.scss";

.add-new-cart-pro-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  // max-height: 800px;
  overflow: auto;
  width: 100%;
  padding: 20px;
}

.add-new-cart-pro-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-new-cart-pro-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.comment {
  width: 100%;
  min-height: 100px;
  padding: 5px;
}
.separator {
  margin: 20px 0px !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .add-new-cart-pro-header {
    flex-direction: column;
  }
}
