@import "styles/variables.scss";

.expandable-container {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid $lightGray;
  border-bottom: 0;
  border-top: 0;
}

.expandable-component-title {
  padding: 30px;
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  margin-bottom: 20px;
  height: 100px;
  display: flex;
  align-items: center;

  gap: 30px;

  &:hover {
    color: $brandAccent;
  }

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.expand-icon {
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isFinished {
  color: $success;
}
