@import "styles/variables.scss";

.checklist {
  width: 100%;
}

.checklist-item {
  cursor: pointer;
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.checkbox-input {
  cursor: pointer;
  min-width: none !important;
  width: fit-content;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px dotted $darkGray;
  margin: 0 10px;
}
