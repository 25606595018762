@import "/src/styles/variables.scss";

.product-list {
  list-style: none;
}

.product-item {
  width: fit-content;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  border: 1px solid $darkGray;
  border-radius: 3px;
  background-color: white;
  padding: 0.5em;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.product-selected {
  opacity: 1;
  border: 2px solid $brandAccent;
  padding: calc(0.5em - 1px);
}

.product-group-icon {
  width: 30px;
  margin-left: 0.5rem;
}

.checkin-state {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.checked-icon,
.missing-icon {
  width: 30px !important;
}

.checked-icon {
  color: $success;
}

.missing-icon {
  color: $error;
}
