@import "styles/variables.scss";

.description-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  &:hover {
    color: $brandAccent;
    border-color: $brandAccent !important;
  }
}

.description {
  // flex: 2;
}

.dots {
  border-bottom: 1px dotted black;
  height: 1px;
  padding-inline: 5px;
  margin: 0px 10px;
  flex: 1;
}

.delete {
  display: flex;
  align-items: center;
  &:hover,
  &:active {
    color: $error;
  }
}

.operation-item {
  align-content: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  list-style: square;
  padding: 0px 30px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.operation-item.larger {
  padding: 0px 10px 0px 0px;

  .description-container {
    width: 100%;
  }
}
