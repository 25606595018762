@import "styles/variables.scss";

.expandable-menu {
  position: fixed;
  top: 0;
  z-index: 1996;
  height: 100%;
  width: 220px;
  background-color: white;
  border-right: 1px solid $lightGray;
  padding: 0.5rem;
  box-shadow: 6px 0px 15px -4px rgba(0, 0, 0, 0.35);
}

.sub-menu {
}

.sub-menu-container {
  // margin-top: 1rem;
  margin-top: calc(54px + 1rem);
  font-size: 1rem;
  line-height: 1.2rem;
}

.title {
  align-items: center;
  display: flex;
  padding: 0 1rem;
  height: 40px;
  border-bottom: 1px solid $lightGray;
  color: $darkGray;
  margin-bottom: 0.5rem;
}

.expandable-menu-item-link {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;

  &:hover {
    color: inherit;
  }
}
