@import "styles/variables.scss";

.date-input {
  width: 260px;
  height: 36px;
  border: 1px solid black;
  order: unset !important;
  outline: unset !important;
  margin-bottom: 20px;

  &:active {
    outline: 1px solid $brandAccent !important;
    background-color: $shadeAccent;
  }
}

.select-date {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
  // width: 100%;
}
