@import "styles/variables.scss";

.shop-miniature {
  background-color: $brandSecondary;
  border-radius: 4px;
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 12px;
  cursor: pointer;

  &:focus {
    background-color: $darkGray;
    .delete-button-container {
      display: block;
    }
  }

  &:hover {
    background-color: $darkGray;
    .delete-button-container {
      display: block;
    }
  }
}

.shop-name {
  text-transform: capitalize;
  font-weight: bold;
}

.license-rank {
  margin-bottom: 6px;
  margin-top: 6px;
}

.delete-button-container {
  position: absolute;
  top: -15px;
  right: -15px;
  display: none;
}

.delete-button {
  cursor: pointer;
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $error;
  color: $brandSecondary;
  filter: brightness(60%);
  border-radius: 50%;

  &:hover {
    filter: none;
  }
}
