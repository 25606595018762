@import "styles/variables.scss";

.login-page {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 450px;
    width: 400px;
    background-color: $brandPrimary;
    border-radius: 5px;
    padding: 10px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 80px 20px;
  height: 100%;
  gap: 30px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.button {
  // &:hover {
  //   color: white !important;
  //   background-color: $brandAccent !important;
  //   filter: brightness(90%);
  // }
}

.forgetPassword {
  color: $brandSecondary;
  order: 5;

  &:hover {
    text-decoration: underline;
  }
}

.show-password {
  cursor: pointer;
  color: white;
  font-style: italic;
  font-size: 14px;
  line-height: 14px;
  text-decoration: underline;

  &:hover {
    color: $brandAccent;
  }
}

.logo {
  width: 275px;
}

.error {
  color: $error;
  order: 4;
}

.bottom {
  order: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
