@import "styles/variables.scss";

.logistic-modal-contact-card {
  top: 10px !important;
}

.logistic-modal {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.logistic-modal-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.loading-container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $mobileBreakpoint) {
  .logistic-modal {
    min-width: unset;
  }

  .logistic-modal-contact-card {
    display: none;
  }
}
@media screen and (max-width: $tabletBreakpoint) {
  .logistic-modal-contact-card {
    display: none;
  }
}
