@import "styles/variables.scss";

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 2rem;
  gap: 10px;
}

.filters {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.5rem;
  gap: 10px;
}

.visible {
  transform: scaleX(-1);
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $lightGray;
  border-radius: 3px;
  width: 100%;
  padding: 1rem;
}

.filters-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  line-height: 1rem;
  height: fit-content;
  width: fit-content;
}

.icon {
  height: 20px;
  width: 20px;
  margin-right: 0.25rem;
}

@media screen and (max-width: $laptopBreakpoint) {
  .filters-container {
    padding: 0 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .filters-wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
  }
  .filters {
    flex-direction: column;
    align-items: flex-end;
    margin-left: 0.5rem;
  }

  .filters-container {
    padding: 0 0.5rem;
  }
}
