@import "styles/variables.scss";

.close-button-container {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 0px;
  overflow: visible;
}

.close-icon {
  color: white;
  cursor: pointer;
  font-size: 30px !important;

  &:hover {
    color: $brandAccent;
  }
}

.image-viewer {
  background-color: rgb(18, 18, 21, 0.9);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2002;
}
