@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.item-list {
  display: flex;
  flex-direction: column;
  // gap: 40px;
  max-width: 1500px;
  width: 85vw;
  // padding-top: 50px;
  margin-left: auto;
  margin-right: auto;

  .header {
    display: flex;
    font-size: 16px;
  }

  .header-element-container {
    flex: 2;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    background-color: $brandPrimary;
    color: $brandSecondary;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      flex: 1;
    }

    &:nth-child(2) {
      flex: 4;
    }

    &:nth-child(3) {
      flex: 1;
    }
  }

  .header-element {
    display: flex;
  }

  .translate {
    position: relative;
    right: 24px;
  }

  .list-body {
    margin-bottom: 40px;
  }

  .top {
    display: flex;
    align-items: center;
    min-height: 50px;
    gap: 40px;
    margin-bottom: 20px;

    .title {
      font-size: 36px;
      cursor: default;
    }

    .addButton {
      background-color: $brandAccent;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $brandPrimary;
        color: $brandAccent;
      }
    }
  }
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters-group {
  margin-top: 20px;
}

.filter-by-status {
  margin-left: 10px;
}

.filters {
  display: flex;
  // gap: 10px;
}

.reset-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.separator {
  border: 1px solid black;
}

.filters-group {
  display: flex;
  align-items: center;
}
// .shipping-date,
.shipping-state {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  // select,
  // input {
  //   width: 75%;
  // }
}

.shipping-service,
.shipping-direction,
.shipping-date {
  display: flex;
  flex-direction: column;

  font-size: 14px;
  margin-right: 20px;
}

.shipping-date {
  margin-left: 20px;
  margin-right: 0px;
}

.shipping-direction {
  margin-left: 20px;
}

.shipping-service-item {
}

.date-input {
  width: 240px;
  height: 36px !important;
  min-width: unset !important;
}

.shipping-service-button {
  border: none;
  cursor: pointer;
  background-color: $lightGray;
  color: $brandPrimary;
  padding-inline: 10px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 14px;
  height: 34px;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.toggled {
  background-color: $brandPrimary;
  color: $brandAccent;
  &:hover {
    background-color: $brandPrimary;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  cursor: pointer;
  height: 22px;
  width: 22px;
}

.paging-container {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.page-limit-select {
  min-width: unset;
  min-height: unset;
  padding: 10px 0px;
  padding-left: 5px;
  height: fit-content;
  width: fit-content;
}

.paging-container-left {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 5px;
}

.paging-container-right {
  display: flex;
  gap: 5px;
  align-items: center;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sort-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate {
  transform: scale(1, -1);
}

.sort-icon-arrow {
  position: relative;
  left: 7px;
}

.sort-icon-list {
  transform: scale(80%);
  position: relative;
  right: 6px;
  top: 3px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.empty-list {
  text-align: center;
  font-style: italic;
}

// @media screen and (max-width: 1480px) {

@media screen and (max-width: 1400px) {
  .container {
    margin-top: 20px;
  }

  .separator {
    display: none;
  }

  .filters-group {
    // display: flex;
    // gap: 20px;
    // width: 50vw;
  }

  .filters {
    // flex-direction: column;
    // gap: 10px;
  }
}

@media screen and (max-width: 1235px) {
  .itemList {
    .header {
      display: none;
    }
  }
}
