@import "styles/variables.scss";

.overview-reference {
  display: flex;
  justify-content: space-between;
}

.overview-reference-left {
  display: flex;
  align-items: flex-end;
}

.track-id,
.separator,
.date,
.due-date {
  font-size: clamp(0.7rem, 5vw, 0.9rem);
}

.separator {
  margin-inline: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .overview-reference {
    flex-direction: column;
  }
}
