@import "styles/variables.scss";

.previous-button {
  margin-right: 10px;
}

.step-by-step-modal {
  background-color: $shadeSecondary;
  width: 100%;
  max-width: 1000px;
  height: 100%;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step-by-step-modal-footer {
  display: flex;
  justify-content: flex-end;
}
