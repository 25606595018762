@import "styles/variables.scss";

.layout {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.outlet {
  display: flex;
  justify-content: center;
  padding-left: 220px;
  margin-top: 54px;
  width: 100%;
  transition: padding-left 0.5 ease-in-out;
}

.outlet.outlet-small {
  padding-left: 64px;
}

select {
  cursor: pointer;
}

@media screen and (max-width: $laptopBreakpoint) {
  .outlet,
  .outlet.outlet-small {
    width: 100%;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .outlet.outlet-small,
  .outlet {
    padding-left: 0px;
  }
  .MainLayout-ordinaryLeftPanelBorder {
    display: none !important;
  }
}
