@import "/src/styles/variables.scss";

.select-from-list-modal {
  position: relative;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.input {
  margin-bottom: 12px;
}

.alphabet-item:hover {
  text-decoration: underline;
}

.search-input {
  display: flex;
  align-items: center;
}

.validate-button {
  height: fit-content;
  margin-left: 0.5rem;
}
