@import "styles/variables.scss";

.cross-selling-create {
  display: flex;
  flex-direction: column;
  // margin-top: 50px;
  margin-bottom: 20px;
  // gap: 30px;
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  align-items: center;
  font-size: 30px;
  display: flex;
  margin-bottom: 20px;
  height: 68px;
}

.cross-selling-form {
  display: flex;
  flex-direction: column;
  // max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
}

.cross-selling-form-top {
  display: flex;

  // &:not(:last-child()) {
  //   margin-right: 20px;
  // }
}

.cross-selling-form-left {
  flex: 3;
}

.input-element {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.cross-selling-form-right {
  flex: 2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 50px;
}

.select-category {
  width: 100% !important;
}

.image-container {
  // display: flex;
  // flex-direction: column;
}

.file-input {
  display: none;
}

.addcontainer {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addbutton {
  display: flex;
  align-items: center;
  gap: 5px;

  border: none;
  background-color: $brandPrimary;
  border-radius: 4px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: $brandAccent;
  cursor: pointer;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
}

.submit-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 20px;

  padding: 15px;
  color: $brandAccent;
  background-color: $brandPrimary;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }
}

.image-preview-miniature {
  max-height: 400px;
  width: 400px;
  object-fit: scale-down;
  border: 1px solid $lightGray;
  &:hover {
    filter: brightness(80%);
  }
}

.add-image-wrapper {
  display: flex;
  flex-direction: column;
}

.delete-image-button {
  display: flex;
  align-items: center;

  width: fit-content;

  &:not(:last-child()) {
    margin-right: 10px;
  }
}

.action-buttons {
  display: flex;
}

.image-preview-miniature {
  // cursor: crosshair;
}

.add-image-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 370px;
  width: 370px;
}

.add-image-icon {
  height: 150px !important;
  width: 150px !important;
}

.product-group-list {
  display: flex;
}

.product-group-item {
  // background-color: $shadePrimary;
  // width: fit-content;
  // padding: 2px 10px;
  // border-radius: 5px;
  // cursor: pointer;
  // font-size: 14px;
  // font-weight: 700;

  // &:hover {
  //   background-color: $brandPrimary;
  //   color: $brandAccent;
  // }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.selected {
  background-color: $brandPrimary;
  color: $brandAccent;
}

.validation-error {
  color: $error;
  border-color: $error;
}

.error-message {
  font-style: italic;
  font-size: 10px;
}

.section-from-template {
  font-size: 14px;
}

@media screen and (max-width: 1400px) {
  .cross-selling-create {
    margin-top: 20px;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .cross-selling-form-top {
    flex-direction: column;
  }

  .cross-selling-create {
    padding: 0px 10px;
  }

  .cross-selling-form-right {
    margin: 40px 0px 0px 0px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .cross-selling-create {
    margin-top: 50px;
  }
}
