@import "styles/variables.scss";

.add-product-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  // max-height: 800px;
  overflow: auto;
  width: 100%;
  padding: 20px;
}

.add-product-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.input-container {
  max-width: 300px;
}

.select-container {
  //   width: 100% !important;
  //   max-width: 300px !important;
}

.separator {
  margin: 20px 0px 10px 0px !important;
}

.input-line {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media screen and (max-width: $mobileBreakpoint) {
  .add-product-modal-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
