@import "styles/variables.scss";

.contact-info {
  flex: 1;
}

.history-cart-card {
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: 4px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.history-cart-list {
  width: 100%;
}

.loading-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-history {
  margin-top: 20px;
}

.workshop-id-list {
  flex: 3;
}

.workshop-id {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
