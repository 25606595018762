@import "styles/variables.scss";

.widget-container {
  width: 100%;
  aspect-ratio: 1.1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 0.4s;
  border-radius: 3px;
  border: 1px solid $darkGray;
  background-color: $brandSecondary;
  font-size: 1.2rem;
}

.hover:hover {
  cursor: pointer;
  transform: translateY(-5px);
  border: 2px solid $brandAccent;
  padding: 18px;
}

.active:active {
  cursor: pointer;
  transform: translateY(-5px);
  border: 2px solid $brandAccent;
  padding: 18px;
}

@media screen and (max-width: $laptopBreakpoint) {
  .widget-container {
    font-size: 1rem;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .widget-container {
    font-size: 0.925rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .widget-container {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
