@import "styles/variables.scss";

.header-element-container {
  flex: 2;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
  background-color: $brandPrimary;
  color: $brandSecondary;

  &:first-child {
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
  }

  &:nth-child(2) {
    flex: 4;
  }

  &:nth-child(3) {
    flex: 1;
  }
}

.header-element {
  display: flex;
}

.sort-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort-icon-first {
  position: relative;
  left: 10px;
}

.rotate {
  transform: scale(1, -1);
}

.sort-icon-arrow {
  position: relative;
  left: 7px;
}

.sort-icon-list {
  transform: scale(80%);
  position: relative;
  right: 6px;
  top: 3px;
}

.translate {
  position: relative;
  right: 24px;
}
