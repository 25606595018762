@import "styles/variables.scss";

.payed-list-widget {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding: 1.5rem 2rem;
  width: 100%;
}

.body {
  width: 100%;
}

.footer {
  padding: 1rem;
  border: 1px solid $lightGray;
  border-top: 0;
  border-radius: 0 0 3px 3px;
  background-color: white;
  margin: 0 1.5rem;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: $laptopBreakpoint) {
  .footer {
    padding: 1rem;
    margin: 0 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .header {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .header {
    padding: 0.5rem;
  }

  .footer {
    padding: 0.5rem;
    margin: 0 0.5rem;
    margin-bottom: 0.5rem;
  }
}
