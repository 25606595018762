@import "styles/variables.scss";

.direction {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-shipping-method {
  margin-bottom: 10px;
  width: 100%;
}

.direction-info {
  background-color: $info;
  padding: 10px;
  line-height: 18px;
  border-radius: 4px;
  color: white;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  display: flex;
  justify-content: center;
  max-width: 100px;
}

@media screen and (max-width: 540px) {
  .direction {
    margin-bottom: 20px;
  }
}
