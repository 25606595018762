@import "/src/styles/variables.scss";

.list {
  list-style: none;
  overflow-y: auto;
  padding-right: 1rem;
}

.item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.item:hover {
  background: #f7f7f7;
}

.selected {
  color: $brandAccent;
  text-decoration: underline;
}
