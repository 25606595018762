@import "styles/variables.scss";

.action-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:active {
    color: $brandAccent;
  }
}

.action-section {
  display: flex;
  height: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  // min-height: 74px;
}

.brand {
  font-size: 30px;
}

.cart-list {
  margin-top: 20px;
}

.cart-pro-product-card {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.cart-pro-product-card-container {
  -webkit-box-shadow: $primaryShadow;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  min-height: 100px;
  background-color: $shadeSecondary;
}

.cart-pro-product-card-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.cart-pro-product-card-right {
  align-items: center;
  display: flex;
  flex: 3;
}

.image-preview {
  max-width: 120px;
}
.canceled {
  // background-color: red;
  // filter: grayscale(100%);
}

.canceled-logo {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  padding: 5px;
  color: $error;
  border: 3px solid $error;
  border-radius: 8px;
  margin-left: 5px;
}

.checkbox-icon {
  color: $success;
}

.comment {
  font-size: 16px;
  font-style: italic;
}

.create-new-cart-pro-modal {
  background-color: $shadeSecondary;
  border-radius: 4px;
  height: 100%;
  width: fit-content;
  // max-width: 1400px;

  padding: 20px;
  overflow-x: auto;
  width: 80vw;
  max-width: 1200px;
}

.create-new-cart-pro-modal-container {
  margin: 0px;
  width: 100%;
}

.edit-cart {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:active {
    text-decoration: underline;
    color: $brandAccent;
    // padding: 19px;
  }
}

.edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // justify-content: end;
}

.green-button {
  background-color: $success !important;
  margin-top: 10px;

  &:hover,
  &:active {
    color: white !important;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.loading-spinner {
  // color: $darkGray;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: rgb(251, 247, 242, 0.6);
}

.loading-spinner-container {
  height: 0px;
  overflow: visible;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
}

.picture {
  display: flex;
  flex-direction: column;
}

.pro-incoming-shipping {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.product-card-comment {
  overflow-wrap: break-word;
  word-break: break-all;
  width: 100%;
}
.product-card-comment-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.product-card-displayname {
  font-size: 24px;
  width: 100%;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-group {
  font-weight: bold;
  margin-bottom: 20px;
}

.product-card-information,
.product-card-comment-container {
  font-weight: 300;
  font-style: italic;
  margin-bottom: 20px;
  opacity: 0.7;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;
}

.product-info-comment {
  font-size: 14px;
  font-style: italic;
  // margin-left: 20px;
  // width: 100%;
  max-width: 22rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-info {
  width: 100%;
  height: 100%;
}

.product-info-brand {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.product-info-group {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  margin-top: 5px;
}

.product-info-group-comment {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
}

.product-info-top {
  display: flex;
  align-items: center;
  width: 100%;
}

.product-item {
  // cursor: pointer;
  height: 100%;

  &:first-child {
    padding-top: 10px;
  }
}

.product-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-block: 10px;

  &:last-child {
    padding-bottom: 20px;
  }
}

.loading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.operation-list {
  margin-top: 10px;
}

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.separator {
  margin: 0px !important;
}

.validate-button {
  background-color: $success;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 20px;

  &:active {
    filter: brightness(80%);
  }
}

@media screen and (min-width: 1400px) {
  .pro-incoming-shipping {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .cart-pro-product-card-left {
    display: none;
  }
  .pro-incoming-shipping {
    margin: 20px;
  }
}
