@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 20px;
  gap: 30px;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 30px;
}

.validationbutton {
  margin-top: 12px;
  margin-bottom: 12px;

  width: fit-content;
  color: $brandAccent;

  &:hover {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

.deletebutton {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  background-color: $error;
}

.inputcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
}

.validation-error {
  color: $error;
  border-color: $error;
}

.error-message {
  font-style: italic;
  font-size: 10px;
}

@media screen and (max-width: 1400px) {
  .container {
    margin-top: 20px;
  }
}
