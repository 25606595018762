@import "styles/variables.scss";

.generate-label {
  display: flex;
  margin-top: 20px;
}

.generate-label-button {
  // margin-top: 20px;
  width: 260px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.generate-label-section {
  display: flex;
  width: fit-content;
}
