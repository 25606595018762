@import "styles/variables.scss";

.left {
  flex: 1;
}

.right {
  flex: 2;
}

.input-line {
  display: flex;
  gap: 5px;
}

.workshop-pro-form-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.delete-button,
.validate-button {
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
  text-decoration: underline;
}

.validate-button {
  margin-right: 20px;
}

.delete-button {
  &:active {
    color: $error;
  }
}

.operation-section-container {
  margin-top: 10px;
}

.select-container {
  width: 100%;
}

.workshop-pro-info-form {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .workshop-pro-info-form,
  .input-line {
    flex-direction: column;
  }
}
