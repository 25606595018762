@import "styles/variables.scss";

.cobbler {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin: 1rem 0;
  width: 100%;
}

.filters-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.loading-container {
  height: 100%;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-list {
  width: 100%;
}

.set-mode {
  display: flex;
}

.selected {
  background-color: black !important;
  color: $brandAccent;
  border-color: 2px;
}

.select-mode-button {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.show-pro-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  width: fit-content;
  align-self: flex-end;
  label {
    margin-bottom: 0 !important;
    margin-right: 12px;
  }
}

@media screen and (max-width: $laptopBreakpoint) {
  .cobbler {
    padding: 0 1rem;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .cobbler {
    margin: 0.5rem 0;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .filters-section {
    flex-direction: column;
  }

  .set-mode {
    margin-bottom: 1rem;
    justify-content: space-between;
  }
}
