@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: clamp(350px, 85vw, 1500px);
  // max-width: 1500px;
  // width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.date-range-container {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(min-content, 600px));
  justify-content: space-evenly; 
}

.tables-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 800px));
  justify-content: space-evenly; 
}

.report-table {
  width: clamp(350px, 100%, 800px);
  position: relative;
}

.report-table-header {
  background-color: $brandPrimary;
  color: $brandSecondary;
}

.report-table-footer {
  background-color: $brandPrimary;
  color: $brandSecondary;
}

.product-table-grid {
  display: grid;
  grid-template-columns: repeat(5, auto);
  border: 1px solid $brandPrimary;
  border-radius: 4px;
}

.product-table-grid.loading {
  background-color: rgba(0, 0, 0, 0.05);
  filter: blur(2px);
}

.estimate-table-grid {
  display: grid;
  grid-template-columns: auto auto;
  border: 1px solid $brandPrimary;
  border-radius: 2px; 
}

.estimate-table-grid.loading {
  background-color: rgba(0, 0, 0, 0.05);
  filter: blur(2px);
}


.product-table-title {
  grid-column: 1 / span 3;
  padding: 20px;
  text-align: center;
  font-size: 1.25em;
  font-weight: bold;
}

.product-table-separator {
  grid-column: 1 / span 5;
}

.estimate-table-separator {
  grid-column: 1 / span 2;
}

.report-table-item {
  padding: 5px;
  text-align: center;
}

.secondary-report-table-item {
  text-align: center;
  font-style: italic;
  padding-top: 0;
  color: gray;
}

.report-table-item-price {
  text-align: right;
  &:after {
    content: " €";
  }
}

.date-range-picker-container {
  width: clamp(300px, 100%, 600px);
  padding: 20px 0;
}

.comparison-icon {
  font-size: 0.85em !important;
  margin-right: 5px;
}

.comparison-up {
  color: $success;
}

.comparison-down {
  color: $error;
}

.comparison-equal {
  color: gray;
}

.loading-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.loading-overlay.loading {
  display: block;
}