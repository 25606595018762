@import "styles/variables.scss";

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;
  // cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }

  td a {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.status-box,
.item-date {
  width: 140px !important;
}

.item-date {
  cursor: pointer;
}

.hover:hover,
.active:active {
  background-color: $brandSecondary;
}
