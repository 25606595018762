.unavailable {
  font-style: italic;
}

.price {
  text-wrap: nowrap;
}

.price::after {
  content: " €";
}
