@import "styles/variables.scss";

.icon {
  color: white;
  margin-left: 10px;
}

.invalid-id-message {
  font-size: 14px;
  font-style: italic;
  color: $error;
}

.workshop-id-tag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.workshop-id-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.workshop-id-input-field {
  min-width: unset !important;
  width: 150px !important;
  order: unset !important;
}

.workshop-id-input-container {
  display: flex;
  background-color: $crossSell;
  border-radius: 4px;
  width: fit-content;
  padding: 20px;
}
