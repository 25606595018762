@import "styles/variables.scss";

.contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

@media screen and (max-width: $mobileBreakpoint) {
  .contact-form {
    display: block;
    width: 100%;
  }
}
