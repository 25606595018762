@import "styles/variables.scss";

.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.more-tools {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 35px;
  overflow: visible;
}

.more-tools-dropdown {
  background-color: $brandSecondary;
  border-radius: 4px 4px 0px 0px;
  box-shadow: $primaryShadow;
  width: fit-content;
  height: fit-content;
}

.more-tools-dropdown-wrapper {
  width: 0px;
  overflow: visible;
}
