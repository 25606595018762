@import "styles/variables.scss";

.address {
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}

.shipping-information {
  padding: 1rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }

  &:first {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.shipping-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shipping-information-top {
  display: flex;
  align-items: center;
}

.direction {
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-align: end;
  width: 100%;
}

.state {
  font-size: 0.8rem;
  line-height: 0.8rem;
  border: 1px solid black;
  border-radius: 2px;
  padding: 0.3rem;
  // margin-bottom: 0.2rem;
  margin-right: 0;
}

.shipping-information-right {
  margin-left: auto;
}

.incoming {
  color: $success;
}

.outgoing {
  color: $info;
}

.icon {
  height: 15px !important;
  width: 15px !important;
  margin-left: 0.4rem;
}

.delivered {
  // opacity: 0.5;
}

.edit-button {
  margin-left: 0.5rem;
}

.shipping-information-right {
  display: flex;
  align-items: center;
}

.finish-shipping {
  margin-right: 0.5rem;
  padding: 0.4rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .finish-shipping {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .shipping-information-right {
    flex-direction: column;
  }
}
