@import "styles/variables.scss";

.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.dial-pad-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-left: 10px;
  width: 36px;

  &:active {
    color: $brandAccent;
  }
}

.input-section {
  display: flex;
  align-items: center;
  min-width: 280px;
}

.numeric-pad-grid {
  cursor: pointer;
  display: grid;
  font-size: 24px;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: fit-content;
}

.numeric-pad-item {
  align-items: center;
  background-color: white;
  border-radius: 2px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 80px;
  justify-content: center;
  user-select: none;
  width: 80px;

  &:active {
    background-color: $brandAccent;
  }
}

.numeric-pad-dropdown {
  display: flex;
  background-color: $shadeSecondary;
  box-shadow: $primaryShadow;
  flex-direction: column;
  justify-content: center;
  //   padding: 10px;
  position: relative;
  top: 5px;
  z-index: 10;
}

.numeric-pad-dropdown-container {
  display: flex;
  //   background-color: $shadeSecondary;
  //   box-shadow: $primaryShadow;
  //   flex-direction: column;
  justify-content: center;
  padding: 10px 10px 20px 10px;
  //   position: relative;
  //   top: 10px;
  //   z-index: 10;
}

.reset-workshopId-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 36px;
  width: 36px;

  &:active {
    color: $error;
  }
}

.search-by-workshop-id {
  display: flex;
}

.search-by-workshop-id-container {
  height: 36px;
  overflow: visible;
}

.workshop-id-input {
  height: 36px;
  max-width: 230px;
  order: unset;

  &::placeholder {
    font-size: 12px;
    font-style: italic;
  }
}
