@import "styles/variables.scss";

.container {
  position: fixed;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.4;
}

.modal {
  background-color: $brandSecondary;
  min-width: 850px;
  height: fit-content;
  max-height: 860px;
  padding: 40px 35px 40px 35px;
  border-radius: 5px;
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 1px 0px 20px 2px #888;
  -webkit-box-shadow: 1px 0px 20px 2px #888;
}

.close-button {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover {
    color: $brandAccent;
  }
}

.allday-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.allday-list::-webkit-scrollbar {
  display: none;
  // width: 0;
  // background: transparent;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}

.chronopost-item {
  display: flex;
  justify-content: space-between;
  //   flex-direction: column;
  //   gap: 10px;
}

.left {
  flex: 1;
  //   align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-title {
  font-weight: bold;
}

.shipping-info {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.addresse {
  display: flex;
  flex-direction: column;
}

.state {
  display: flex;
  justify-content: space-between;
  // justify-content: flex-end;
  gap: 10px;
  height: 50px;
}

.select-state {
  display: flex;
  align-items: center;
  gap: 50px;
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 2;
  order: 2;

  &:hover {
    color: $brandAccent;
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.external-id {
  border: 1px solid black;
  padding: 5px 10px;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bottom {
  display: flex;

  justify-content: right;
}

.done-button {
  background-color: $success;
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    color: $brandPrimary;
  }
}

.access-button,
.download-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: $brandPrimary;
  color: $brandAccent;
  width: fit-content;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  padding: 5px 10px;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }
}

.download-button {
  order: 2;
  // height: fit-content;
}

hr {
  margin: 10px !important;
}
