@import "styles/variables.scss";

.add-new {
  display: flex;
  font-style: italic;
  font-size: 16px;
  justify-content: center;
  text-decoration: underline;
  margin-top: 10px;
  width: 100%;

  span {
    cursor: pointer;
    &:active {
      color: $brandAccent;
    }
  }
}

.left {
  flex: 1;
}

.operation-list {
  margin-top: 10px;
}

.operation-section {
}

.operation-section-top {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-section-top {
    flex-direction: column;
  }
}
