@import "styles/variables.scss";

.input-code-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 1000px;
  width: 100%;
}

.input-container {
  display: flex;
  gap: 10px;
  input {
    flex: 3;
  }
  select {
    flex: 1;
  }
}

.submit-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;

  padding: 10px;
  color: $brandAccent;
  background-color: $brandPrimary;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .input-container {
    flex-direction: column;
  }
}
