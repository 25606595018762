@import "styles/variables.scss";

.overview-section {
  padding: 1rem;
  border: 1px solid $darkGray;
  border-radius: 1px;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .overview-section {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
