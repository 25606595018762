@import "styles/variables.scss";

.climbing-card {
display: grid;
grid-template-areas: 
"top-left top-right"
"bottom bottom";
grid-template-columns: 1fr 1fr;
grid-template-rows: 1fr 1fr;
  // display: flex;
  padding: 20px;
  width: 100%;
  // justify-content: space-between;
  background-color: $shadeSecondary;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  border-radius: 4px;

  // &:not(:last-child) {
  //   margin-bottom: 20px;
  // }
}

.climbing-card-left {
  display: flex;
  flex-direction: column;
  grid-area: top-left;
}

.climbing-card-right {
  grid-area: top-right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.comment {
  font-style: italic;
}

.brand {
  font-size: 22px;
}

.brand,
.rubber-type {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.validate-button {
  background-color: $success;
  color: white;
}

.operation-button {
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  border-radius: 0;
  background-color: white;

  &:first-child {
    border-radius: 10px 0 0 10px;
  }

  &:last-child {
    border-radius: 0px 10px 10px 0px;
  }
}

.operation-container {
  display: flex;
}

.operation-selection {
grid-area: bottom;
  display: flex;
  margin-top: 20px;
  // border: 1px solid gray;
  border-radius: 10px;
  // background-color: $lightGray;
}

.border {
  border: 1px solid black;
  margin-block: 3px;
}

.selected {
  background-color: $success;
  color: white;
}
