@import "styles/variables.scss";

.due-date {
  font-size: 1rem;
}

.product-due-date {
  padding-left: 1rem;
}

@media screen and (max-width: 650px) {
  .product-due-date {
    padding-left: 0;
  }
}
