@import "styles/variables.scss";

.incoming-picture {
  height: 150px;
  border: 1px solid $lightGray;
}

.operation-control-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 45px;
  padding-bottom: 50px;
}

.operation-control-top-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.workshop-id {
  min-width: 190px;
  font-size: 26px;
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-control-top {
    margin-top: 10px;
  }

  .operation-control-top,
  .operation-control-top-left {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
  }
}
