@import "styles/variables.scss";

.item-list {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 40px;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .item {
    display: flex;
    font-size: 16px;
    .item-element {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      justify-content: center;
      padding: 10px;
      font-weight: bold;
      background-color: $brandPrimary;
      color: $brandSecondary;

      &:first-child {
        border-top-left-radius: 5px;
        flex: 3;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 20px;

    .title {
      font-size: 36px;
      cursor: default;
    }

    .addButton {
      background-color: $brandAccent;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $brandPrimary;
        color: $brandAccent;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .item-list {
    margin-top: 20px;
    width: 90vw;
  }
}

@media screen and (max-width: 1235px) {
  .item-list {
    .item {
      display: none;
    }
  }
}
