@import "styles/variables.scss";

.add-new {
  width: fit-content;
  min-width: unset;
}

.list-page {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.list-container {
  margin-bottom: 1rem;
  padding: 0 2rem;
  width: 100%;
}

.list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}

.table {
  border: 1px solid $lightGray;
  border-radius: 3px;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    min-width: 50px;
  }
}

.paging-component,
.paging-component-top {
  padding: 0.5rem 1rem;
  border: 1px solid $lightGray;
}

.paging-component {
  border-top: 0px;
  border-radius: 0 0 3px 3px;
}

.paging-component-top {
  border-bottom: 0px;
  border-radius: 3px 3px 0 0;
}

@media screen and (max-width: $laptopBreakpoint) {
  .list-container {
    padding: 0 1rem;
  }

  .list {
    overflow-x: auto; /* Allow horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .list-container {
    padding: 0 0.5rem;
  }
}
