@import "styles/variables.scss";

.container {
  display: flex;

  &:nth-child(2n + 1) {
    background-color: $shadeSecondary;
  }
}

.shipping-info,
.shipping-product,
.shipping-state {
  flex: 2;
  padding: 10px;
}

.shipping-product,
.shipping-action-container {
  flex: 1;
  padding: 10px;
}

.shipping-contact-info {
  flex: 4;
  padding: 10px;
}

.chronopost-logo {
  width: 80px;
}

.shipping-address,
.shipping-phone {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.direction-outgoing,
.direction-incoming {
  padding: 5px 10px;
  font-weight: bold;
  color: $brandSecondary;
  border-radius: 5px;
}

.direction-incoming {
  background-color: $success;
}

.direction-outgoing {
  background-color: $info;
}

.timeslot-date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeslot {
  font-weight: bold;
  font-size: 22px;
}

.displayname {
  font-weight: bold;
}

.address-comment {
  font-style: italic;
  font-size: 14px;
}

.address,
.phone,
.address-icon {
  //   flex: 1;
}

.shipping-info,
.shipping-contact-info,
.shipping-product,
.shipping-state,
.shipping-action-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shipping-info,
.shipping-state,
.shipping-contact-info {
  flex-direction: column;
}

.shipping-action {
  display: flex;
  align-items: center;
}

.shipping-action-container {
  display: flex;
  flex-direction: column;
}

.shipping-action-container-toggled-list {
  // position: relative;
  // top: 52px;
  // gap: unset;
}

.download-container {
  display: flex;
  cursor: pointer;
  &:hover {
    color: $brandAccent;
  }

  position: relative;
  top: 1px;
}

.printmode-list-container {
  height: 0px;
  overflow: visible;
}

.printmode-list {
  position: relative;
  background-color: $brandSecondary;
  -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  width: fit-content;
  font-size: 14px;
  // padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  z-index: 3;
}

.printmode-list-item {
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: $brandAccent;
  }
}

.state {
  padding: 5px 10px;
  font-weight: bold;
  color: $brandSecondary;
  border-radius: 5px;
}

.yellow {
  background-color: $warning;
}

.green {
  background-color: $success;
}

.red {
  background-color: $error;
}

.shipping-state {
  gap: 10px;
}

.shipping-miscellaneous {
  display: flex;
  gap: 10px;
}

.miscellaneous-icons {
  cursor: help;
}

.shipping-info {
  justify-content: space-around;
}

.shipping-contact-info {
  gap: 10px;
  align-items: flex-start;
}
