@import "styles/variables.scss";

.list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  margin-top: 2rem;
}

.table {
  border: 1px solid $lightGray;
  border-radius: 3px;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    min-width: 50px;
  }
}

.list-header {
  width: 100%;
  border: 0px !important;
}

.list-header-row {
  display: table-row;
  width: 100%;
}

.list-header-cell {
  border: 0px !important;
  display: table-cell;
  text-align: left;
  padding: 0 1rem;
  font-weight: 600 !important;
  font-size: 0.875rem;

  background-color: $shadeSecondary;
  height: 48px;
  line-height: 20px;
}

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}
