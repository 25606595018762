@import "styles/variables.scss";

.operation-category-title {
  // color: white;
  font-weight: 600;
  border-bottom: 1px solid $brandPrimary !important;
  border-top: 1px solid $brandPrimary !important;
  font-size: 0.9rem;
  padding: 0.2rem 1rem;
  background-color: $shadeAccent;
  // position: relative;
  // z-index: 2;
}

.checkbox {
  height: 15px !important;
  width: 15px !important;
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}
