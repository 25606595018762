@import "styles/variables.scss";

.dashboard-widget {
  background-color: $brandSecondary;
  border-radius: 3px;
  border: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  min-height: 428px;
  overflow: auto;
}
