@import "styles/variables.scss";

.import-xlsx-modal {
  background-color: $brandSecondary;
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-header {
  background-color: $brandSecondary;
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-input {
  display: none;
}

.file-name {
  font-size: 0.8rem;
  height: 0;
  overflow: visible;
}

.import-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.results-container {
  width: 100%;
  font-size: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .modal-header {
    flex-direction: column;
  }

  .file-name {
    line-height: 1rem;
    height: fit-content;
    // width: 0;
    margin-bottom: 0.5rem;
  }

  .button {
    &:not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 0.5rem;
    }
  }
}
