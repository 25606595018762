@import "styles/variables.scss";

.delete-icon-container {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.operation-entry {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 3px;
  position: relative;
  border: 1px solid $darkGray;
  background-color: $shadeSecondary;
  width: 100%;
  padding: 0.5rem;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.operation-description {
  flex: 1;
}
.operation-edit-input {
  height: 36px;
  padding: 0 1em !important;
  border: 1px solid black;
  border-radius: 3px;
}
.operation-price {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.5rem;
  input {
    order: 0;
    width: 5rem;
    min-width: 5rem;
  }
}
.operation-edit-input {
  height: 36px;
  border: 1px solid black;
  border-radius: 3px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  -moz-appearance: textfield !important;
}

.operation-type {
  margin-top: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-entry {
    width: 100%;
  }
}
