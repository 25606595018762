@import "styles/variables.scss";

.hide {
  display: none;
}

.menu-item-link {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
  height: 100%;

  &:hover {
    color: inherit;
  }
  //   width: 100%;
}

.reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.menu-item-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item-icon {
  height: 16px !important;
  width: 16px !important;
  // margin-right: ;
}

.menu-item-title {
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 0.5rem;
}
