.item-product {
}

.product-desc {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.icon {
  height: 100% !important;
  width: 100% !important;
  max-width: 22px;
  object-fit: scale-down;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.product-group-list {
  display: flex;
}

.brand,
.price {
  line-height: 16px !important;
  margin-right: 1rem;
}
