$background: #ffffff;

$brandPrimary: #121215;
$brandAccent: #c5a37b;
$brandBlack: #121215;
$brandSecondary: #fafafa;
$transparent: #12121550;

$hoverPrimary: #fafafa;
$hoverSecondary: #121215;
$hoverAccent: #c28642;

$shadePrimary: #f2f6fb;
$shadeSecondary: #fbf7f2;
$shadeAccent: #f0ddc8;
$shadeAccentTransparency: #f0ddc844;
$lightGray: #e4e4e7;
$lightGrayTransparent40: #e4e4e730;
$lightGrayTransparent60: #e4e4e7a1;
$darkGray: #7b7c90;

$nightBlue: #111314;

$success: #4cb263;
$info: #0066cc;
$warning: #eeb155;
$er: #bb5733;
$error: #e85454;
$chronopost: #4608ad;
$errorTransparency: #e8545444;
$workshopId: #00458a;
$crossSell: #4da6ff;
$pro: #00264d;

$homeHeroColor: #b2755e;

$selectedbackground: #d9d9d9;
$selectedShadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

$inputShadow: 0px 0px 4px rgba(0, 102, 204, 0.4);

$navBarHeight: 60px;

$readCardShadow: 2.5px 4px 4px 0px rgba(0, 0, 0, 0.25);

$primaryShadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

$bronze: #614e1a;
$silver: #c0c0c0;
$gold: #d4af37;

$mobileBreakpoint: 950px;
$tabletBreakpoint: 1150px;
$laptopBreakpoint: 1500px;
