@import "styles/variables.scss";

.section-title {
  display: flex;
  align-items: center;
  margin: 0.7rem 0;
}

.section-label {
  margin-bottom: 0 !important;
}

.dotted {
  flex: 1;
  height: 1px;
  border-bottom: 1px solid $darkGray;
  margin: 0 10px;
}
