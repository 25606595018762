@import "styles/variables.scss";

.shipping-widget-container {
  padding: 25px;
  cursor: pointer;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  // background-image: linear-gradient(white, $shadeSecondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    border: 1px solid $brandAccent;
    transform: translateY(-5px);
    padding: 24px;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.shipping-widget-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.shipping-widget-left-top,
.shipping-widget-left-bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shipping-period {
  font-size: clamp(1rem, 2.5vw, 2rem);
  line-height: clamp(1.2rem, 2.5vw, 2rem);
  font-weight: 500;
}

.shipping-widget-right {
  height: 180px;
  font-size: clamp(1rem, 2.5vw, 2rem);
  line-height: clamp(1.2rem, 2.5vw, 2rem);
  overflow: auto;
}

.service-logo {
  height: 20px;
  object-fit: scale-down;
  display: flex;
  justify-content: flex-start;

  &:hover {
    filter: sepia();
  }
}

.displayname {
  font-size: 20px;
}

.shipping-selection-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.external-shipping {
  //   border: 1px solid black;
  //   padding: 10px;
  //   width: fit-content;
}
