@import "styles/variables.scss";

.error {
  font-style: italic;
  color: $error;
  font-size: 14px;
}

.hover:hover,
.active:active {
  filter: brightness(95%);
  // background-color: $brandPrimary;
  // color: $brandSecondary;
}

.loading-spinner {
  height: 14px;
  width: 14px;
  border-width: 3px;
}

.button {
  font-size: 0.875rem;
  line-height: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  background-color: $brandSecondary;
  border: 1px solid $darkGray;
  border-radius: 2px;
  color: rgb(18, 18, 21, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
