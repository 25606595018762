.label-preview {
  display: flex;
  align-items: start;
}

.text-area {
  font-size: 1rem;
  padding: 0.5rem;
  margin-right: 1rem;
  resize: none;
}

.canvas {
  border: 1px solid #000;
}
