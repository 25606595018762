.label {
  margin-bottom: 0 !important;
  margin-left: 0.8rem;
  user-select: none;
}

.radio-button {
  display: flex;
  align-items: center;
  cursor: pointer !important;
}

.radio-button {
}

.radio-input {
  min-width: unset !important;
  height: 16px !important;
  width: 16px !important;
  order: unset !important;
}
