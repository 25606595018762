@import "/src/styles/variables.scss";

.contact-information {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.contact-item {
  margin-right: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px !important;
  height: 25px !important;
  font-size: 0.8rem;
}

.icon {
  height: 18px !important;
  width: 18px !important;
}

.action-button {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .action-button {
    &:not(:last-child) {
      margin-right: 0rem;
      margin-bottom: 0.5rem;
    }
  }
}
