@import "styles/variables.scss";

.toasted-link-message {
  text-decoration: underline;
  //   margin-left: 20px;
  font-size: 12px;
}

.toasted-link-message.hover:hover,
.toasted-link-message.active:active {
  color: $brandAccent;
}
