.gp-calendar-container {
    width: 100%;
    max-width: 100%;
  }
  
  .gp-calendar-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
  }
  
  .gp-calendar-body-days {
    max-width: calc(100% - 58px);
    display: flex;
    flex-direction: row;
    flex: 1;
  }