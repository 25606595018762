@import "styles/variables.scss";

.gp-calendar-day-column-container {
  flex: 1;
  border-left: 1px solid rgba(224, 224, 224, 1);
  max-width: 100%;
}

.gp-calendar-day-row {
  height: 58px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  flex-direction: row;
  flex: 1;
}

// .skeleton {
//   margin: 1px;
//   animation: skeleton-loading 1s linear infinite alternate;
//   color: transparent;
// }

.gp-calendar-day-row-weekly {
  max-width: 20%;
}

.gp-calendar-day-column-header {
  height: 86px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: 2em;
  text-align: center;
}

.gp-calendar-day-column-header-today {
  color: blue;
}

.gp-calendar-day-column-header-day {
  font-size: 1em;
  margin-bottom: 0.25em;
}

.gp-calendar-day-column-header-date {
  font-size: 2em;
}

// @keyframes skeleton-loading {
//   0% {
//     background-color: #f5f5f5; /* FROM Color 1 */
//   }
//   100% {
//     background-color: #e2e2e2; /* TO Color 2 */
//   }
// }

@media screen and (max-width: $tabletBreakpoint) {
  .gp-calendar-day-column-header {
    height: 58px;
  }
}

