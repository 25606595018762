.table-header {
  width: 100%;
  border: 0px !important;
}

.table-header-row {
  display: table-row;
}

.table-header-cell {
  display: table-cell;
  padding: 0.5rem 0;
}

.row-title {
  min-width: 100px;
  font-size: 0.8rem;
}

.price-table {
  width: 100%;
}

.price-input {
  -moz-appearance: textfield !important;
  -webkit-appearance: none;
  margin: 0;
}

.list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}
