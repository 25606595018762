@import "styles/variables.scss";

.modal-detailed-cross-sell {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cross-sell-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.cross-sell-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.description {
  flex: 3;
  font-size: 16px;
}

.cart-quantity,
.state {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ready-icon {
  color: $success;
}

.not-ready-icon {
  color: $error;
}

.title {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 500;
}
