@import "styles/variables.scss";

.shipping-pro-card {
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 150px;
  padding: 20px;
  background-color: $shadeSecondary;

  &:hover {
    border: 1px solid $brandAccent;
    padding: 19px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

// .shipping-pro-card {
//   animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;

//   // Prestate
//   opacity: 0;
//   // remove transform for just a fade-in
//   transform: rotateX(-90deg);
//   transform-origin: top center;
// }

// @keyframes show {
//   100% {
//     opacity: 1;
//     transform: none;
//   }
// }

.shipping-pro-card-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.organization {
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .state {
    display: none;
  }
}
