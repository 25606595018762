@import "styles/variables.scss";

.tab {
  width: fit-content;
  text-wrap: nowrap;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.5;
  padding: 1rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-color 0.3s, opacity 0.3s;
  position: relative;
  z-index: 10;
}

.tab.hover:hover,
.tab.active:active {
  color: $brandAccent;
  border-bottom-color: $brandAccent;
  opacity: 1;
}

.tab-navigation {
  // border-bottom: 1px solid $darkGray;
  margin-bottom: 1rem;
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.selected {
  color: $brandAccent;
  border-bottom-color: $brandAccent;
  opacity: 1;
}

.tabs-list {
  display: flex;
  list-style: none;
}

.separator {
  border-bottom: 1px solid $darkGray;
  position: relative;
  z-index: -1;
  top: -1px;
}
