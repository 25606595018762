@import "styles/variables.scss";

.item-list {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .item {
    display: flex;
    // gap: 2px;
    font-size: 16px;
    .item-element {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      justify-content: center;
      // min-width: 175px;
      padding: 10px;
      font-weight: bold;
      background-color: $brandPrimary;
      color: $brandSecondary;

      &:first-child {
        border-top-left-radius: 5px;
        cursor: pointer;
        // flex: 3;
        // min-width: 500px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        // min-width: 500px;
      }

      &:nth-child(3) {
        cursor: pointer;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    gap: 40px;
    min-height: 50px;
    margin-bottom: 20px;

    .title {
      font-size: 36px;
      cursor: default;
    }

    .add-button {
      background-color: $brandAccent;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $brandPrimary;
        color: $brandAccent;
      }
    }
  }
}

.bottom {
  margin-bottom: 40px;
}

.filters-container {
  display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.filters {
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.reset-filters-container {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.reset-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.discount-code {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  input {
    &::placeholder {
      font-style: italic;
      opacity: 0.3;
      font-size: 13px;
    }
  }
  select,
  input {
    width: 70%;
  }
}

.bottom {
  margin-bottom: 40px;
}

// @media screen and (max-width: 1480px) {

@media screen and (max-width: 1400px) {
  .item-list {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1235px) {
  .item-list {
    .item {
      display: none;
    }
  }
}
