@import "styles/variables.scss";

.sub-menu {
  position: fixed;
  top: 0;
  z-index: 1996;
  height: 100%;
  width: 220px;
  background-color: white;
  border-right: 1px solid $lightGray;
  padding: 0.5rem;
  box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.12);
}

.background {
  position: fixed;
  top: 0;
  z-index: 1996;
  height: 100%;
  width: 100%;
}
