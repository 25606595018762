@import "styles/variables.scss";

.addresses-form {
  width: 100%;
}

.address-form {
  margin-top: 1rem;
}

.add-new-button {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  margin-top: 1rem;
  width: fit-content;
}

.add-new-button.hover:hover,
.add-new-button.active:active {
  color: $brandAccent;
}

.bottom-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.validate-button,
.cancel-button {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}

.cancel-button {
  margin-left: 1rem;
}

.cancel-button.hover:hover,
.cancel-button.active:active {
  color: $error;
}

@media screen and (max-width: $mobileBreakpoint) {
  .add-new-button {
    text-align: center;
  }
}
