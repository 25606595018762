.body {
  height: fit-content;
}

.modal-title {
  margin-bottom: 1rem;
}

.validate-drop-off-modal {
  padding: 1rem;
  padding-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
