.operation-acronym-check {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.print-operations-checklist {
  display: grid;
  grid-template-columns: repeat(4, 120px);
  width: fit-content;
  margin-top: 1rem;
  gap: 10px;
  // max-height: 12em;
  overflow-y: scroll;
  height: fit-content;
}

.acronym {
  margin: 0 0 0 5px !important;
}

.check-box {
  order: inherit !important;
  height: 30px !important;
  width: 30px !important;
}

.acronym {
  font-size: 0.875rem;
}
