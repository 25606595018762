@import "/src/styles/variables.scss";

.date-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.section-title {
  width: 100%;
}

.repare-time {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.date {
  font-size: 0.875rem;
}

.date-button {
  margin-left: 1rem;
}

.date-input {
  display: none;
}

.timeslot {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .repare-time {
    grid-template-columns: 1fr;
  }
}
