@import "styles/variables.scss";

.comment-section {
  // margin-bottom: 0.5rem !important;
  width: 100%;
}

.comment {
  font-size: 0.875rem;
}

.edit-button {
  all: unset;
  cursor: pointer;
  min-height: 34px;
}

.edit-button.hover:hover,
.edit-button.active:active {
  color: $brandAccent;
}

.note-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 1rem;
  width: 100%;
}

.comment-area {
  width: 100%;
  min-height: 100px;
  padding: 5px;
  background-color: white;
  border: 1px solid gray;
  resize: vertical;
  margin-bottom: 1rem;
}

.comment-list {
  list-style: none;
  // .timeline {
  position: relative;
  padding: 0 20px;
  border-left: 1px solid black;
  // }
}

@media screen and (max-width: $mobileBreakpoint) {
  .comment-section {
    // margin-bottom: 0rem !important;
    // margin-top: 0.5rem !important;
  }
}
