@import "styles/variables.scss";

.series-modal-container {
  padding: 1em 0;
  display: flex;
  flex-direction: row;
  max-height: 100%;
  width: 100%;
}

.series-modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1em;
  width: 100%;
}

.series-modal-header-container {
  width: 100%;
}
.series-modal-header {
  display: grid;
  column-gap: 1em;
  row-gap: 0.5em;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto;
}

.series-modal-header-actions {
  grid-row: 2;
  grid-column: 2/4;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.series-modal-header-done {
  max-width: 224px;
  display: flex;
  color: $success;
  border-color: $success;
}
.series-modal-header-delete {
  max-width: 224px;
  color: $error;
  border-color: $error;
  margin-left: 2em;
}
.series-modal-header-title {
  grid-row: 1;
  grid-column: 1;
  font-size: 1.5em;
}
.series-modal-header-product-count-container {
  grid-row: 1;
  grid-column: 2;
}
.series-modal-header-product-count {
  font-size: 1.5em;
}
.series-modal-header-date-range {
  grid-row: 2;
  grid-column: 1;
  font-style: italic;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.series-modal-button-text {
  margin-left: 1em;
}

.series-modal-content-product-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.series-modal-content-remove-product-button {
  width: 42px !important;
  height: 42px;
  color: $error;
}
.series-modal-content-product-done-button {
  width: 42px !important;
  height: 42px;
  color: $success;
}

.series-modal-content-button-icon {
  height: 15px;
  width: 15px;
}

.series-modal-product-card-container {
  padding: 5px 0;
}

.series-modal-product-card-state-1_todo {
  border-bottom: 4px solid $warning;
}

.series-modal-product-card-state-2_in_progress {
  border-bottom: 4px solid $warning;
}

.series-modal-product-card-state-3_done {
  border-bottom: 4px solid $success;
}

.series-modal-product-card-state-4_controlled {
  border-bottom: 4px solid $success;
}
