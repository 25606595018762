@import "styles/variables.scss";

.list-container {
  // margin-bottom: 1rem;
  padding: 0 1.5rem;
  width: 100%;
}

.table {
  border: 1px solid $lightGray;
  background-color: white;
  border-radius: 3px;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    min-width: 50px;
  }
}

.list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}

@media screen and (max-width: $laptopBreakpoint) {
  .list-page {
    // margin-top: 20px;
  }
  .list-container {
    padding: 0 1rem;
  }

  .list {
    overflow-x: auto; /* Allow horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .list-container {
    padding: 0 0.5rem;
  }
}
