@import "/src/styles/variables.scss";

.add-operation-modal {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 100%;
}

.operation-input {
  margin-bottom: 0.5rem;
}

.operation-list {
  width: 100%;
  height: 100%;
  padding-right: 0.5rem;
  list-style: none;
  // overflow: auto;
  margin-bottom: 1rem;
}

.operation {
  user-select: none;
  cursor: pointer;
  padding: 1rem 0.5rem 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 0.25rem;
    // border-bottom: 1px solid $brandPrimary;
  }
}

.selected {
  background-color: $lightGray;
}

.add-new-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  width: 100%;
}
