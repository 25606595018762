@import "styles/variables.scss";

.cross-sell-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.cross-selling-products-container {
  width: 100%;
}

.cross-selling-products-list {
  width: 100%;
}

.validate-button {
  display: flex;
  align-items: center;
  gap: 5px;

  border: none;
  background-color: $brandPrimary;
  border-radius: 4px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: $brandAccent;
  cursor: pointer;

  &:active {
    background-color: $brandAccent;
    color: $brandPrimary;
  }
}

.loading-spinner {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-filters-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 100px;

  &:hover {
    color: $brandAccent;
  }
}

.filters-container-left {
  display: flex;
  align-items: center;
}

.selected {
  background-color: $brandPrimary;
  color: $brandAccent;
}

// .delete-icon {
//   width: 50px;
//   height: 50px;
// }

.add-crosssell {
  display: flex;
  font-size: 14px;
  text-decoration: underline;
  font-style: italic;
  justify-content: center;
  width: 100%;

  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.add-new-button {
  cursor: pointer;
  background-color: $brandAccent;
  color: $brandSecondary;
  border: none;
  border-radius: 5px;
  width: fit-content;
  height: 80px;
  font-size: 20px;
  padding: 20px;
  margin-top: 20px;

  &:hover {
    background-color: $brandPrimary;
    color: $brandAccent;
  }
}
