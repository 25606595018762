@import "styles/variables.scss";

.product-operations-list {
  padding-left: 1rem;
  height: fit-content;
  overflow: auto;
}

.operations-list {
  list-style: square;
  padding-left: 1rem;
}

.operation {
  font-size: 1rem;
}

.canceled {
  opacity: 0.7;
  text-decoration: line-through;
}

@media screen and (max-width: 650px) {
  .product-operations-list {
    padding-left: 0;
  }
}
