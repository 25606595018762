@import "styles/variables.scss";

.cancel-button,
.download-button {
  min-width: unset !important;
}

.download-button {
  margin-right: 10px;
}

.download-label {
  -webkit-box-shadow: $primaryShadow;
  align-items: center;
  border-radius: 4px;
  background-color: $brandAccent;
  box-shadow: $primaryShadow;
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 10px;
  width: fit-content;

  &:active {
    filter: brightness(90%);
  }
}

.shipping-label-action-container {
  display: flex;
}

.shipping-date {
  flex: 1;
  display: flex;
  justify-content: center;
  min-width: 102px;
}

.shipping-direction {
  flex: 1;
  margin-right: 10px;
  font-style: italic;
  font-size: 14px;
}

.shipping-pro-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  // background-color: $shadeAccent;

  &:nth-child(2n) {
    background-color: white;
  }
}

.shipping-pro-item-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.state {
  flex: 1;
  min-width: 126px;
  display: flex;
  justify-content: center;
}

.state-container {
  max-width: 126px;
}

.select-state {
  display: flex;
  max-width: 90px;
  max-height: 36;
}

.chronopost-tracking {
  // flex: 1;
  width: fit-content;
}

.cancel-button {
  width: fit-content;
}

.select-state {
  display: flex;
  max-width: 90px;
  max-height: 36;
}

.download-container {
  display: flex;
  // flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.print-mode-list-container {
  height: 0px;
  width: 0px;
  overflow: visible;
  display: flex;
  justify-content: center;
}

.print-mode-list {
  position: relative;
  top: 5px;
  background-color: white;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  font-size: 11px;
  min-width: 164px;
}

.mode-item {
  cursor: pointer;
  padding: 10px;
}

.mode-item.hover:hover,
.mode-item.active:active {
  background-color: $lightGray;
}

.open-link {
  display: flex;
  align-items: center;
}

.select-state {
  width: 140px;
  margin-left: 10px;
}

@media screen and (max-width: 1000px) {
  .chronopost-tracking {
    display: none;
  }
}
