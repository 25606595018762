.contact-information {
  display: flex;
  align-items: center;
}

.address-form {
  width: 100%;
}

.edit-icon-container {
  cursor: pointer;
  margin-left: 20px;
}

.edit-icon {
  font-size: 18px !important;
}

.edit-icon-container {
  cursor: pointer;
  margin-left: 20px;
}
