.discount {
  display: flex;
  align-items: center;
  font-style: italic;
  font-size: 0.875rem;
  cursor: pointer;
}

.discount-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.close-button {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}
