.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.quote-action {
  padding-top: 1rem;
}

.loading-spinner {
  height: 30px !important;
  width: 30px !important;
}

.action-container {
  display: flex;
}

.invoice-button {
  margin-right: 1rem;
}

.invoice-frame {
  height: 100%;
  width: 100%;
}
