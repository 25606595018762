@import "styles/variables.scss";

.paging-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 10px;
  width: 100%;
}

.page-limit-select {
  min-width: unset;
  min-height: unset;
  margin-bottom: 0;
  padding: 5px 0px;
  padding-left: 5px;
  width: 50px;
  height: 30px;
  font-size: 11px;
  line-height: 11px;
  padding-inline: 5px;
  border: 1px solid $brandPrimary;
  &:hover {
    border-color: $brandAccent;
  }
}

.paging-container-left {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 11px;
}

.paging-container-right {
  display: flex;
  gap: 5px;
  align-items: center;
}

.paging-container-center {
  font-size: 14px;
  font-weight: bold;
}

.arrow-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    color: $brandAccent;
  }
}

.arrow-button-desktop {
  &:hover {
    color: $brandAccent;
  }
}

.page-number-container {
  min-width: 100px;
  display: flex;
  justify-content: center;
}

.page-number {
  width: fit-content;
  font-size: 14px;
}

.arrow-icon {
  height: 30px;
  width: 30px;
}
