@import "styles/variables.scss";

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 20px;
  gap: 30px;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 30px;
}

@media screen and (max-width: $laptopBreakpoint) {
  .container {
    width: 90vw;
    margin-top: 20px;
  }
}
