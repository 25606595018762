@import "styles/variables.scss";

.generate-label-button {
  margin-top: 20px;
  width: 260px;
}

.generate-label-section {
  width: fit-content;
}
