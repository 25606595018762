.customer-link {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 1rem;
  height: 20px !important;
  width: 20px !important;
}
