.title {
  font-size: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
}

.icon {
  align-items: center;
}

@media screen and (max-width: 600px) {
  .title {
    flex-direction: column;
    align-items: flex-start;
  }
  .title-container {
    margin-bottom: 1rem;
  }
}
