.state-container {
  border: 1px solid;
  border-radius: 3px;
  text-align: center;
  width: fit-content;
  padding: 0.5rem;
  font-size: 1rem;
  // max-width: 150px;
  line-height: 1rem;
  display: flex;
  align-items: center;
  min-height: 30px;
}
