@import "styles/variables.scss";

.operation-category-title {
  color: white;
  background-color: $brandAccent;
}

.list {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
}

.table {
  border: 1px solid $lightGray;
  background-color: white;
  // table-layout: fixed;
  border-radius: 3px;
  width: 100%;
  border-collapse: collapse;
}
