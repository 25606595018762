@import "styles/variables.scss";

.more-button {
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  height: 35px;

  &:active {
    color: $brandAccent;
  }
}
