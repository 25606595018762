@import "styles/variables.scss";

.overview-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
  padding: 0 1rem;
}

.loading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.body {
  display: flex;
  width: 100%;
}

.left {
  flex: 4;
  margin-right: 1rem;
}

.right {
  flex: 3;
}

@media screen and (max-width: $laptopBreakpoint) {
  .overview-container {
    margin: 1rem 0;
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .left {
    flex: 5;
  }

  .right {
    flex: 4;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .overview-container {
    padding: 0 0.5rem;
  }

  .left {
    margin-right: 0rem !important;
    margin-bottom: 0.5rem;
  }

  .right {
    display: flex;
    flex-direction: column-reverse;
  }

  .body {
    flex-direction: column;
  }
}
