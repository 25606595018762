@import "styles/variables.scss";

.brand {
  display: flex;
  flex-direction: column;
  font-size: 36px;
  font-weight: 300;
}

.comment-container {
  min-height: 44px;
  margin-bottom: 20px;
}

.info-product {
  display: flex;
  flex: 1;
  // margin-left: 20px;
  background-color: white;
  border: 1px solid $brandAccent;
  box-shadow: $primaryShadow;
  -webkit-box-shadow: $primaryShadow;
  border-radius: 4px 0px 4px 4px;
  padding: 15px;
  position: relative;
  right: -5px;
  height: 100%;
}

.picture-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.picture-item {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

@keyframes info-product-container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info-product-container {
  animation: info-product-container 1s;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.left {
  width: 100%;
}

.operation-group {
  font-size: 16px;
  font-weight: 400;
  min-height: 19px;
  margin-bottom: 20px;
}

.operation-list {
  margin: 10px 0 0 30px;
}

.operation-item {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.operation-item-comment {
  font-style: italic;
  font-size: 16px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.state-widget {
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 128px !important;
  font-size: 14px;
}

@media (max-width: $mobileBreakpoint) {
  .state-widget {
    min-width: 100px !important;
    font-size: 12px;
    margin-right: 5px;
  }

  .info-product {
    margin-left: 0px;
    order: 2;
    right: 0px;
    height: fit-content;
    border-radius: 4px;
  }

  .info-product-container {
    flex-direction: column-reverse;
  }

  .right {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}
