@import "styles/variables.scss";

.add-new-product-miniature {
  transition: transform 0.4s;
  width: 150px;
  height: 120px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
  margin-right: 10px;

  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  border-radius: 5px;

  &:hover {
    transform: translateY(-5px);
    border: 2px solid $brandAccent;
    padding: 8px;

    .icon {
      color: $brandAccent !important;
    }
  }
}

.icon {
  width: 50px !important;
  height: 50px !important;
}

.products-list-container {
  padding: 20px 10px;
  display: flex;
  width: 100%;
  overflow: auto;
}

.products-list {
  display: flex;
  width: fit-content;
}

.product-miniature,
.add-new-product-miniature {
  background-color: $shadeSecondary !important;
}

.product-miniature:not(:last-child) {
  margin-right: 10px;
}
