@import "styles/variables.scss";

.category {
  font-size: 1rem;
  line-height: 1.1rem;
}

.color {
  margin-left: 0;
  width: fit-content;
  min-width: unset !important;
  line-height: 0.7rem;
}

.header-right {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  width: 100%;
}

.product-sheet {
  flex: 1;
  min-width: 300px;
  width: 100%;
}

.product-state {
  font-size: clamp(0.6rem, 70%, 1.2rem);
}

.product-group-brand {
  display: flex;
  align-items: center;
}

.brand {
  font-size: 1.7rem;
  font-weight: 500;
}

.product-group-logo {
  height: 30px !important;
  margin-right: 0.5rem;
  object-fit: scale-down;
}

.product-information-line {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  //   justify-content: space-between;
}

.product-information-grid {
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.product-information {
  margin: 1rem 0;
}

.control-link-button {
  width: fit-content;
  min-width: unset !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  margin-right: 0.5rem;
}

@media screen and (max-width: $mobileBreakpoint) {
  .product-sheet {
    margin-top: 1rem;
    // width: 90vw;
  }
}
