@import "styles/variables.scss";

.operation-control-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
}

.operation-control-container {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.validate {
  background-color: $success;
  color: $brandSecondary;
}

@media screen and (max-width: $laptopBreakpoint) {
  .operation-control-container {
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .operation-control-container {
    padding: 0.5rem;
  }
}
