@import "styles/variables.scss";

.cart-modal {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  width: 100%;
  min-width: 1000px;
  height: 100%;
}

.read-document {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   background-color: red;
}

.header-right {
  position: relative;
  top: -15px;
  display: flex;
  align-items: center;
}

.header-left {
  padding: 30px;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
}

.body {
  flex: 12;
  padding: 20px 30px;
  display: flex;
}

.product-information-container {
  width: 100%;
  height: 73vh; //shiiiiit

  overflow: auto;
}

.loading-container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tools-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: $brandPrimary;
  color: $brandSecondary;
  border-radius: 50px;
  cursor: pointer;

  position: relative;
  // top: -10px;
}

.state-container {
  position: relative;
  // top: -20px;
}

.cart-modal-contact-card {
  top: 70px !important;
}

@media screen and (max-width: 1200px) {
  .cart-modal {
    min-width: unset;
    width: 90vw;
  }
}
