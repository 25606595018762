@import "styles/variables.scss";

.left {
  flex: 1;
}

.right {
  flex: 2;
}

.input-line {
  display: flex;
  gap: 5px;
}

.comment {
  width: 100%;
  min-height: 100px;
  padding: 5px;
  background-color: white;
  border: 1px solid gray;
}

.comment-form {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .comment-form {
    flex-direction: column;
  }
}
