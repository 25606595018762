@import "styles/variables.scss";

.item-list {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .item {
    display: flex;
    font-size: 16px;
    .item-element {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      justify-content: center;
      padding: 10px;
      font-weight: bold;
      background-color: $brandPrimary;
      color: $brandSecondary;

      &:first-child {
        border-top-left-radius: 5px;
        flex: 3;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }

      &:nth-child(2) {
        flex: 12;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 20px;

    .title {
      font-size: 36px;
      cursor: default;
    }
  }
}

@media screen and (max-width: 1400px) {
  .item-list {
    width: 90vw;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1235px) {
  .item-list {
    .item {
      display: none;
    }
  }
}
