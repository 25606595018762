@import "/src/styles/variables.scss";

.in-store-drop-off-body {
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  margin-bottom: 1rem;
}

.in-store-drop-off-left {
  width: 100%;
  border-right: 1px solid $darkGray;
  padding-right: 0.5rem;
}

.in-store-drop-off-right {
  width: 100%;
  padding-left: 0.5rem;
}

.in-store-drop-off-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $tabletBreakpoint) {
  .in-store-drop-off-body {
    padding: 0.5rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .in-store-drop-off-body {
    grid-template-columns: 1fr;
  }

  .in-store-drop-off-right {
    margin-top: 1rem;
  }
}
