@import "styles/variables.scss";

.action-button {
  display: flex;
  align-items: center;
  width: fit-content !important;
  text-wrap: nowrap;
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .action-button {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
