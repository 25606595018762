.icon {
  // margin-right: 1rem;
}

.required-action {
  display: flex;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0); /* Start and end at the original position */
  }
  50% {
    transform: translateY(-5px); /* Move up by 20px */
  }
}

.bounce {
  display: inline-block; /* Ensures it's treated as a box */
  animation: bounce 2s ease-in-out infinite; /* 2s duration, smooth easing, infinite loop */
}
