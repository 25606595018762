@import "styles/variables.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: space-between;
  padding: 10px;
}

.title {
  font-size: 36px;
  cursor: default;
}

.output-size {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 6px;

  select {
    padding: 13.5px;
  }
}
.item {
  display: flex;
  gap: 2px;
  font-size: 16px;
  align-items: center;
  //max-width: 1500px;
  width: 20vw;

  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}
.button {
  padding: 10px;
  background-color: $brandAccent;
  border: none;
  border-radius: 5px;
  color: $brandPrimary;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
    background-color: $brandPrimary;
  }
}

.error {
  font-style: italic;
  font-size: 12px;
  color: $error;
}

.first-row {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: end;
}
