@import "styles/variables.scss";

.category {
}

.radio-button {
  padding: 0.5rem 0;
  width: fit-content;
}

.pricing-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-height: 800px;
  max-width: 1000px;
  overflow: auto;
  padding: 20px;
  width: 100%;
}

.pricing-modal-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;

  span {
    font-size: 18px;
    font-style: italic;
    margin-left: 10px;
  }
}
