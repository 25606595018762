@import "styles/variables.scss";

.create-chronopost {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 100%;
}

.create-chronopost-container {
  width: 100%;
  max-width: 1000px;
}

.other-shop {
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  text-decoration: underline;
  width: 260px;

  &:hover {
    color: $brandAccent;
  }
}

.title {
  font-size: 30px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1100px) {
  .create-chronopost {
    margin-left: 20px;
  }
}
