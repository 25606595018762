.import-export-item {
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
  padding: 0.5rem;
}

.import-export-section {
  align-items: center;
  display: flex;
}
