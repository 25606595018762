.section-title {
  width: 100%;
}

.message {
  width: 100%;
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
}
