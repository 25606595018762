.cancel-estimate-reason-modal {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
  
    -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
    width: 100%;
    max-width: 1000px;
    height: 100%;
    overflow-y: auto;
}

.cancel-estimate-reason-form {
    margin: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.reason-textarea {
    min-width: 50em;
    min-height: 10em;
    margin: 1em 0;
}