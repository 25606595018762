@import "styles/variables.scss";

.left {
  flex: 1;
}

.right {
  flex: 2;
}

.input-line {
  display: flex;
  gap: 5px;
}

.miscellaneous-info-form {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: $mobileBreakpoint) {
  .miscellaneous-info-form,
  .input-line {
    flex-direction: column;
  }
}
