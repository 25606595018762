@import "styles/variables.scss";

.create-shipping-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.create-shipping-pro-form {
  width: 100%;
  max-width: 1000px;
}

.form-component {
  width: 100% !important;
}

.magic-tool {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 75px;
  height: 40px;
  width: 40px;
}

.normal-button {
  cursor: pointer;
  padding: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.selected {
  background-color: $brandAccent;
  color: white;
}

.validate-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}
