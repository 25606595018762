@import "/src/styles/variables.scss";

.link-tracker-to-drop-off {
  align-items: center;
  justify-content: center;
  display: table-row;
  padding: 1rem;
  //   border: 1px solid $brandPrimary;

  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid $brandPrimary;
  }
}

.section-title {
  width: 100%;
}

.link-tracker-to-drop-off {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.date {
  width: 100px !important;
  font-size: 1rem;
}

.more-details {
  border: unset;
  text-decoration: underline;

  &:hover {
    background-color: unset;
    color: $brandAccent;
  }
}

.loading-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.state {
  border: 1px solid;
  border-radius: 3px;
  font-size: 0.875rem;
  line-height: 0.9rem;
  padding: 0.5rem;
  width: fit-content;
}

.actual-order {
  border: 1px solid $brandAccent !important;
}

.actual {
  font-size: 0.874rem;
  user-select: none;
}
