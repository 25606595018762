@import "styles/variables.scss";

.item {
  display: flex;

  &:nth-child(2n) {
    background-color: $shadeSecondary;
  }
}

.tools {
  display: flex;
  padding: 10px;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.username {
  flex: 3;
  padding: 10px;
}
.roles {
  flex: 12;
  padding: 10px;
  display: flex;
  gap: 10px;
}

.icon:nth-child(2):hover {
  color: $error;
}

.newUser {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  input,
  select {
    flex: 1;
    min-height: 48px;
  }

  .roleInput {
    flex: 2;
    order: 3;

    .roleList {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      gap: 10px;

      .roleItem {
        display: flex;
        gap: 10px;
        width: fit-content;
        cursor: pointer;

        font-size: 14px;
        border-radius: 5px;
        padding: 5px 10px;
        color: $brandPrimary;
        background-color: $brandAccent;
      }
    }
  }
}

.updateItem {
  flex: 16;
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eyeIcon {
  cursor: pointer;
  opacity: 0.4;
  order: 3;
  position: relative;
  right: 30px;
  top: 11px;
  width: 0px;
  margin-left: -10px;
}

.addUser {
  order: 4;
  position: relative;
  top: 10px;
  padding: 0px 15px;
  &:hover {
    cursor: pointer;
  }
}

.error {
  font-style: italic;
  font-size: 12px;
  color: $error;
}

@media screen and (max-width: 880px) {
  .newUser {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }

  input,
  .roleInput,
  select {
    width: 100%;
  }

  .eyeIcon {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    top: -15px;
    right: 15px;
    margin-top: -27px;
  }
}
