@import "styles/variables.scss";

.item-list {
  display: flex;
  flex-direction: column;
  // padding-top: 50px;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;

  .item {
    display: flex;
    font-size: 16px;
    .item-element {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: center;

      justify-content: center;
      padding: 10px;
      font-weight: bold;
      background-color: $brandPrimary;
      color: $brandSecondary;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }

      &:nth-child(4) {
        // flex: 2;
      }
    }

    .item-left {
      display: flex;
      flex: 4;

      .item-element:last-child {
        border-top-right-radius: unset;
      }
    }
  }
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    min-height: 50px;

    .title {
      font-size: 36px;
      cursor: default;
      margin-bottom: 20px;
    }

    .add-button {
      background-color: $brandAccent;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 40px;

      &:hover {
        background-color: $brandPrimary;
        color: $brandAccent;
      }
    }
  }
}

.bottom {
  margin-bottom: 40px;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.filters {
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.filter-element {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  input {
    &::placeholder {
      font-style: italic;
      opacity: 0.3;
      font-size: 13px;
    }
  }

  select,
  input {
    width: 60%;
  }
}

.loading-spinner-container {
  align-items: center;
  display: flex;
  height: 760px;
  justify-content: center;
  width: 100%;
}

.reset-filters-container {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.reset-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
  }
}

.separator {
  height: 48px;
  border: 1px solid black;
}

.unavailable {
  display: flex;
  justify-content: center;
  font-style: italic;
  margin-top: 20px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .item-list {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1235px) {
  .itemList {
    .item {
      display: none;
    }
  }
}
