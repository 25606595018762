@import "/src/styles/variables.scss";

.shipping-service-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

.section-title {
  width: 100%;
}

.loading-section {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $mobileBreakpoint) {
  .shipping-service-container {
    grid-template-columns: 1fr;
  }
}
