@import "styles/variables.scss";

.read-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgb(0, 0, 0, 0.5);
}

.read-modal {
  // width: 100%;
  // max-width: 1000px;
  // max-height: 748px;
  // height: 100%;
  // background-color: var(--brand-secondary);
  // border: 1px solid var(--light-gray);
  // padding: 32px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  background-color: $shadeSecondary;
  display: flex;
  border-radius: 5px;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  width: 100%;
  min-width: 1000px;
  overflow: auto;
}

.read-modal-wrapper {
  display: flex;
  align-items: flex-start;
  // justify-content: center;
  width: 100%;
  max-width: 1000px;
}

.close-button {
  cursor: pointer;
  width: 0;
  overflow: visible;
  position: relative;
  right: 25px;
}

@media screen and (max-width: $mobileBreakpoint) {
  .read-modal {
    padding: 16px;
  }
}
