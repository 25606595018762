@import "styles/variables.scss";

.connect-printer-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-left: 0px !important;
  border-radius: 0 3px 3px 0;
  min-width: none !important;
  width: 40px;
  background-color: $info;
  color: white;

  &:active,
  &:hover {
    filter: brightness(80%);
  }
}

.disconnected {
  background-color: $error;
}
.spinner {
  width: 20px !important;
  height: 20px !important;
  border-top-color: white !important;
  border-left-color: white !important;
  border-width: 3px !important;
}

.icon {
  position: relative;
  height: 22px !important;
  width: 22px !important;
  left: 1px;
}
