@import "styles/variables.scss";

.select-by-list-and-Search {
  user-select: none;
}

.selected-item-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 11em;
}

.shop-select {
  margin-right: 20px;
}

.shop-select-input {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  height: 36px;
  justify-content: space-between;
  min-width: 260px;
  transition: border-color 0.7s ease-out;

  &:hover {
    border-color: $brandAccent;
    color: $brandAccent;
  }
}

.reset-icon {
  display: flex;
  align-items: center;
  overflow: visible;
  width: 0px;
  height: 34px;
}

.shop-select-input-right {
  display: flex;
  align-items: center;
}

.selected-item {
  align-items: center;
  display: flex;
  padding-left: 10px;
}

.selected {
  background-color: $brandAccent;
}

.close-icon {
  cursor: pointer;
  left: -55px;
  position: relative;
  height: 30px !important;
  width: 30px !important;

  &:hover {
    color: $error;
  }
}

.close-icon-container {
  display: flex;
  align-items: center;
  height: 34px;
  width: 34px;
}

.shop-select-input-container {
  display: flex;
  background-color: white;
}

.select-drop-container {
  height: 0px;
  overflow: visible;
  position: relative;
  top: 2px;
  width: 260px;
  // scrollbar-color: red orange;
  // scrollbar-width: thin;
  z-index: 10;
}

.selected-item-placeholder {
  font-size: 13px;
  font-style: italic;
}

.select-drop {
  background-color: $brandPrimary;
  border-radius: 3px 3px 0 0;
  color: $brandSecondary;

  // padding: 10px;
}

.select-drop-list {
  max-height: 400px;
  overflow-y: auto;
}

.select-drop-input-container {
  align-items: center;
  display: flex;
  padding: 10px;
}

.select-drop-input {
  padding-left: 35px;
  height: 36px;
  max-width: 240px !important;

  &::placeholder {
    font-style: italic;
    font-size: 11px;
  }
}

.select-drop-item {
  display: flex;
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover,
  &:active {
    background-color: $brandAccent;
  }
}

.shop-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-icon-container {
  align-items: center;
  display: flex;
  height: 36px;
  overflow: visible;
  width: 0px;
}

.search-icon {
  color: $brandAccent;
  left: 5px;
  position: relative;
}

.shop-workshopPrefixId {
  min-width: 60px;
  text-align: left;
}

.show-more {
  background-color: $brandAccent;
  cursor: pointer;
  font-size: 11px;
  font-style: italic;
  text-align: center;

  &:hover {
    color: $brandSecondary;
  }
}

.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.no-value-found {
  font-size: 14px;
  font-style: italic;
  text-align: center;
}
