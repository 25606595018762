.content {
  // margin: 12px;
  width: 100%;
  padding: 12px;
  // background-color: brown;
}

.video-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  max-height: 224px;
  background-color: #a8a3a3;
  padding:12px;
  border-radius: 6px;
}

.video {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 200px;
  max-height: 200px;
  object-fit: cover;

  z-index: 1;
}

.placeholder {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #a8a3a3 url("../../assets/icons/barcode.png") no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 2;
}

.hidden {
  display: none;
}