@import "styles/variables.scss";
.contact-information,
.address-information {
  display: flex;
  align-items: center;
}

.address-form {
  width: 100%;
}

.edit-icon-container {
  cursor: pointer;
  margin-left: 20px;
}

.edit-icon {
  font-size: 18px !important;
}

.edit-icon-container {
  cursor: pointer;
  margin-left: 20px;
}

.generate-label-button {
  margin-top: 20px;
  width: 260px;
}

.pick-up-list {
  margin-top: 40px;
}
