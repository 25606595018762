@import "styles/variables.scss";

.product-miniature-card {
  align-items: flex-end;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  min-width: 206px;
  height: 100px;
  margin-right: 10px;
  opacity: 0.5;

  -webkit-box-shadow: $readCardShadow;
  box-shadow: $readCardShadow;
  &:last-child {
  }
}

.is-selected,
.hover:hover,
.active:active {
  border: 1px solid $brandAccent;
  border-left: none;
  // box-shadow: none;
  // -webkit-box-shadow: none;
  padding: 9px;
  opacity: 1;
}

.is-selected {
  position: relative;
  z-index: 2;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  // box-shadow: none;
  opacity: 1;
}

.product-group {
  font-size: 14px;
  font-weight: 200;
  max-width: 100px;
}

.product-miniature-card-top-canceled-container {
  display: flex;
  align-items: center;
}

.product-miniature-card-brand {
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.product-miniature-card-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-miniature-card-top-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.state-widget {
  position: relative;
  top: -5px;
  height: 25px;
  width: 79px;
  font-size: 9px;
  min-width: unset;
}

.workshop-id {
  position: relative;
  z-index: 10;
}

@media (max-width: $mobileBreakpoint) {
  .is-selected {
    border: 1px solid $brandAccent;
    border-radius: 4px 4px 0px 0px;
    border-bottom: unset;
  }

  .product-miniature-card {
    height: fit-content;
    width: fit-content;
    min-width: unset;
  }

  .product-miniature-card-brand,
  .product-group {
    display: none;
  }
}
