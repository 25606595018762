@import "styles/variables.scss";

.incoming-internal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.date-input {
  max-width: 400px;
}

.input-container {
  width: fit-content;
}

.set-date-container {
  align-items: center;
  display: flex;
  gap: 20px;
  width: fit-content;
}

.reset-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  order: 2;
  width: 36px;

  &:hover {
    color: $brandAccent;
  }
}

.reset-button {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 36px;
  margin-left: 20px;
}
