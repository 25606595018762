@import "styles/variables.scss";

.add-cross-sell-modal-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
}

.add-cross-sell-modal-list-container {
  background-color: white;
  flex: 10;
  padding: 10px;
  overflow: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 300px;
}

@media screen and (max-width: $tabletBreakpoint) {
  .add-cross-sell-modal-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .add-cross-sell-modal-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 520px) {
  .add-cross-sell-modal-list {
    grid-template-columns: 1fr;
  }
}
