.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.title {
  cursor: pointer;
  font-weight: 500;
}

.icon {
  height: 15px !important;
  width: 15px !important;
}
