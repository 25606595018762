@import "styles/variables.scss";

.quick-access {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.quick-access-grid {
  align-items: flex-start;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 1500px;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: $laptopBreakpoint) {
  .quick-access {
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .quick-access-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .quick-access-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .quick-access {
    padding: 0.5rem;
  }
  .quick-access-grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .quick-access-grid {
    grid-template-columns: 1fr 1fr;
    font-size: 1.2rem;
  }
}
