@import "styles/variables.scss";

.autocomplete-item,
.no-result,
.show-more {
  cursor: pointer;
  padding: 10px;
}

.hover:hover {
}

.circle-spinner-container {
  height: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
}

.circle-spinner {
  position: relative;
  top: 5px;
  right: 10px;
  width: 25px;
  height: 25px;
}

.dropdown {
  color: white;
  position: relative;
  top: 2px;
  z-index: 10;
  background-color: $brandPrimary;
  // padding: 10px;
  border-radius: 3px;
  max-height: calc(76px * 5 + 48px);
  min-height: 10px;
  overflow: auto;
  overflow-x: hidden;
}

.dropdown-container {
  height: 0px;
  overflow: visible;
}

.highlighted-string {
  display: flex;
  white-space: "pre-wrap";
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(76px * 3);
}

.main-info {
  display: flex;
  gap: 5px;
  font-size: 18px;
  line-height: 20px;
}

.no-result {
  font-style: italic;
}

.highlighted {
  font-weight: 500;
  color: $brandAccent;
  white-space: "pre-wrap";
}

.not-highlighted {
  white-space: "pre-wrap";
}

.secondary-info,
.secondary-name-info {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
}

.secondary-name-info {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}

.selected {
  background-color: rgb(197, 163, 123, 0.7);
}

.selected.hover:hover,
.selected.active:active {
  background-color: rgb(197, 163, 123, 0.7);
}

.show-more {
  font-style: italic;

  &:hover {
    color: $brandAccent;
  }
}

.show-more-loading-container {
  display: flex;
  // align-items: flex-start;
  padding: 10px 0px 10px 20px;
  // margin-left: 20px;
  height: 46px;
}

.small {
  width: 5px;
  height: 5px;
  line-height: 8px;
}
