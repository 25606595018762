@import "styles/variables.scss";

.shipping-calendar {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.calendar-container {
  padding-top: 20px;
}

.date-view-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;

  &:hover {
    background-color: $lightGray;
  }
}

.calendar-item {
  max-width: 20%;

}

@media screen and (max-width: $tabletBreakpoint) {
  .shipping-calendar {
    margin-top: 0.5rem;
    padding: 1rem;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .shipping-calendar {
    padding: 0.5rem;
  }
}
