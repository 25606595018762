@import "styles/variables.scss";

.reset {
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  margin-top: 0.5rem;
}

.refashion-select {
  width: 100%;
}

.refashion-section {
  width: 100%;
}

.reset:hover {
  color: $brandAccent;
}

.reset:active {
  color: $brandAccent;
}
