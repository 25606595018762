@import "styles/variables.scss";

.slider {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 101px);
}

.chevron-container {
  align-items: center;
  background-color: $brandAccent;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  margin: 0 20px;
  height: 46px;
  width: 46px;

  &:hover {
    filter: brightness(90%);
  }
}

.chevron {
  font-size: 30px;
}

.main-picture-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.main-picture {
  height: 100% !important;
  width: fit-content;
  max-height: unset;
  max-width: 1600px;
  cursor: zoom-in !important;

  transition: transform 1s ease-in-out;
  filter: brightness(100%) !important;
}

.zoom-in {
  position: relative;
  cursor: zoom-out !important;
  transform: scale(200%);
}

.disabled {
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(100%);
    cursor: default;
  }
}
