@import "styles/variables.scss";

.add-order-input {
  display: flex;
  width: 100%;
  margin-top: 1rem;
}

.add-button,
.remove-button {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.order-item {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.orders {
  width: 100%;
}

.tracker-input {
  min-width: 0 !important;
}

@media screen and (max-width: $mobileBreakpoint) {
  .order-item {
    flex-direction: column;
  }

  .remove-button {
    margin-left: 0;
  }
}
