@import "styles/variables.scss";

.status-select-container {
  display: flex;
  flex-direction: column;
}

.status-select {
  width: 100px;

  color: $brandSecondary;
  font-weight: bold;
  font-size: 18px;
  border: unset;
  border-radius: 5px;
}
