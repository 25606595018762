@import "styles/variables.scss";

.image-container {
}

.image-preview-miniature {
  max-height: 370px;
  width: 370px;
  object-fit: scale-down;
  border: 1px solid black;
  &:hover {
    filter: brightness(80%);
  }
}

.add-image-wrapper {
  display: flex;
  flex-direction: column;
}

.delete-image-button {
  display: flex;
  align-items: center;

  width: fit-content;

  &:not(:last-child()) {
    margin-right: 10px;
  }
}

.action-buttons {
  display: flex;
}

.add-image-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 370px;
  width: 370px;
  &:hover {
    // color: ;
  }
}

.add-image-icon {
  height: 150px !important;
  width: 150px !important;
}

.file-input {
  display: none;
}
@media screen and (max-width: 1400px) {
  .cross-selling-create {
    margin-top: 0px;
  }
}

@media screen and (max-width: 960px) {
  .cross-selling-create {
    margin-top: 50px;
  }
}
