.address-select-container {
  width: 100%;
}

.address-select {
  width: 100%;

  .select-option {
    width: 100% !important;
  }
}
