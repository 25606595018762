@import "styles/variables.scss";

.expandable-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.expand-icon {
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
  gap: 20px;
  padding-top: 50px;
  justify-content: right;
}

.image-form-quality {
  flex-wrap: wrap;
  width: 820px;
}

.validate-button {
  display: flex;
  justify-content: space-around;
}

.disabled {
  display: flex;
  justify-content: space-around;
  background-color: lightgray;
}

.validate-button {
  background-color: $success;
  color: $brandSecondary;
}

.cancel,
.validate,
.disabled {
  &:hover {
    color: white !important;
  }
}
