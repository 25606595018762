.add-container {
  margin-top: 1rem;
  width: fit-content;
}

.add-new-picture-button {
  width: 80px;
}

.file-input {
  display: none;
}
