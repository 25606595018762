.progression-bar-container {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 8px 0;
}

.progression-bar-fill {
  height: 100%;
  background-color: #3b82f6; /* A blue color */
  transition: width 0.3s ease;
  border-radius: 12px 0 0 12px;
}

.progression-bar-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
}
