@import "/src/styles/variables.scss";

.cross-sell-entry {
  display: flex;
  width: 100%;
  border: 1px solid $darkGray;
  border-radius: 3px;
  padding: 0.5rem;
  position: relative;
  background-color: $lightGray;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.image {
  height: 100px;
  object-fit: scale-down;
  margin-right: 0.5rem;
  border: 1px solid $lightGray;
}

.delete-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.cross-sell-input {
  width: 100%;
  margin-right: 0.5rem;
}

.change-quantity-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cart-quantity {
  min-width: 30px;
  text-align: center;
  font-size: 1rem;
}

.icon {
  font-size: 1rem !important;
}

.quantity-button {
  cursor: pointer;
  height: 25px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color {
  margin: 0.5rem 0;
}

.inputs {
  display: flex;
}

.price {
  min-width: 80px;
  width: 80px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  -moz-appearance: textfield !important;
}

.cross-sell-entry-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
