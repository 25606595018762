@import "styles/variables.scss";

.address-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  width: 100%;
}

.validation-error {
  color: $error;
  border-color: $error;
}

.my-input:disabled {
  background-color: rgb(228, 228, 231, 0.2);
}

.input {
  height: 36px;
  min-width: 260px;
  outline: unset !important;
  border: 1px solid $brandPrimary;
  border-radius: 3px;

  &:hover {
    border-color: $brandAccent;

    .expand-icon-container {
      color: $hoverAccent;
    }
  }

  &:active {
    outline: 1px solid $brandAccent !important;
    background-color: $shadeAccent;
  }
}

@media screen and (max-width: 540px) {
  .address-form {
    display: block;
  }
}
