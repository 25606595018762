@import "styles/variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  // height: fit-content;
}

.left {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contact-comment {
  font-style: italic;
  margin-top: 10px;
}

.contact-name {
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-info-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.edit-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
    color: $brandAccent;
  }
}

.edit-icon {
  margin-left: 5px;
  font-size: 20px;
}

.icon {
  font-size: 16px !important;
}

.contact-info-item-text {
  font-size: 16px;
  margin-left: 10px;
}

.close-button {
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  position: relative;
  right: -10px;
  top: -10px;
  width: 0px;
  overflow: visible;
}

.right-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}

.create-date {
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  margin-top: 5px;
}

@media (max-width: $mobileBreakpoint) {
  .contact-name {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .state-widget {
    font-size: 12px;
    min-width: 100px;
  }
}
