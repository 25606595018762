@import "styles/variables.scss";

.create {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 40px;
  max-width: 1500px;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: space-between;
  padding: 10px;
}

.title {
  font-size: 36px;
  cursor: default;
}

.button {
  padding: 10px;
  background-color: $brandAccent;
  border: none;
  border-radius: 5px;
  color: $brandPrimary;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $brandAccent;
    background-color: $brandPrimary;
  }
}

// input,
// select {
//   flex: 1;
//   min-height: 48px;
//   max-width: 500px;
// }

.password {
  display: flex;
  order: 2;
}

.roleInput {
  flex: 2;
  order: 3;
}

.roleList {
  display: flex;
  padding-top: 10px;
  width: fit-content;
  gap: 10px;
}

.roleItem {
  display: flex;
  gap: 10px;
  width: fit-content;
  cursor: pointer;

  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  color: $brandPrimary;
  background-color: $brandAccent;
}

.eyeIcon {
  cursor: pointer;
  opacity: 0.4;
  order: 3;
  position: relative;
  right: 30px;
  top: 11px;
  width: 0px;
  margin-left: -10px;
}

.addUser {
  order: 4;
  margin-top: 40px;
}

.error {
  font-style: italic;
  font-size: 12px;
  color: $error;
}

@media screen and (max-width: 1400px) {
  .create {
    margin-top: 20px;
  }
}

@media screen and (max-width: 880px) {
  .newUser {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
  }

  input,
  .roleInput,
  select {
    width: 100%;
  }

  .eyeIcon {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    top: -15px;
    right: 15px;
    margin-top: -27px;
  }
}
