@import "styles/variables.scss";

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0 1rem;
  display: table-cell;
  text-align: left;
}
