@import "styles/variables.scss";

.read-modal-container {
  background-color: $shadeSecondary;
  -webkit-box-shadow: $primaryShadow;
  box-shadow: $primaryShadow;
  padding: 20px;

  display: flex;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  min-width: 1000px;
  overflow: auto;
}

.cross-sell-image {
  width: 400px;
  height: 400px;
  border: 1px solid $lightGray;
  background-color: white;
  object-fit: scale-down;
}

.read-modal-left {
  // min-width: 600px;
  width: 100%;
}

.read-modal-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.read-modal-right {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title-container {
  display: flex;
  flex-wrap: wrap;
}

.title {
  line-height: normal;
  overflow-y: auto;
  font-size: 36px;
}

.product-info-line {
  display: flex;
  flex-direction: column;
  font-size: 20px;

  span {
    font-weight: 300;
    font-size: 11px;
    line-height: 20px;
  }

  //   &:not(:last-child) {
  margin-top: 20px;
  //   }
}

.slug {
  font-style: italic;
  color: $darkGray;
  font-size: 16px;
}

.product-group-list {
  display: flex;
  font-weight: 500;
  margin-top: 15px;
}

.product-group-item {
  background-color: $shadePrimary;
  width: fit-content;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background-color: $brandPrimary;
    color: $brandAccent;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.quantity-container {
  margin-top: 30px;
}

.quantity-change-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 48px;
}

.quantity-value {
  min-width: 100px;
  text-align: center;
}

.quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brandAccent;
  border-radius: 50px;
  cursor: pointer;

  width: 30px;
  height: 30px;

  &:hover {
    background-color: $brandPrimary;
    .icon {
      color: $brandAccent;
    }
  }
}

.quantity-input {
  min-width: unset !important;
  width: 80px !important;
  appearance: none;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.save-changes-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 14px;

  padding: 10px;
  color: $brandAccent;
  background-color: $brandPrimary;

  &:hover {
    color: $brandPrimary;
    background-color: $brandAccent;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:first-child {
    color: $brandSecondary;
    background-color: $error;

    &:hover {
      filter: brightness(80%);
    }
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .read-modal-body {
    flex-direction: column;
  }

  .read-modal-right {
    align-items: center;
  }
}
