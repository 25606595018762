.license-rank {
  margin-bottom: 10px;
}

.operation-item {
  display: flex;
  align-items: center;
}

.shop-validation {
  overflow: auto;
}

.shop-validation-header {
  display: flex;
  justify-content: space-between;
}

.organization {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.non-visible {
  opacity: 0.3;
}

.visibility {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}
