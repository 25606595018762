@import "styles/variables.scss";

.workshop {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.workshop-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .workshop-grid {
    grid-template-columns: 1fr 1fr;
    padding: 0px 10px;
  }
}

@media screen and (max-width: $mobileBreakpoint) {
  .workshop-grid {
    grid-gap: 10px;
    padding: 0px 10px;
  }
}
