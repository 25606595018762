@import "/src/styles/variables.scss";

.add-section {
  width: 100%;
  display: flex;
}

.add-button {
  width: 100% !important;
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.add-icon {
  margin-right: 0.5rem;
}

.button-name {
  display: flex;
  align-items: center;
  height: 0.875rem;
  line-height: 0.8rem;
}

.disabled {
  cursor: default !important;
  opacity: 0.4;
  // background-color: $darkGray;

  &:hover {
    filter: unset;
  }
}
