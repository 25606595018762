@import "styles/variables.scss";

.add-shipping-pro-modal {
  -webkit-box-shadow: $primaryShadow;
  background-color: $shadeSecondary;
  border-radius: 4px;
  box-shadow: $primaryShadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  padding: 20px;
}

.add-shipping-pro-modal-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  // padding: 20px;
}

.button-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.date-picker {
  margin-left: 20px;
  width: fit-content;
  width: 260px;
}

.date-picker-input {
  cursor: pointer;
  border: 1px solid $brandPrimary;
  height: 36px;
  min-width: 260px;
  transition: border-color 0.7s ease-out;

  &:hover {
    border-color: $brandAccent;
    color: $brandAccent;
  }
}

.error-message {
  height: 0px;
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  overflow: visible;
  width: fit-content;
}

.query-section {
  align-items: flex-start;
  display: flex;
  margin-top: 20px;
  // justify-content: center;
}

.title {
  font-size: 36px;
  margin-block: 20px;
}
