@import "styles/variables.scss";

.address-miniature {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  padding-bottom: 20px;

  // margin-bottom: 20px;
  border-bottom: 1px dotted black;
  &:not(:first-child) {
    padding-top: 20px;
  }
}

.address-miniature {
  width: 100%;
}

.radio-button {
  margin-right: 50px;
  width: 217px !important;
  //   flex: 1;
  cursor: pointer;
  pointer-events: none;
}

.pick-up-location-name {
  font-weight: bold;
}

.warning-address {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.another-method-button {
  margin-top: 10px;
}

.pick-up-location-distance {
  font-style: italic;
  font-size: 14px;
}

.search-by-key-component {
  display: flex;
  align-items: center;
}

.select-key-container {
  height: 36px;
  width: 240px;
  // flex: 1;
  overflow: visible;
}

.input-value-container {
  height: 36px;
  width: 240px;
  overflow: visible;
  display: flex;
  margin-left: 10px;
}

.select-key,
.input-value,
.pick-up-location-input {
  cursor: pointer;
  width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;

  border: 1px solid $brandPrimary;
  border-radius: 3px;

  &:hover {
    border-color: $brandAccent;

    .expand-icon-container {
      color: $hoverAccent;
    }
  }

  &:active {
    background-color: $shadeAccent;
  }
}

.input-value {
  cursor: text;
  &::placeholder {
    font-size: 12px;
    font-style: italic;
    line-height: 12px;
  }

  &:focus {
    border-color: $brandAccent;
    outline: 1px solid $brandAccent;
  }
}

.placeholder {
  font-size: 14px;
  line-height: 14px;
  font-style: italic;
  opacity: 0.7;
}

.expand-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon-container {
  position: relative;
  order: 2;
  right: 15px;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    cursor: pointer;
    color: $brandAccent;
  }
}

.options-list {
  padding-top: 5px;
}

.options-list-container {
  position: relative;
  z-index: 10;
  width: 240px;
  color: white;
  background-color: $nightBlue;
  border-radius: 3px;
}
.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.option-item {
  cursor: pointer;
  line-height: 14px;
  font-size: 14px;
  padding: 10px;

  &:hover {
    background-color: $brandAccent;
  }
}

.address-info {
  flex: 2;
  cursor: pointer;
}

.timeslot-container {
  flex: 3;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .timeslot-container {
    flex: 2;
  }
  .radio-button {
    margin-right: 0px;
  }
}
