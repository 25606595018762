@import "styles/variables.scss";

.more-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 13px;
  // padding: 10px;
  margin-left: 10px;
}

.product-cell {
  display: flex;
  width: 300px;
}

.item {
  display: table-row;
  font-size: 16px;
  height: 48px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.coordinates {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.item-coord {
  width: 300px;
}

.displayname {
  font-weight: 500;
}

.item-product {
  // display: flex;
}

.status-box,
.item-date {
  width: 140px !important;
}
.tools {
  width: 90px !important;
}

.item-date {
  cursor: pointer;
}
