@import "styles/variables.scss";

.dashboard {
  max-width: 1500px;
  width: 100%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media screen and (max-width: $laptopBreakpoint) {
  .dashboard {
  }
}

@media screen and (max-width: $tabletBreakpoint) {
}

@media screen and (max-width: $mobileBreakpoint) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }
  .list-container {
    padding: 0 0.5rem;
  }
}
