@import "styles/variables.scss";

.active:active {
  background-color: $brandPrimary;
  color: $brandAccent;
}

.error {
  font-style: italic;
  color: $error;
  font-size: 14px;
}

.hover:hover {
  background-color: $brandPrimary;
  color: $brandAccent;
}

.loading-spinner {
  height: 25px;
  width: 25px;
}

.validate-button {
  // -webkit-box-shadow: $primaryShadow;
  // background-color: $brandAccent;
  // border-radius: 3px;
  // border: none;
  // box-shadow: $primaryShadow;
  // cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size: 14px;
  // font-weight: 300;
  // height: 36px;
  // min-width: 164px;
  // padding: 10px;
}
