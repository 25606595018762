@import "styles/variables.scss";

.select-component {
  display: flex;
  align-items: center;
}

.select-container {
  height: 36px;
  width: 100%;
  overflow: visible;
}

.select-option {
  cursor: pointer;
  width: 100%;
  min-width: 260px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 5px 10px;

  border: 1px solid $brandPrimary;
  border-radius: 3px;

  &:hover {
    border-color: $brandAccent;

    .expand-icon-container {
      color: $hoverAccent;
    }
  }

  &:active {
    background-color: $shadeAccent;
  }
}

.placeholder {
  font-size: 14px;
  line-height: 14px;
  font-style: italic;
  opacity: 0.7;
}

.expand-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon-container {
  position: relative;
  order: 2;
  right: 15px;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:hover {
    cursor: pointer;
    color: $brandAccent;
  }
}

.options-list {
  padding-top: 5px;
  max-height: 400px;
  overflow: auto;
}

.options-list-container {
  background-color: $nightBlue !important;
  border-radius: 3px;
  color: white;
  position: relative;
  top: 2px;
  width: 100%;
  min-width: 260px;
  z-index: 10;
}
.beautiful-div {
  height: 5px;
  background-color: $brandAccent;
  border-radius: 0 0 3px 3px;
}

.option-item {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  line-height: 14px;
  font-size: 14px;
  padding: 10px;

  &:hover {
    background-color: $brandAccent;
  }
}

.large-item {
  padding: 20px;
  font-size: 16px;
}

@media screen and (max-width: 580px) {
  .select-component {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-value-container {
    margin-left: 0px;
    margin-top: 10px;
  }
}
