@import "styles/variables.scss";

.estimate-modal {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.read-document {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
  //   background-color: red;
}

.header-right {
  position: relative;
  display: flex;
  align-items: center;
}

.header-left {
  padding: 30px 30px 0px 30px;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  display: flex;
  align-items: flex-end;
}

.body {
  flex: 12;
  // height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-info {
  display: flex;
  margin-bottom: 40px;
  overflow: auto;
}

.contact-info-element {
  display: flex;
  align-items: center;
  gap: 10px;
  &:not(:last-child) {
    margin-right: 20px;
  }

  &:not(:first-child) {
    &:hover,
    &:active {
      color: $brandAccent;
    }
  }
}

.contact-info-hubspot {
  margin-left: auto;
}

.contact-info-hubspot-button {
  background-color: #121215;
  border: none;
  border-radius: 5px;
  color: #c5a37b;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  padding: 6px;
  &:hover {
    background-color: #c5a37b;
    color: #121215;
  }
}

.product-information-container {
  //   max-height: 50vh;
  flex: 6;
  overflow: auto;
}

.images-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100px;
  width: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.images-list {
  display: flex;
  max-height: 280px;
  padding-bottom: 10px;
  // overflow: auto;
}

.image-preview {
  filter: unset;
  height: 140px !important;
  object-fit: scale-down;
  padding: 3px;
  width: 140px !important;

  &:not(:last-child) {
    margin-right: 5px;
  }
  &:hover {
    border: 3px solid $brandAccent;
    padding: 0px;
  }
}

.loading-container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  width: 100%;
  text-overflow: clip;
  margin-bottom: 20px;
}

.tools-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: $brandPrimary;
  color: $brandSecondary;
  border-radius: 50px;
  cursor: pointer;

  position: relative;
  // top: -10px;
}

.state-container {
  position: relative;
  // top: -20px;
}

.estimate-modal-contact-card {
  top: 70px !important;
}

.centered-error {
  margin: auto;
}

@media screen and (max-width: $mobileBreakpoint) {
  .estimate-modal {
    padding: 0.5rem;
  }

  .header-left {
    padding: 0;
  }
  .header {
    padding-right: 20px;
  }

  .body {
    padding: 0;
  }

  .contact-info-hubspot {
    margin: 0.5rem 0;
  }

  .contact-info {
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
  }
}
