@import "styles/variables.scss";

.navigation-bar {
  background-color: $brandSecondary;
  border-right: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  left: 0;
  height: 100dvh;
  position: fixed;
  top: 0;
  width: 64px;
  z-index: 1997;
  user-select: none !important;
  overflow: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-scrollbar: none;
}

.menu {
  list-style: none !important;
  margin-top: calc(54px + 1rem);
  width: 100%;
  margin-bottom: 1rem;
}

.navigation-bar::-webkit-scrollbar {
  display: none;
}

.expand {
  width: 220px;
  height: 100dvh;
}

.menu-bottom {
  height: 40px;
  width: 100%;
}

@media screen and (max-width: $mobileBreakpoint) {
  .navigation-bar {
    display: none;
  }
}
