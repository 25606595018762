@import "styles/variables.scss";

.menu-item-container {
  cursor: pointer;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.hover:hover {
  background-color: $lightGray;
}

.active:active {
  background-color: $lightGray;
}

.selected {
  background-color: $shadeAccent !important;
}

.title {
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 2000;
  padding: 0 0.5rem;
  justify-content: center;
  font-size: 1rem;
  height: 40px;
  background-color: $brandPrimary;
  border-radius: 6px;
  left: 64px;
  color: $brandSecondary;
  box-shadow: $readCardShadow;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.title::before {
  content: "";
  position: absolute;
  left: -10px; /* Position the arrow on the right side */
  top: 50%;
  transform: scaleX(-1) translateY(-50%); /* Center the arrow vertically */
  border-style: solid;
  border-width: 8px 0 8px 10px; /* Adjust the size of the triangle */
  border-color: transparent transparent transparent $brandPrimary; /* Match the arrow color to the background */
}

.opacity {
  opacity: 1;
}
