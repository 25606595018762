@import "styles/variables.scss";

.incoming-pro-shipping {
  width: 100%;
  max-width: 1000px;
}

.query-section {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  left: 28px;
}

.shop-select {
  margin-right: 20px;
}

.date-picker-input {
  cursor: pointer;
  border: 1px solid $brandPrimary;
  height: 36px;
  min-width: 260px;
  transition: border-color 0.7s ease-out;

  &:hover {
    border-color: $brandAccent;
    color: $brandAccent;
  }
}

.reset-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  order: 2;
  width: 36px;
  margin-left: 10px;

  &:hover {
    color: $brandAccent;
  }
}

.shipping-list {
  height: 100%;
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
}

.shipping-pro-card-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.modal-link {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-top: 20px;
  text-decoration: underline;

  &:active {
    color: $brandAccent;
  }
}

@media screen and (max-width: 768px) {
  .query-section {
    flex-direction: column;
    left: 0px;
  }

  .shop-select {
    margin: 0px 0px 10px 0px;
  }
}
