@import "styles/variables.scss";

.address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.address {
  margin-right: 1rem;
}

.edit-button,
.delete-button {
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  font-size: 0.875rem;
}

.delete-button {
  margin-left: 1rem;
}

.delete-button.hover:hover,
.delete-button.active:active {
  color: $error;
}

.right {
  display: flex;
}
