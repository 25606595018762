@import "styles/variables.scss";

.climbing {
  width: 100%;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.climbing-header {
  display: flex;
  justify-content: space-between;
}

.climbing-list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
gap: 10px;
}

.no-content {
  width: 100%;
  display: flex;
  justify-content: center;
  font-style: italic;
}
