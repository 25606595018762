@import "styles/variables.scss";

.quote-modal {
  background-color: white;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;

  border-radius: 5px;
  padding: 20px;

  -webkit-box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 9px 4px rgba(0, 0, 0, 0.12);
  width: 100%;
  min-width: 1000px;
  height: 100%;
}

.read-document {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   background-color: red;
}

.header-right {
  position: relative;
  top: -15px;
  display: flex;
  align-items: center;
}

.header-left {
  padding: 30px;
  font-size: 30px;
  display: flex;
  align-items: flex-end;
}

.replied-at-date {
  margin-left: 20px;
  font-size: 15px;
  line-height: 15px;
  font-style: italic;
}

.delay {
  //   line-height: 14px;
  font-size: 14px;
  font-style: italic;
  margin-right: 20px;
}

.body {
  flex: 12;
  padding: 20px 0px;
  display: flex;
}

.product-information-container {
  flex: 3;
  //   width: 60%;
  height: 72vh;
  overflow: auto;
}

.other-information-container {
  flex: 2;
  padding: 0px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.other-information-container-top {
  height: 72vh;
  overflow: auto;
}

.quote-modal-contact-card {
  top: 70px !important;
}

.loading-container {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cross-sell-line,
.shipping-line,
.discount-line {
  display: flex;
  flex-direction: row;
}

.shipping-dots,
.discount-dots,
.cross-sell-dots {
  border: none;
  border-bottom: 1px dotted black;
  flex: 1;
}

.other-information-section-title {
  font-weight: bold;
}

.other-information-top {
  height: 63vh;
  overflow: auto;
}

.other-information-bottom {
  display: flex;
  justify-content: flex-end;
}

.total-section {
  font-size: 30px;
  margin-bottom: 20px;

  //   background-color: $brandAccent;
  padding: 10px;
  border: 1px solid $brandAccent;
  border-radius: 5px;

  min-width: 300px;
  display: flex;
  justify-content: space-between;
}

.other-information-section {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.tools-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: $brandPrimary;
  color: $brandSecondary;
  border-radius: 50px;
  cursor: pointer;

  position: relative;
  // top: -10px;
}

.state-container {
  position: relative;
  // top: -20px;
}

.tabs-section {
  height: 100%;
  //   background-color: red;
  display: flex;
}

.tab-item {
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  line-height: 16px;
  font-weight: bold;
  //   border: 1px solid black;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  font-size: 16px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    color: $brandAccent;
  }
}

.top-section {
  display: flex;
  height: 40px;
  border-bottom: 2px solid black;
  justify-content: space-between;
}

.top-section-right {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .quote-modal {
    min-width: unset;
    width: 90vw;
  }
}
