@import "/src/styles/variables.scss";

.item {
  cursor: pointer;
  display: table-row;
  font-size: 16px;
  height: 70px !important;

  &:not(:last-child) {
    border-bottom: 1px solid $lightGray;
  }
}

.hover:hover {
  background-color: $brandSecondary;
}

.active:active {
  background-color: $brandSecondary;
}

.item-cell {
  font-weight: 400 !important;
  padding: 0.2rem 1rem;
  display: table-cell;
  text-align: left;
}

.state-box {
  width: 140px !important;
}

.item-date {
  width: 140px !important;
}

.image {
  max-height: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
}

.shipping {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 3px;
}

.shipping-state {
  border: unset;
}

.tracker-state {
  background-color: $lightGrayTransparent40;
}
