.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
}

.subtitle {
  font-size: 18px;
  text-align: start;
}

.payment-container {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
}

.quote-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
}

.line {
}

.line-name {
  font-size: 12px;
}

.line-value {
}

.invoice-frame {
  // max-width: 75%;
  height: 100%; //600px;;
  max-height: 580px;
  width: 420px;
}
