@import "styles/variables.scss";

.payment-modal {
  background-color: $shadeSecondary;
  -webkit-box-shadow: $primaryShadow;
  border-radius: 3px;
  box-shadow: $primaryShadow;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.payment-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.payment-form-top {
  flex: 1;
}

.payment-form-bottom {
  display: flex;

  justify-content: flex-end;
  padding-top: 1rem;
  width: 100%;
}

.add-image-container {
  margin-top: 1rem;
}
